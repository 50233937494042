import {FC, useState, useEffect} from 'react'

import secureLocalStorage from 'react-secure-storage'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import {baseUrl} from '../core'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import '../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {DateBasicFormat} from '../DateHelpers'
import {useThemeMode} from '../../../_metronic/partials'
import es from 'date-fns/locale/es'
import ar from 'date-fns/locale/ar'
import fr from 'date-fns/locale/fr'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import React from 'react'
import {registerLocale} from 'react-datepicker'
import useApiCall from '../custom_hooks/useApiCall'

import usePostApi from '../custom_hooks/usePostApi'

const IssueAsset = (props) => {
  const intl = useIntl()

  const {execute} = usePostApi()
  const {mode} = useThemeMode()
  const {data, fetchData, isLoading} = useApiCall(
    `/assetManagement/assets/asset/asset/${props.props.id}`
  )
  const {data: hiringManager} = useApiCall('/hrConfig/employees/all')
  let assetData = data && data[0]
  const [editmode, seteditmode] = useState(true)
  const [asset_id, setassetId] = useState([])
  const [asset_type_name, setassettypename] = useState([])
  const [brand, setbrand] = useState([])
  const [make, setmake] = useState([])
  const [model, setmodel] = useState([])
  const [working_status, setworkingstatus] = useState([])
  const [first_installation_date, setinstallationdate] = useState([])
  const [asset_lifespan, setassetlifespan] = useState([])
  const [locale, setLocale] = React.useState('en')

  const lang = useLang()

  useEffect(() => {
    if (assetData) {
      setassetId(assetData?.asset_id)
      setbrand(assetData?.brand)
      setmake(assetData?.make)
      setmodel(assetData?.model)
      setworkingstatus(assetData?.working_status)
      setinstallationdate(assetData?.first_installation_date)
      setassetlifespan(assetData?.asset_lifespan)
      setassettypename(assetData?.asset_type_name)
    }
  }, [assetData])

  useEffect(() => {
    fetchData()
  }, [props.props.id])

  const validationSchemaEdit = Yup.object().shape({
    employee_id: Yup.string().required('Employee is Required'),
    return_by_date: Yup.string().required('Return  Date is Required'),
  })

  const initialValues = {
    asset_id: '',
    employee_id: '',

    return_by_date: '',
  }

  useEffect(() => {
    if (lang === 'ar') {
      setLocale('ar')
    } else {
      setLocale(lang)
    }
  })

  registerLocale('es', es)
  registerLocale('ar', ar)
  registerLocale('fr', fr)

  const handleSubmit = async (values, formikHelpers) => {
    // const {resetForm} = formikHelpers
    try {
      const organizationId = secureLocalStorage.getItem('organization_id')
      const url = `${baseUrl}/assetManagement/empAssets`
      const formData = {
        organization_id: organizationId,
        employee_id: parseInt(values.employee_id),
        asset_id: asset_id,
        return_by_date: DateBasicFormat(values.return_by_date),
      }

      await execute(url, 'POST', formData)
      // resetForm()

      Swal.fire({
        title: 'Asset Issued Successfully',
        text: 'The changes have been saved.',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaEdit}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, handleChange}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL.ISSUES_ASSET'})}
                  </h3>
                  <div className='card-toolbar'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                      id='candidateInfoDrawerClose'
                    >
                      <i className='bi bi-x fs-1'></i>
                    </button>
                  </div>
                </div>

                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative scroll-y me-n5 pe-5 w-450px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      {isLoading ? (
                        <div className='card h-350px'>
                          <div className='m-auto d-flex flex-column align-items-center'>
                            <div className='spinner-border spinner-primary mr-15'></div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              {intl.formatMessage({id: 'LABEL.ASSET_TYPE'})}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {asset_type_name}
                              </span>
                            </div>
                          </div>

                          <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              {intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {brand}
                              </span>
                            </div>
                          </div>

                          <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              {intl.formatMessage({id: 'LABEL.ASSET_MAKE'})}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {make}
                              </span>
                            </div>
                          </div>

                          <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.MODEL'})}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {model}
                              </span>
                            </div>
                          </div>

                          <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              {intl.formatMessage({id: 'LABEL.ASSET_LIFE_SPAN'})}(in yrs)
                            </label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {asset_lifespan}
                              </span>
                            </div>
                          </div>

                          <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.INSTALLATION_DATE'})}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {first_installation_date}
                              </span>
                            </div>
                          </div>

                          <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.WORKING_STATUS'})}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {working_status}
                              </span>
                            </div>
                          </div>

                          <h5 className='mt-4 text-dark'>
                            {' '}
                            {intl.formatMessage({id: 'LABEL.WORKFLOW'})}
                          </h5>
                          <hr className='text-dark mt-2' />
                          <div className='card-body position-relative p-0' id='kt_activities_body'>
                            <div className='timeline'>
                              <div className='timeline-item'>
                                <div className='timeline-line w-20px'></div>
                                <div className='timeline-icon symbol symbol-circle symbol-20px'>
                                  <div
                                    className='symbol-label bg-primary
            
           icon-dotted-border text-primary'
                                  ></div>
                                </div>

                                <div className='timeline-content mb-12 mt-n1'>
                                  {/* <div className='d-flex justify-content-between mt-1'>
                                      <div className=''></div>
                                    </div> */}
                                  <div className='min-h-26px'>
                                    <label>
                                      {' '}
                                      {intl.formatMessage({id: 'LABEL.SELECT_EMPLOYEE'})}
                                    </label>
                                    <br />
                                    <Field
                                      as='select'
                                      className='form-control form-control-solid'
                                      id='floatingInput'
                                      name='employee_id'
                                      value={values.employee_id}
                                      onChange={handleChange}
                                      placeholder='Select Salary'
                                    >
                                      <option value=''>
                                        {' '}
                                        {intl.formatMessage({id: 'LABEL.SELECT_EMPLOYEE'})}
                                      </option>
                                      {hiringManager?.map((hiringmanager) => (
                                        <option
                                          key={hiringmanager.employee_id}
                                          value={hiringmanager.employee_id}
                                        >
                                          {hiringmanager.first_name}
                                        </option>
                                      ))}
                                    </Field>
                                    <ErrorMessage
                                      name='employee_id'
                                      component='div'
                                      className='text-danger'
                                    />
                                    <br />
                                    <label> {intl.formatMessage({id: 'LABEL.DATE'})}</label>
                                    <br />

                                    <Field name='return_by_date'>
                                      {({form, field}) => {
                                        const {setFieldValue} = form
                                        const {value} = field

                                        const handleChange = (date) => {
                                          setFieldValue('return_by_date', date)
                                        }

                                        return (
                                          <div
                                            className={`react-datepicker-fullwidth-wrapper test ${
                                              mode === 'dark' ? 'dark-mode' : 'light-mode'
                                            }`}
                                          >
                                            <DatePicker
                                              selected={value}
                                              minDate={new Date()}
                                              isClearable
                                              showYearDropdown
                                              scrollableYearDropdown
                                              onChange={(date) => {
                                                handleChange(date)
                                              }}
                                              placeholderText={intl.formatMessage({
                                                id: 'LABEL.SELECT_RETURN_DATE',
                                              })}
                                              showMonthDropdown
                                              useShortMonthInDropdown
                                              dateFormat='dd/MM/yyyy'
                                              className='form-control form-control-solid'
                                              popperPlacement='bottom'
                                              locale={locale}
                                            />
                                          </div>
                                        )
                                      }}
                                    </Field>
                                    <ErrorMessage
                                      name='return_by_date'
                                      component='div'
                                      className='text-danger'
                                    />
                                    <br />
                                  </div>
                                  <div className='card-footer p-0 py-5 text-end'>
                                    <button
                                      className='btn btn-light-success btn-sm py-2'
                                      // name='Accepted'
                                      onClick={() => handleSubmit}
                                      type='submit'
                                    >
                                      {intl.formatMessage({id: 'LABEL.ISSUE_ASSET'})}
                                    </button>
                                    {/* <button
                    className='btn btn-light-danger btn-sm ms-2 py-2'
                    name='Rejected'
                    onClick={handleSubmit}
                    type='submit'
                    // id='side_activities_close'
                  >
                    REJECT
                  </button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default IssueAsset
