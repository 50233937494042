//businessBlock
import React, {useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import {GetBusinessUnitAll} from '../OrganizationAPI'
import usePageTitle from '../../custom_hooks/usePageTitle'
import secureLocalStorage from 'react-secure-storage'
const DataTable = ({columns, data, loading, fetchData}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  const userInfo = secureLocalStorage.getItem('user_info')
  const {is_admin} = userInfo || {}
  usePageTitle(intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.BUSINESS_UNITS'}))
  const filteredData = useMemo(() => {
    return data.filter(
      (each) =>
        each.business_unit_name?.toLowerCase().includes(inputVal.toLowerCase()) ||
        each.business_unit_description?.toLowerCase().includes(inputVal.toLowerCase())
    )
  }, [data, inputVal])

  // Create separate datasets for active and inactive records
  const activeData = useMemo(() => {
    return filteredData.filter((record) => record.status === 1)
  }, [filteredData])

  const inactiveData = useMemo(() => {
    return filteredData.filter((record) => record.status === 0)
  }, [filteredData])

  // Table instances for active and inactive dataset
  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)
  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }
  const pageOptions = [5, 10, 20, 30, 40, 50]
  // Render the component
  return (
    <Context.Consumer>
      {(value) => {
        const {id, updateId} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <div className='card'>
                <div className='card-header border-0 pt-5'>
                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='fw-bolder'>
                      <ul className='nav'>
                        <li className='nav-item'>
                          <a
                            className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                            data-bs-toggle='tab'
                            href='#kt_table_widget_4_tab_1'
                          >
                            {intl.formatMessage({id: 'LABEL.ACTIVE'})}
                          </a>
                        </li>
                        {is_admin === 1 && (
                          <li className='nav-item'>
                            <a
                              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                              data-bs-toggle='tab'
                              href='#kt_table_widget_4_tab_2'
                            >
                              {intl.formatMessage({id: 'LABEL.INACTIVE'})}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className='card-toolbar'>
                    <div className='d-flex align-items-center position-relative me-4'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9'
                        placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                        autoComplete='off'
                      />
                    </div>
                    {is_admin === 1 && (
                      <button
                        className='btn btn-sm btn-light-primary'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateId({
                            type: 'add_Business_unit',
                            fetchData,
                          })
                        }}
                      >
                        <KTIcon iconName='plus' className='fs-3' />
                        {intl.formatMessage({id: 'LABEL.NEW_UNIT'})}
                      </button>
                    )}
                  </div>
                </div>
                <div className='tab-content p-2 pt-0'>
                  {/* Active Tab */}
                  <div
                    className='card-body tab-pane fade show active table-responsive'
                    id='kt_table_widget_4_tab_1'
                  >
                    <table
                      className='table table-row-dashed align-middle table-row-gray-300'
                      {...activeTableInstance.getTableProps()}
                    >
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.NAME'})}</th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                          </th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.HEAD_NAME'})}
                          </th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.BU_CODE'})}
                          </th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.ERP_ID'})}
                          </th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.STATUS'})}
                          </th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.ACTION'})}
                          </th>
                        </tr>
                      </thead>
                      <tbody {...activeTableInstance.getTableBodyProps()}>
                        {activePage.length > 0 ? (
                          activePage.map((row) => {
                            activeTableInstance.prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className=' ' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='card'>
                              <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {activePage.length > 10 && (
                      <Pagination>
                        <Pagination.First
                          onClick={() => gotoActivePage(0)}
                          disabled={!activeTableInstance.canPreviousPage}
                        />
                        <Pagination.Prev
                          onClick={activeTableInstance.previousPage}
                          disabled={!activeTableInstance.canPreviousPage}
                        />
                        {[...Array(activePageCount)].map((_, i) => (
                          <Pagination.Item
                            key={i}
                            active={i === activePageIndex}
                            onClick={() => gotoActivePage(i)}
                          >
                            {i + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={activeTableInstance.nextPage}
                          disabled={!activeTableInstance.canNextPage}
                        />
                        <Pagination.Last
                          onClick={() => gotoActivePage(activePageCount - 1)}
                          disabled={!activeTableInstance.canNextPage}
                        />
                        <div className='d-flex align-items-center mx-5'>
                          <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                          <select
                            className='form-select form-select-solid fw-bold w-75px me-2'
                            value={activePageSize}
                            onChange={(e) => setActivePageSize(Number(e.target.value))}
                          >
                            {pageOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Pagination>
                    )}
                  </div>

                  {/* Inactive Tab */}
                  <div
                    className='card-body tab-pane fade table-responsive'
                    id='kt_table_widget_4_tab_2'
                  >
                    <table
                      className='table table-row-dashed align-middle table-row-gray-300'
                      {...inactiveTableInstance.getTableProps()}
                    >
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-120px'>{intl.formatMessage({id: 'LABEL.NAME'})}</th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                          </th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.HEAD_NAME'})}
                          </th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.BU_CODE'})}
                          </th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.ERP_ID'})}
                          </th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.STATUS'})}
                          </th>
                          <th className='min-w-120px'>
                            {intl.formatMessage({id: 'LABEL.ACTION'})}
                          </th>
                        </tr>
                      </thead>
                      <tbody {...inactiveTableInstance.getTableBodyProps()}>
                        {inactivePage.length > 0 ? (
                          inactivePage.map((row) => {
                            inactiveTableInstance.prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                  <td className=' ' {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                ))}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td className='card'>
                              <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {inactivePage.length > 10 && (
                      <Pagination>
                        <Pagination.First
                          onClick={() => gotoInactivePage(0)}
                          disabled={!inactiveTableInstance.canPreviousPage}
                        />
                        <Pagination.Prev
                          onClick={inactiveTableInstance.previousPage}
                          disabled={!inactiveTableInstance.canPreviousPage}
                        />
                        {[...Array(inactivePageCount)].map((_, i) => (
                          <Pagination.Item
                            key={i}
                            active={i === inactivePageIndex}
                            onClick={() => gotoInactivePage(i)}
                          >
                            {i + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={inactiveTableInstance.nextPage}
                          disabled={!inactiveTableInstance.canNextPage}
                        />
                        <Pagination.Last
                          onClick={() => gotoInactivePage(inactivePageCount - 1)}
                          disabled={!inactiveTableInstance.canNextPage}
                        />
                        <div className='d-flex align-items-center mx-5'>
                          <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                          <select
                            className='form-select form-select-solid fw-bold w-75px me-2'
                            value={inactivePageSize}
                            onChange={(e) => setInactivePageSize(Number(e.target.value))}
                          >
                            {pageOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Pagination>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1 className='mb-5'>
                    {intl.formatMessage({id: 'LABEL.NO_BUSINESS_DATA_FOUND'})}
                  </h1>
                  {is_admin === 1 && (
                    <button
                      className='btn btn-sm btn-light-primary'
                      id='side_activities_toggle'
                      onClick={() => {
                        updateId({
                          type: 'add_Business_unit',
                          fetchData,
                        })
                      }}
                    >
                      <KTIcon iconName='plus' className='fs-3' />
                      {intl.formatMessage({id: 'LABEL.NEW_UNIT'})}
                    </button>
                  )}
                </div>
              </div>
            )}
            <SideDrawer value={id} />
          </>
        )
      }}
    </Context.Consumer>
  )
}

const BusinessBlock = () => {
  const {businessUnitData, isBusinessLoading: isLoading, fetchData} = GetBusinessUnitAll()
  const userInfo = secureLocalStorage.getItem('user_info')
  const {is_admin, is_hr} = userInfo || {}
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'business_unit_name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='d-flex justify-content-start flex-column'>
                <p className='text-dark fw-bold text-hover-primary fs-6 default-cursor'>
                  {row.original.business_unit_name}
                </p>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Description',
        accessor: 'business_unit_description',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.business_unit_description}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Head Name',
        accessor: 'bu_head_name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.bu_head_name ? row.original.bu_head_name : '-'}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'BU CODE',
        accessor: 'bu_code',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.bu_code}
            </p>
          )
        },
      },
      {
        Header: 'ERP ID',
        accessor: 'erp_id',
        Cell: ({row}) => {
          return (
            <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
              {row.original.erp_id}
            </p>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({row}) => {
          const statusColor = row.original.status === 1 ? 'success' : 'danger'
          return (
            <p
              className={`text-${statusColor} fw-bold text-hover-primary d-block fs-6 default-cursor`}
            >
              {row.original.status === 1 ? 'Active' : 'Inactive'}
            </p>
          )
        },
      },
      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <Context.Consumer>
              {(value) => {
                const {updateId} = value
                return (
                  <div
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    id='side_activities_toggle'
                    onClick={() => {
                      updateId({
                        id: row.original.business_unit_id,
                        type: 'edit_Business_unit',
                        fetchData,
                      })
                    }}
                  >
                    {is_admin === 1 ? (
                      <KTIcon iconName='pencil' className='fs-3' />
                    ) : (
                      <KTIcon iconName='eye' className='fs-3' />
                    )}
                  </div>
                )
              }}
            </Context.Consumer>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={businessUnitData ? businessUnitData : []}
      loading={isLoading}
      fetchData={fetchData}
    />
  )
}

export default BusinessBlock
