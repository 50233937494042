import {useEffect, useState} from 'react'
import useApiCall from '../custom_hooks/useApiCall'
import secureLocalStorage from 'react-secure-storage'
export function GetAllTimesheets() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: timesheetsData,
    isLoading: istripsLoading,
    fetchData,
  } = useApiCall(`/timesheets/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  return {timesheetsData, istripsLoading, fetchData}
}

export function GetAllApprovedTimesheets() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: approvedtimesheetsData,
    isLoading: isapprovedLoading,
    fetchData,
  } = useApiCall(`/timesheets/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  // Filter and return only approved leaves
  const approvedtimesheeets = approvedtimesheetsData?.filter(
    (leave) => leave.timesheet_status === 'Approved'
  )

  return {approvedtimesheeets, isapprovedLoading}
}

export function GetAllEmptimesheets() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }

  const {
    data: alltimesheetData,
    isLoading: istimesheetLoading,
    fetchData,
  } = useApiCall(emp_id ? `/timesheets/${emp_id}` : null)

  useEffect(() => {
    fetchData()
  }, [])

  return {alltimesheetData, istimesheetLoading}
}

export function GetAllApprovedEmpTimesheets() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: getalltimesheetData,
    isLoading: isallapprovedempLoading,
    fetchData,
  } = useApiCall(emp_id ? `/timesheets/${emp_id}` : null)

  useEffect(() => {
    fetchData()
  }, [])

  // Filter and return only approved leaves
  const allapprovedtimesheet = getalltimesheetData?.filter(
    (timesheet) => timesheet.timesheet_status === 'Approved'
  )

  return {allapprovedtimesheet, isallapprovedempLoading}
}
