import {useMemo, useState, useEffect} from 'react'

import {useIntl} from 'react-intl'

import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'

import usePageTitle from '../../custom_hooks/usePageTitle'

import useApiCall from '../../custom_hooks/useApiCall'

const LeaveEligibility2 = () => {
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_ELIGIBILITY'}))
  const [searchValue, setSearchValue] = useState('')
  const dateFormat = localStorage.getItem('dateFormat')

  const {data: employeesleaveEligibility, isLoading, fetchData} = useApiCall(`/leave/eligibility`)
  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const pageOptions = [5, 10, 20, 30] // Number of items to show per page

  const pageCount = Math.ceil(
    (employeesleaveEligibility && employeesleaveEligibility.length) || 0 / pageSize
  )

  const startIndex = pageIndex * pageSize
  const endIndex = startIndex + pageSize

  const gotoPage = (page) => {
    setPageIndex(page)
  }

  const previousPage = () => {
    setPageIndex((prev) => prev - 1)
  }

  const nextPage = () => {
    setPageIndex((prev) => prev + 1)
  }

  const canPreviousPage = pageIndex > 0
  const canNextPage = pageIndex < pageCount - 1

  const tableData = useMemo(() => {
    return employeesleaveEligibility?.filter((item) =>
      item.leave_type_name.toLowerCase().includes(searchValue.toLowerCase())
    )
  }, [employeesleaveEligibility, searchValue])

  const uniqueMembers = [...new Set(tableData?.map((item) => item.leave_type_id))]
  return (
    <>
      {isLoading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : employeesleaveEligibility !== null && employeesleaveEligibility.length > 0 ? (
        <>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <h3 className='fw-bolder my-2 default=cursor'>
              {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_ELIGIBILITY'})}
            </h3>

            <div className='d-flex my-2'>
              <div className='d-flex align-items-center position-relative me-4'>
                <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                <input
                  type='search'
                  id='kt_filter_search'
                  className='form-control form-control-white form-control-sm w-150px ps-9'
                  placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </div>
          <>
            <div className='card mb-5 mb-xl-8 p-10'>
              <table className='table table-row-dashed table-row-gray-300 align-middle'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px default-cursor'>
                      {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_ELIGIBILITY'})}
                    </th>
                    <th className='min-w-140px default-cursor'>
                      {intl.formatMessage({id: 'LABEL.CRITERIA_TYPE'})}
                    </th>
                    <th className='min-w-140px default-cursor'>
                      {intl.formatMessage({id: 'LABEL.CRITERIA_VALUE'})}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.length > 0 ? (
                    uniqueMembers.map((member) => {
                      const memberLeaves = tableData.filter((item) => item.leave_type_id === member)
                      return memberLeaves.map((item, index) => (
                        <tr key={index}>
                          {index === 0 && (
                            <td rowSpan={memberLeaves.length}>
                              <p className='text-dark fw-bold text-hover-primary fs-6 m-0 default-cursor'>
                                {item.leave_type_name}
                              </p>
                            </td>
                          )}
                          <td className='text-start align-middle'>
                            <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 default-cursor'>
                              {item.rule_type.charAt(0).toUpperCase() + item.rule_type.slice(1)}{' '}
                              {/* Capitalize the first letter */}
                            </p>
                          </td>
                          <td className='text-start align-middle'>
                            <p className='text-dark fw-bold text-hover-primary fs-6 m-0 default-cursor'>
                              {item.rule_value.match(/^\d+$/)
                                ? `${item.rule_value} Days`
                                : item.rule_value}
                            </p>
                          </td>
                        </tr>
                      ))
                    })
                  ) : (
                    <tr>
                      <td className='card'>
                        <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                          {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {tableData.length > 10 && (
              <Pagination>
                <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                {[...Array(pageCount)].map((_, i) => (
                  <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                    {i + 1}
                  </Pagination.Item>
                ))}

                <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />

                <div className='d-flex align-items-center mx-5'>
                  <span className='me-2'>{intl.formatMessage({id: 'BTN.SHOW'})}</span>
                  <select
                    className='form-select form-select-solid fw-bold w-75px me-2'
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                  >
                    {pageOptions.map((option) => (
                      <option key={option} value={option} className='select-bg'>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </Pagination>
            )}
          </>
        </>
      ) : (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <h1 className='mb-5'>{intl.formatMessage({id: 'MSG.NO_LEAVES_FOUND'})}</h1>
          </div>
        </div>
      )}
    </>
  )
}

export default LeaveEligibility2
