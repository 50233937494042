import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {registerLocale} from 'react-datepicker'
import {useThemeMode} from '../../../../_metronic/partials'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import es from 'date-fns/locale/es'
import ar from 'date-fns/locale/ar'
import fr from 'date-fns/locale/fr'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import React from 'react'
import '../team_leaves/teamleavestable.css'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../core'
import usePostApi from '../../custom_hooks/usePostApi'
import useApiCall from '../../custom_hooks/useApiCall'
import {DateBasicFormat} from '../../DateHelpers'
import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'

const AddLeaveDrawer = ({updateLeaveFunc}) => {
  const {execute} = usePostApi()
  const {data: leavetypesDetails} = useApiCall('/masterData/leave_types')

  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const intl = useIntl()
  const lang = useLang()

  const [locale, setLocale] = React.useState('en')

  const [isSubmitting, setIsSubmitting] = useState(false)

  const {mode} = useThemeMode()
  const [darkMode, setDarkMode] = useState(false)
  // const tomorrow = addDays(new Date(), 1)

  useEffect(() => {
    setDarkMode(mode !== 'light')
  })

  useEffect(() => {
    if (lang === 'ar') {
      setLocale('ar')
    } else {
      setLocale(lang)
    }
  })

  const validationSchema = Yup.object().shape({
    leaveType: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.LEAVE_TYPE_IS_REQUIRED'})}`
    ),
    start_date: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.START_DATE_IS_REQUIRED'})}`
    ),
    end_date: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.END_DATE_IS_REQUIRED'})}`),
    leaveComments: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.REASON_IS_REQUIRED'})}`
    ),
    // no_of_days: Yup.string().required(
    //   `${intl.formatMessage({id: 'ERR.MSG.NO_OF_DAYS_IS_REQUIRED'})}`
    // ),
  })

  const initialValues = {
    leaveType: '',
    start_date: '',
    end_date: '',
    no_of_days: '',
    leaveComments: '',
  }

  registerLocale('es', es)
  registerLocale('ar', ar)
  registerLocale('fr', fr)

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    const organizationId = secureLocalStorage.getItem('organization_id')
    try {
      const selectedLeaveType = leavetypesDetails.find(
        (leaveType) => leaveType.leave_type_name === values.leaveType
      )
      const url = `${baseUrl}/leave/employees`

      const formData = {
        leave_type_id: selectedLeaveType.leave_type_id,
        leave_type_name: values.leaveType,
        start_date: DateBasicFormat(values.start_date),
        end_date: DateBasicFormat(values.end_date),
        no_of_days: values.no_of_days,
        counted_no_of_days: values.no_of_days,
        hr_reviewed_status: 'Pending',
        status: 'Manager Pending',
        reviewer_status: 'Pending',

        organization_id: organizationId,

        leave_comments: values.leaveComments,
      }

      await execute(url, 'POST', formData)

      resetForm()
      updateLeaveFunc()
      Swal.fire({
        title: intl.formatMessage({id: 'MSG.LEAVE_APPLIED_SUCCESSFULLY'}),
        text: intl.formatMessage({
          id: 'YOU_CAN_NOW_TRACK_THE_STATUS_OF_THE_LEAVE_IN_THE_MY_LEAVES_SECTION',
        }),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('employeeInfoDrawerClose')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  const handleCancel = (resetForm, touched) => {
    if (Object.keys(touched).length === 0) {
      const sideDrawer = document.getElementById('employeeInfoDrawerClose')
      if (sideDrawer) {
        sideDrawer.click()
      }
    } else {
      resetForm()
    }
  }

  const calculateNoOfDays = (startDate, endDate) => {
    if (startDate && endDate) {
      const timeDifference = endDate.getTime() - startDate.getTime()
      const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1

      return daysDifference
    } else {
      return 0
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div
        id='employeeleaveInfo'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='activities'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'90%', 'lg': '400px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#applyleave'
        data-kt-drawer-close='#employeeInfoDrawerClose'
      >
        <div className='w-100'>
          <div className='card-header'>
            <h3 className='card-title fw-bolder text-dark'>
              {intl.formatMessage({id: 'LABEL.APPLY_LEAVE'})}
            </h3>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='employeeInfoDrawerClose'
              >
                <i className='bi bi-x fs-1'></i>
              </button>
            </div>
          </div>
          <div className=' m-8 mt-6 mb-7'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({values, setFieldValue, handleChange, resetForm, touched}) => (
                <Form>
                  <div className=''>
                    <div className='modal-body'>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.LEAVE_TYPE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='leaveType'
                            value={values.leaveType || ''}
                            onChange={(e) => setFieldValue('leaveType', e.target.value)}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_LEAVE_TYPE'})}
                            </option>

                            {leavetypesDetails?.map((leaveType) => (
                              <option
                                key={leaveType.leave_type_id}
                                value={leaveType.leave_type_name}
                              >
                                {leaveType.leave_type_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name='leaveType' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.REASON'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='leaveComments'
                            className='form-control form-control-solid'
                            value={values.leaveComments || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.REASON'})}
                            onChange={(e) => setFieldValue('leaveComments', e.target.value)}
                          />
                          <ErrorMessage
                            name='leaveComments'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {' '}
                          {intl.formatMessage({id: 'LABEL.START_DATE'})}
                        </label>

                        <div className='col-lg-8 text-start'>
                          <Field name='start_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('start_date', date)
                                setFieldValue(
                                  'no_of_days',
                                  calculateNoOfDays(date, values.end_date)
                                )
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({
                                      id: 'LABEL.SELECT_START_DATE',
                                    })}
                                    minDate={new Date()}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    dateFormat='dd/MM/yyyy'
                                    className='form-control form-control-solid'
                                    popperPlacement='bottom'
                                    locale={locale}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage name='start_date' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.END_DATE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field name='end_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('end_date', date)
                                setFieldValue(
                                  'no_of_days',
                                  calculateNoOfDays(values.start_date, date)
                                )
                              }

                              let minDate = null
                              if (values && values.start_date) {
                                const departureDate = new Date(values.start_date)
                                minDate = departureDate.setDate(departureDate.getDate())
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({
                                      id: 'LABEL.SELECT_END_DATE',
                                    })}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    dateFormat='dd/MM/yyyy'
                                    className='form-control form-control-solid'
                                    popperPlacement='bottom'
                                    minDate={minDate}
                                    locale={locale}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage name='end_date' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.NO_OF_DAYS'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='no_of_days'
                            className='form-control form-control-solid'
                            value={values.no_of_days}
                            placeholder={intl.formatMessage({id: 'LABEL.NO_OF_DAYS'})}
                            onChange={(e) => setFieldValue('no_of_days', e.target.value)}
                            readOnly={true}
                          />
                          <ErrorMessage name='no_of_days' component='div' className='text-danger' />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card-footer p-0 py-5 text-end'>
                    <button
                      type='button'
                      className='btn btn-light me-2 btn-sm'
                      onClick={() => handleCancel(resetForm, touched)}
                    >
                      {Object.keys(touched).length === 0
                        ? intl.formatMessage({id: 'BTN.CLOSE'})
                        : intl.formatMessage({id: 'BTN.CLEAR'})}
                    </button>

                    <button
                      type='submit'
                      className='btn btn-sm btn-light-primary ms-2'
                      onClick={() => {
                        setIsSubmitting(true)
                        // ErrorMessageHandling()
                      }}
                    >
                      {intl.formatMessage({id: 'BTN.APPLY_LEAVE'})}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddLeaveDrawer
