import {useEffect, useState} from 'react'
import useApiCall from '../custom_hooks/useApiCall'
import {useContext} from 'react'
import VacancyContext from './vacancies/VacancyContext'
export function GetAllVacancies() {
  const {
    data: vacanciesData,
    isLoading: isVacancyLoading,
    fetchData,
  } = useApiCall(`/recruitment/vacancies`)

  useEffect(() => {
    fetchData()
  }, [])

  return {vacanciesData, isVacancyLoading, fetchData}
}

export function GetAllEmployees() {
  const {
    data: employeesData,
    isLoading: isEmployeeLoading,
    fetchData,
  } = useApiCall('/hrConfig/employees/all')

  useEffect(() => {
    fetchData()
  }, [])

  return {employeesData, isEmployeeLoading}
}

export function GetCandidates() {
  const {
    data: candidatesData,
    isLoading: isEmployeeLoading,
    fetchData,
  } = useApiCall(`/recruitment/candidates`)

  useEffect(() => {
    fetchData()
  }, [])

  return {candidatesData, isEmployeeLoading, fetchData}
}

export function GetAllCandidates() {
  const {VACANCYID} = useContext(VacancyContext)
  const getuserID = localStorage.getItem('CANDID')
  const vacancyIdString = parseInt(VACANCYID)
  let candidateID = null

  if (vacancyIdString !== 0) {
    candidateID = vacancyIdString
  } else {
    candidateID = getuserID
  }
  const {
    data: candidatesData,
    isLoading: isEmployeeLoading,
    fetchData,
  } = useApiCall(
    candidateID !== null && candidateID != '"0"' ? `/recruitment/candidates/${candidateID}` : null
  )

  useEffect(() => {
    fetchData()
  }, [])

  return {candidatesData, isEmployeeLoading}
}
