import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import DateFormatter from '../../DateFormatter'
import es from 'date-fns/locale/es'
import ar from 'date-fns/locale/ar'
import fr from 'date-fns/locale/fr'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import usePageTitle from '../../custom_hooks/usePageTitle'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../../_metronic/partials'
import DatePicker from 'react-datepicker'
import VacancyContext from './VacancyContext'
import {Link} from 'react-router-dom'
import React from 'react'
import {GetAllVacancies} from '../RecruitmentAPI'
import {registerLocale} from 'react-datepicker'
const DataTable = ({columns, data, loading, fetchData}) => {
  const [inputVal, setInputVal] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const [locale, setLocale] = React.useState('en')
  const intl = useIntl()
  const {mode} = useThemeMode()
  const lang = useLang()
  usePageTitle(intl.formatMessage({id: 'MENU.RECRUITMENT.VACANCIES'}))
  // Filter data based on input value
  const filteredData = useMemo(() => {
    return data.filter((each) => each.qualifications.toLowerCase().includes(inputVal.toLowerCase()))
  }, [data, inputVal])

  // Create separate datasets for active and inactive records

  // Table instances for active and inactive datasets

  const filteredDataByDate = useMemo(() => {
    if (!selectedDate) {
      return filteredData
      // No date selected, return the current filtered data
    }

    // Adjust the selected date to account for the time zone offset
    const selectedDateStr = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10) // Convert selected date to 'yyyy-MM-dd' format

    return filteredData.filter((each) => {
      return each.created_timestamp.slice(0, 10) === selectedDateStr
    })
  }, [selectedDate, filteredData])
  const activeData = useMemo(() => {
    return filteredDataByDate?.filter((request) => request.vacancy_status === 'Open')
  }, [filteredDataByDate])

  const inactiveData = useMemo(() => {
    return filteredDataByDate?.filter((request) => request.vacancy_status === 'Closed')
  }, [filteredDataByDate])

  useEffect(() => {
    if (lang === 'ar') {
      setLocale('ar')
    } else {
      setLocale(lang)
    }
  })

  registerLocale('es', es)
  registerLocale('ar', ar)
  registerLocale('fr', fr)

  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  // Extract required values from table instances

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }

  const pageOptions = [5, 10, 20, 30, 40, 50]

  // Handle pagination for inactive dataset

  return (
    <VacancyContext.Consumer>
      {(value) => {
        const {VACANCYID, updateVACANCYID} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='d-flex'>
                  <div className='d-flex flex-wrap flex-stack mb-6'>
                    <h3 className='fw-bolder my-2'>
                      {' '}
                      {intl.formatMessage({id: 'MENU.RECRUITMENT.VACANCIES'})}
                    </h3>
                  </div>
                </div>
                <div className='card'>
                  <>
                    <div className='card-header border-0 pt-5'>
                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='fw-bolder'>
                          <ul className='nav'>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_1'
                              >
                                {intl.formatMessage({id: 'LABEL.OPEN'})}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                href='#kt_table_widget_4_tab_2'
                              >
                                {intl.formatMessage({id: 'LABEL.CLOSE'})}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='card-toolbar'>
                        <div className='d-flex align-items-center position-relative me-4'>
                          <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                          <input
                            type='search'
                            id='kt_filter_search'
                            className='form-control form-control-white form-control-sm w-150px ps-9'
                            placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                            value={inputVal}
                            onChange={(e) => setInputVal(e.target.value)}
                          />
                        </div>

                        {/* <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                          <KTIcon iconName='calendar' className='fs-2 m-0' />
                        </button> */}

                        <div
                          className={`react-datepicker-fullwidth-wrapper test me-4 ${
                            mode === 'dark' ? 'dark-mode' : 'light-mode'
                          }`}
                        >
                          <DatePicker
                            className='form-control form-control-sm'
                            selected={selectedDate}
                            isClearable
                            showYearDropdown
                            scrollableYearDropdown
                            dateFormat='dd/MM/yyyy'
                            onChange={(date) => setSelectedDate(date)}
                            placeholderText={intl.formatMessage({id: 'LABEL.SELECT_DATE'})}
                            showMonthDropdown
                            useShortMonthInDropdown
                            locale={locale}
                          />
                        </div>

                        <div>
                          <button
                            className='btn btn-sm btn-light-primary'
                            id='side_activities_toggle'
                            onClick={() => {
                              updateVACANCYID({
                                type: 'add_vacancy',
                                fetchData,
                              })
                            }}
                          >
                            <KTIcon iconName='plus' className='fs-3' />
                            {intl.formatMessage({id: 'BTN.ADD_VACANCIES'})}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='tab-content p-2 pt-0'>
                      <div
                        className='card-body tab-pane fade show active table-responsive'
                        id='kt_table_widget_4_tab_1'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...activeTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-150px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.POSITION'})}
                              </th>
                              <th className='min-w-140px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.DEPARTMENTS'})}
                              </th>
                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.HIRING_MANAGER'})}
                              </th>
                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.NO_OF_VACANCIES'})}
                              </th>
                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                              </th>
                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATE'})}
                              </th>
                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.CITY'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.CREATED_DATE'})}
                              </th>

                              <th className='min-w-100px text-center default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th>

                              <th className='min-w-100px text-center default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>

                          <tbody {...activeTableInstance.getTableBodyProps()}>
                            {activePage.length > 0 ? (
                              activePage.map((row) => {
                                activeTableInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                                <td className='card'>
                                  <p></p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoActivePage(0)}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={activeTableInstance.previousPage}
                              disabled={!activeTableInstance.canPreviousPage}
                            />
                            {[...Array(activePageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === activePageIndex}
                                onClick={() => gotoActivePage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={activeTableInstance.nextPage}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoActivePage(activePageCount - 1)}
                              disabled={!activeTableInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={activePageSize}
                                onChange={(e) => setActivePageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>

                      <div
                        className='card-body tab-pane fade  table-responsive'
                        id='kt_table_widget_4_tab_2'
                      >
                        <table
                          className='table table-row-dashed align-middle table-row-gray-300'
                          {...inactiveTableInstance.getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-150px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.POSITION'})}
                              </th>
                              <th className='min-w-140px default-cursor'>
                                {intl.formatMessage({id: 'LABEL.DEPARTMENTS'})}
                              </th>
                              <th className='min-w-120px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.HIRING_MANAGER'})}
                              </th>
                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.NO_OF_VACANCIES'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                              </th>
                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATE'})}
                              </th>
                              <th className='min-w-100px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.CITY'})}
                              </th>

                              <th className='min-w-100px text-start default-cursor'>
                                {' '}
                                {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                              </th>

                              <th className='min-w-100px text-center default-cursor'>
                                {intl.formatMessage({id: 'LABEL.STATUS'})}
                              </th>

                              <th className='min-w-100px text-center default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>

                          <tbody {...inactiveTableInstance.getTableBodyProps()}>
                            {inactivePage.length > 0 ? (
                              inactivePage.map((row) => {
                                inactiveTableInstance.prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className=' ' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                                <td>
                                  <p></p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {data.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoInactivePage(0)}
                              disabled={!inactiveTableInstance.canPreviousPage}
                            />
                            <Pagination.Prev
                              onClick={inactiveTableInstance.previousPage}
                              disabled={!inactiveTableInstance.canPreviousPage}
                            />
                            {[...Array(inactivePageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === inactivePageIndex}
                                onClick={() => gotoInactivePage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={inactiveTableInstance.nextPage}
                              disabled={!inactiveTableInstance.canNextPage}
                            />
                            <Pagination.Last
                              onClick={() => gotoInactivePage(inactivePageCount - 1)}
                              disabled={!inactiveTableInstance.canNextPage}
                            />
                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={inactivePageSize}
                                onChange={(e) => setInactivePageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>
                    </div>
                  </>
                </div>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1 className='mb-5'> {intl.formatMessage({id: 'LABEL.NO_VACANCIES_FOUND'})}</h1>
                  <button
                    className='btn btn-sm btn-light-primary'
                    id='side_activities_toggle'
                    onClick={() => {
                      updateVACANCYID({
                        type: 'add_vacancy',
                        fetchData,
                      })
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    {intl.formatMessage({id: 'BTN.ADD_VACANCIES'})}
                  </button>
                </div>
              </div>
            )}
            <SideDrawer value={VACANCYID} />
          </>
        )
      }}
    </VacancyContext.Consumer>
  )
}

const VacanciesTable = () => {
  const {vacanciesData, isVacancyLoading: isLoading, fetchData} = GetAllVacancies()

  const columns = useMemo(
    () => [
      {
        Header: 'Position',
        accessor: 'Request Type',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.role_name}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Department Name',
        accessor: 'Department Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.department_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'HiringManager Name',
        accessor: 'HiringManager Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.hiring_manager_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Number of  Vacancies',
        accessor: 'Number of Vacancies',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.number_of_vacancies}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Country Name',
        accessor: 'Country Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.country_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'State Name',
        accessor: 'State Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.state_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'City Name',
        accessor: 'City Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.city_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Created Timestamp',
        accessor: 'Created Timestamp',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.created_timestamp.slice(0, 10))}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Vacancy Status',
        accessor: 'Vacancy Status',
        Cell: ({row}) => {
          const statusColor =
            row.original.vacancy_status === 'Open'
              ? 'success'
              : row.original.vacancy_status === 'Closed'
              ? 'danger'
              : 'danger'
          return (
            <p
              className={`text-${
                statusColor === 'info' ? 'info' : statusColor
              } fw-bold text-center text-hover-primary d-block fs-6 default-cursor`}
            >
              {row.original.vacancy_status}
            </p>
          )
        },
      },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <VacancyContext.Consumer>
              {(value) => {
                const {updateVACANCYID} = value

                return (
                  <>
                    <div className='d-flex flex-row justify-content-center align-items-center'>
                      <div className='d-flex justify-content-start flex-shrink-0 me-2'>
                        <button
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          id='side_activities_toggle'
                          onClick={() => {
                            updateVACANCYID({
                              id: row.original.id,
                              type: 'edit_vacancies',
                              fetchData,
                            })
                          }}
                        >
                          <KTIcon iconName='eye' className='fs-3' />
                        </button>
                      </div>

                      <div className=''>
                        <Link
                          to='/recruitment/candidates'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          onClick={() => {
                            updateVACANCYID(row.original.id)
                          }}
                        >
                          <KTIcon iconName='arrow-right' className='fs-2' />
                        </Link>
                      </div>
                    </div>
                  </>
                )
              }}
            </VacancyContext.Consumer>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={vacanciesData ? vacanciesData : []}
      loading={isLoading}
      fetchData={fetchData}
    />
  )
}

export default VacanciesTable
