/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import secureLocalStorage from 'react-secure-storage'
import Swal from 'sweetalert2'
import {baseUrl} from '../../../core'
import usePostApi from '../../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import useApiCall from '../../../custom_hooks/useApiCall'
const PasswordPreferences = () => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {is_admin, organization_id} = userInfo || {}
  const {data, fetchData, isLoading}: any | null = useApiCall(`/pref/password/${organization_id}`)
  let passwordPreference = data && data[0]
  const [isEditMode, setIsEditMode] = useState(false)
  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleCancelClick = () => {
    setIsEditMode(false)
  }
  const validationSchema = Yup.object().shape({})
  const handleEditSubmit = async (values: any) => {
    const url = `${baseUrl}/pref/password/${organization_id}`
    setIsEditMode(false)
    try {
      const result = await Swal.fire({
        title: intl.formatMessage({id: 'LABEL.UPDATING_THE_PASSWORD_PREFERENCES'}),
        text: intl.formatMessage({id: 'LABEL.CONFIRM_UPDATE_WORK_PREFERENCES'}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })

      if (result.isConfirmed) {
        const requestData = {
          organization_id: organization_id,
          password_encryption_policy: values.password_encryption_policy,
          password_maximum_age: parseInt(values.password_maximum_age),
          password_maximum_history: parseInt(values.password_maximum_history),
        }
        await execute(url, 'PATCH', requestData, id)
        Swal.fire({
          title: intl.formatMessage({id: 'LABEL.PASSWORD_PREFERENCES_EDITED'}),
          text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-light-primary btn-sm',
          },
        })
        fetchData()
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  return (
    <div className={`card card-xl-stretch`}>
      {isLoading ? (
        <div className='card card-xl-stretch h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={{
            password_encryption_policy: passwordPreference?.password_encryption_policy || '',
            password_maximum_age: passwordPreference?.password_maximum_age || '',
            password_maximum_history: passwordPreference?.password_maximum_history || '',
          }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleEditSubmit}
        >
          {({values, handleChange}) => {
            return (
              <Form>
                <div className='card-header'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-5 mb-1'>
                      {intl.formatMessage({id: 'LABEL.ORGANIZATION_PASSWORD_PREFERENCES'})}
                    </span>
                    <span className='text-muted fw-bold fs-7'>
                      {intl.formatMessage({id: 'LABEL.ORGANIZATION_STRONG_PASSWORD'})}
                    </span>
                  </h3>
                  {is_admin === 1 && (
                    <div className='card-toolbar'>
                      {!isEditMode && (
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary btn-ripple'
                          onClick={handleEditClick}
                        >
                          <KTIcon iconName='pencil' className='fs-7' />
                          {intl.formatMessage({id: 'BTN.EDIT'})}
                        </button>
                      )}
                      {isEditMode && (
                        <React.Fragment>
                          <button className='btn btn-light-primary btn-sm' type='submit'>
                            <KTIcon iconName='check' className='fs-7' />
                            {intl.formatMessage({id: 'BTN.SAVE'})}
                          </button>

                          <div
                            className='btn btn-icon btn-sm btn-active-light-danger ms-2'
                            onClick={() => handleCancelClick()}
                          >
                            <KTIcon iconName='cross' className='fs-2' />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  )}
                </div>
                <div className='card-body pb-6'>
                  <div className='d-flex mb-4'>
                    <div className='col-3 d-flex'>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label'>
                          <KTIcon iconName='lock-3' className='fs-2x' />
                        </span>
                      </div>
                    </div>
                    <div className='col-9 d-flex'>
                      {isEditMode ? (
                        <div className='form-floating flex-fill'>
                          <Field
                            as='select'
                            id='password_maximum_history'
                            name='password_maximum_history'
                            className='form-select'
                            value={values.password_maximum_history}
                            onChange={handleChange}
                          >
                            <option value='5'>Last 5 Passwords</option>
                            <option value='10'>Last 10 Passwords</option>
                            <option value='15'>Last 15 Passwords</option>
                          </Field>
                          <label htmlFor='password_maximum_history'>
                            {intl.formatMessage({id: 'LABEL.ORGANIZATION_MAX_PASSWORD_HISTORY'})}
                          </label>
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='password_maximum_history'
                          />
                        </div>
                      ) : (
                        <>
                          <div className='form-floating'>
                            <p className='text-dark fw-bold text-hover-primary my-1 fs-6'>
                              Last {passwordPreference?.password_maximum_history} Password
                            </p>
                            <span className='text-muted fw-bold d-block fs-7'>
                              {intl.formatMessage({id: 'LABEL.ORGANIZATION_MAX_PASSWORD_HISTORY'})}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='d-flex mb-4'>
                    <div className='col-3 d-flex'>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label'>
                          <KTIcon iconName='lock-3' className='fs-2x' />
                        </span>
                      </div>
                    </div>
                    <div className='col-9 d-flex'>
                      {isEditMode ? (
                        <div className='form-floating flex-fill'>
                          <Field
                            as='select'
                            id='password_maximum_age'
                            name='password_maximum_age'
                            className='form-select'
                            value={values.password_maximum_age}
                            onChange={handleChange}
                          >
                            <option value='5'>5 days</option>
                            <option value='60'>60 days</option>
                            <option value='90'>90 days</option>
                            <option value='180'>180 days</option>
                          </Field>
                          <label htmlFor='password_maximum_age'>
                            {intl.formatMessage({id: 'LABEL.ORGANIZATION_FORCE_PASSWORD_EXPIRY'})}
                          </label>
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='password_maximum_age'
                          />
                        </div>
                      ) : (
                        <>
                          <div className='form-floating'>
                            <p className='text-dark fw-bold text-hover-primary my-1 fs-6'>
                              {passwordPreference?.password_maximum_age}{' '}
                              {intl.formatMessage({id: 'LABEL.DAYS'})}
                            </p>
                            <span className='text-muted fw-bold d-block fs-7'>
                              {intl.formatMessage({id: 'LABEL.ORGANIZATION_FORCE_PASSWORD_EXPIRY'})}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='d-flex'>
                    <div className='col-3 d-flex'>
                      <div className='symbol symbol-50px me-2'>
                        <span className='symbol-label'>
                          <KTIcon iconName='lock-3' className='fs-2x' />
                        </span>
                      </div>
                    </div>
                    <div className='col-9 d-flex'>
                      {isEditMode ? (
                        <div className='form-floating flex-fill'>
                          <Field
                            as='select'
                            id='password_encryption_policy'
                            name='password_encryption_policy'
                            className='form-select'
                            value={values.password_encryption_policy}
                            onChange={handleChange}
                          >
                            <option value='SHA256'>SHA256</option>
                            <option value='SHA512'>SHA512</option>
                          </Field>
                          <label htmlFor='password_encryption_policy'>
                            {intl.formatMessage({id: 'LABEL.ORGANIZATION_ENCRYPTION_ALGORITHM'})}
                          </label>
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='password_encryption_policy'
                          />
                        </div>
                      ) : (
                        <>
                          <div className='form-floating'>
                            <p className='text-dark fw-bold text-hover-primary my-1 fs-6'>
                              {passwordPreference?.password_encryption_policy}
                            </p>
                            <span className='text-muted fw-bold d-block fs-7'>
                              {intl.formatMessage({id: 'LABEL.ORGANIZATION_ENCRYPTION_ALGORITHM'})}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

export {PasswordPreferences}
