import {useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {useIntl} from 'react-intl'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import DateFormatter from '../../DateFormatter'
import secureLocalStorage from 'react-secure-storage'
import usePageTitle from '../../custom_hooks/usePageTitle'
import useApiCall from '../../custom_hooks/useApiCall'
import {useEffect} from 'react'
const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()

  usePageTitle(intl.formatMessage({id: 'MENU.LEAVE_ALLOCATIONS'}))

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const leaveTypeMatches =
            each.leave_type_name &&
            each.leave_type_name.toLowerCase().includes(inputVal.toLowerCase())

          return leaveTypeMatches
        })
      : []
  }, [inputVal, data])

  const tableInstance = useTable({columns, data: filteredData}, usePagination)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [5, 10, 20, 30, 40, 50]

  // Render the component

  return (
    <>
      {loading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : data.length > 0 ? (
        <>
          <div className='card-header border-0 pt-5'>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h3 className='fw-bolder my-3 default-cursor'>
                {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.LEAVE_ALLOCATIONS'})}
              </h3>
              <div className='d-flex align-items-center'>
                <div className='d-flex my-2'>
                  <div className='card-toolbar'>
                    <div className='d-flex align-items-center position-relative me-4'>
                      <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                      <input
                        type='search'
                        id='kt_filter_search'
                        className='form-control form-control-white form-control-sm w-150px ps-9'
                        placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                        value={inputVal}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='mb-5 mb-xl-8 p-10 pb-0'>
              <table
                className='table table-row-dashed table-row-gray-300 align-middle'
                {...getTableProps()}
              >
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-140px text-start  default-cursor'>
                      {intl.formatMessage({id: 'LABEL.LEAVE_TYPE'})}
                    </th>
                    <th className='min-w-120pxtext-start default-cursor'>
                      {intl.formatMessage({id: 'LABEL.LEAVE_BALANCE'})}
                    </th>
                    <th className='min-w-100px text-start  default-cursor'>
                      {intl.formatMessage({id: 'LABEL.LEAVE_LIMIT'})}
                    </th>
                    <th className='min-w-100px text-start default-cursor'>
                      {intl.formatMessage({id: 'LABEL.ACTIVE_DATE'})}
                    </th>
                    <th className='min-w-100px text-start  default-cursor'>
                      {intl.formatMessage({id: 'LABEL.EXPIRY_DATE'})}
                    </th>
                  </tr>
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page && page.length > 0 ? (
                    page.map((row) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => (
                            <td className='text-center' {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td className='card'>
                        <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                          {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {filteredData.length > 10 && (
                <Pagination>
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                  {[...Array(pageCount)].map((_, i) => (
                    <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                      {i + 1}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                  <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  />

                  <div className='d-flex align-items-center mx-5'>
                    <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                    <select
                      className='form-select form-select-solid fw-bold w-75px me-2'
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <h1 className='mb-5'>{intl.formatMessage({id: 'MSG.NO_LEAVES_FOUND'})}</h1>
          </div>
        </div>
      )}
    </>
  )
}

const LeaveAllocations = () => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }

  const {data: tableData, isLoading, fetchData} = useApiCall(`/leave/allocation/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Leave Type Name',
        accessor: 'Leave Type Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6'>
                {row.original.leave_type_name}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Start Date',
        accessor: 'Start Date',
        Cell: ({row}) => {
          return (
            <div className='text-end'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 text-start'>
                {row.original.leave_balance}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'End Date',
        accessor: 'End Date',
        Cell: ({row}) => {
          return (
            <div className='text-end'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 text-start'>
                {row.original.leave_limit}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'No of Days',
        accessor: 'No of Days',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 '>
                {DateFormatter(row.original.active_date)}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Expiry Date',
        accessor: 'Expiry Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 '>
                {DateFormatter(row.original.expiry_date)}
              </p>
            </div>
          )
        },
      },
    ],
    []
  )
  return <DataTable columns={columns} data={tableData ? tableData : []} loading={isLoading} />
}

export default LeaveAllocations
