/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {useIntl} from 'react-intl'

const Footer = () => {
  const intl = useIntl()
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      {/* <li className='menu-item'>
        <a href='https://kloudworx.com/' target='_blank' className='menu-link px-2'>
          Kloudworx
        </a>
      </li> */}
      <div className='text-dark order-2 order-md-1'>
        <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span>
        <a href='https://zylk.io/' target='_blank' className='text-gray-800 text-hover-primary'>
          {intl.formatMessage({id: 'ZYLK.A_PRODUCT_BY_KLOUDWORX'})}
        </a>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
