import {useMemo, useState, useEffect} from 'react'
import {useTable, usePagination} from 'react-table'
import {useIntl} from 'react-intl'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import usePageTitle from '../../custom_hooks/usePageTitle'
import DateFormatter from '../../DateFormatter'
import secureLocalStorage from 'react-secure-storage'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import useApiCall from '../../custom_hooks/useApiCall'
const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.HR_CONFIGURATION.HOLIDAYS'}))
  // Filter data based on input value
  const filteredData = useMemo(() => {
    return data.filter((each) => each.holiday_name.toLowerCase().includes(inputVal.toLowerCase()))
  }, [data, inputVal])

  // Create separate datasets for active and inactive records
  const currentYear = new Date().getFullYear()

  const activeData = useMemo(() => {
    return filteredData?.filter((request) => {
      const holidayYear = new Date(request.holiday_date).getFullYear()
      if (holidayYear === currentYear) {
        return true
      }
      return false
    })
  }, [filteredData, currentYear])

  const inactiveData = useMemo(() => {
    return filteredData?.filter((request) => {
      const holidayYear = new Date(request.holiday_date).getFullYear()
      if (holidayYear === currentYear + 1) {
        return true
      }
      return false
    })
  }, [filteredData, currentYear])

  // Table instances for active and inactive datasets
  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset

  const pageOptions = [5, 10, 20, 30, 40, 50]
  // Render the component

  return (
    <Context.Consumer>
      {(value) => {
        const {id, updateId} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='card'>
                  {data.length > 0 ? (
                    <>
                      <div className='card-header border-0 pt-5'>
                        <div className='d-flex flex-wrap flex-stack'>
                          <div className='fw-bolder'>
                            <ul className='nav'>
                              <li className='nav-item'>
                                <a
                                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                                  data-bs-toggle='tab'
                                  href='#kt_table_widget_4_tab_1'
                                >
                                  {intl.formatMessage({id: 'LABEL.CURRENT_YEAR'})}
                                </a>
                              </li>
                              <li className='nav-item'>
                                <a
                                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                  data-bs-toggle='tab'
                                  href='#kt_table_widget_4_tab_2'
                                >
                                  {intl.formatMessage({id: 'LABEL.NEXT_YEAR'})}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className='card-toolbar'>
                          <div className='d-flex align-items-center position-relative me-4'>
                            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                            <input
                              type='search'
                              id='kt_filter_search'
                              className='form-control form-control-white form-control-sm w-150px ps-9'
                              placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                              value={inputVal}
                              onChange={(e) => setInputVal(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='tab-content p-2 pt-0'>
                        <div
                          className='card-body tab-pane fade show active table-responsive'
                          id='kt_table_widget_4_tab_1'
                        >
                          <table
                            className='table table-row-dashed align-middle table-row-gray-300'
                            {...activeTableInstance.getTableProps()}
                          >
                            <thead>
                              <tr className='fw-bold text-muted'>
                                <th className='min-w-25px text-muted text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.OCCASION'})}
                                </th>
                                <th className='min-w-25px  text-muted text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.DATE'})}
                                </th>

                                <th className='min-w-25px  text-muted text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.DAY_TYPE'})}
                                </th>
                              </tr>
                            </thead>

                            <tbody {...activeTableInstance.getTableBodyProps()}>
                              {activePage.length > 0 ? (
                                activePage.map((row) => {
                                  activeTableInstance.prepareRow(row)
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => (
                                        <td className=' ' {...cell.getCellProps()}>
                                          {cell.render('Cell')}
                                        </td>
                                      ))}
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td className='card'>
                                    <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                      {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                    </p>
                                  </td>
                                  <td>
                                    <p></p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          {data.length > 10 && (
                            <Pagination>
                              <Pagination.First
                                onClick={() => gotoActivePage(0)}
                                disabled={!activeTableInstance.canPreviousPage}
                              />
                              <Pagination.Prev
                                onClick={activeTableInstance.previousPage}
                                disabled={!activeTableInstance.canPreviousPage}
                              />
                              {[...Array(activePageCount)].map((_, i) => (
                                <Pagination.Item
                                  key={i}
                                  active={i === activePageIndex}
                                  onClick={() => gotoActivePage(i)}
                                >
                                  {i + 1}
                                </Pagination.Item>
                              ))}
                              <Pagination.Next
                                onClick={activeTableInstance.nextPage}
                                disabled={!activeTableInstance.canNextPage}
                              />
                              <Pagination.Last
                                onClick={() => gotoActivePage(activePageCount - 1)}
                                disabled={!activeTableInstance.canNextPage}
                              />
                              <div className='d-flex align-items-center mx-5'>
                                <span className='me-2'>
                                  {intl.formatMessage({id: 'LABEL.SHOW'})}
                                </span>
                                <select
                                  className='form-select form-select-solid fw-bold w-75px me-2'
                                  value={activePageSize}
                                  onChange={(e) => setActivePageSize(Number(e.target.value))}
                                >
                                  {pageOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Pagination>
                          )}
                        </div>

                        <div
                          className='card-body tab-pane fade  table-responsive'
                          id='kt_table_widget_4_tab_2'
                        >
                          <table
                            className='table table-row-dashed align-middle table-row-gray-300'
                            {...inactiveTableInstance.getTableProps()}
                          >
                            <thead>
                              <tr className='fw-bold text-muted'>
                                <th className='min-w-25px text-muted text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.OCCASION'})}
                                </th>
                                <th className='min-w-25px  text-muted text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.DATE'})}
                                </th>

                                <th className='min-w-25px  text-muted text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.DAY_TYPE'})}
                                </th>
                              </tr>
                            </thead>

                            <tbody {...inactiveTableInstance.getTableBodyProps()}>
                              {inactivePage.length > 0 ? (
                                inactivePage.map((row) => {
                                  inactiveTableInstance.prepareRow(row)
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => (
                                        <td className=' ' {...cell.getCellProps()}>
                                          {cell.render('Cell')}
                                        </td>
                                      ))}
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td className='card'>
                                    <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                      {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                    </p>
                                  </td>
                                  <td>
                                    <p></p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          {data.length > 10 && (
                            <Pagination>
                              <Pagination.First
                                onClick={() => gotoInactivePage(0)}
                                disabled={!inactiveTableInstance.canPreviousPage}
                              />
                              <Pagination.Prev
                                onClick={inactiveTableInstance.previousPage}
                                disabled={!inactiveTableInstance.canPreviousPage}
                              />
                              {[...Array(inactivePageCount)].map((_, i) => (
                                <Pagination.Item
                                  key={i}
                                  active={i === inactivePageIndex}
                                  onClick={() => gotoInactivePage(i)}
                                >
                                  {i + 1}
                                </Pagination.Item>
                              ))}
                              <Pagination.Next
                                onClick={inactiveTableInstance.nextPage}
                                disabled={!inactiveTableInstance.canNextPage}
                              />
                              <Pagination.Last
                                onClick={() => gotoInactivePage(inactivePageCount - 1)}
                                disabled={!inactiveTableInstance.canNextPage}
                              />
                              <div className='d-flex align-items-center mx-5'>
                                <span className='me-2'>
                                  {intl.formatMessage({id: 'LABEL.SHOW'})}
                                </span>
                                <select
                                  className='form-select form-select-solid fw-bold w-75px me-2'
                                  value={inactivePageSize}
                                  onChange={(e) => setInactivePageSize(Number(e.target.value))}
                                >
                                  {pageOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Pagination>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className='card h-250px'>
                      <div className='m-auto d-flex flex-column align-items-center'>
                        <h1 className=''>
                          {intl.formatMessage({id: 'MSG.NO_HOLIDAYS_DATA_FOUND'})}
                        </h1>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1 className=''>{intl.formatMessage({id: 'MSG.NO_HOLIDAYS_DATA_FOUND'})}</h1>
                </div>
              </div>
            )}

            <SideDrawer value={id} />
          </>
        )
      }}
    </Context.Consumer>
  )
}

const HolidayTable = () => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {data: TableData, isLoading, fetchData} = useApiCall(`/leave/holidays`)
  useEffect(() => {
    fetchData()
  }, [])

  const sortDataByMonthAndDate = (data) => {
    return data.sort((a, b) => {
      const dateA = new Date(a.holiday_date)
      const dateB = new Date(b.holiday_date)

      if (dateA.getMonth() === dateB.getMonth()) {
        return dateA.getDate() - dateB.getDate()
      }

      return dateA.getMonth() - dateB.getMonth()
    })
  }

  const sortedTableData = sortDataByMonthAndDate(TableData || [])

  const columns = useMemo(
    () => [
      {
        Header: 'Holiday Name',
        accessor: 'Holiday Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6'>
                {row.original.holiday_name}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Start Date',
        accessor: 'Start Date',
        Cell: ({row}) => {
          return (
            <div className='text-end'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 text-start'>
                {DateFormatter(row.original.holiday_date)}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'End Date',
        accessor: 'End Date',
        Cell: ({row}) => {
          return (
            <div className='text-end'>
              <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 text-start'>
                {row.original.holiday_type}
              </p>
            </div>
          )
        },
      },
    ],
    []
  )

  return <DataTable columns={columns} data={sortedTableData} loading={isLoading} />
}

export default HolidayTable
