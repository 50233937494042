import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'

import {useLocation} from 'react-router-dom'
import usePageTitle from '../../custom_hooks/usePageTitle'

import useApiCall from '../../custom_hooks/useApiCall'
import VacancyContext from '../vacancies/VacancyContext'
import {Link} from 'react-router-dom'
import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'

import AddCandidateDrawer from './AddCandidateDrawer'

const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const {VACANCYID} = useContext(VacancyContext)
  const intl = useIntl()

  usePageTitle(intl.formatMessage({id: 'MENU.RECRUITMENT.CANDIDATES'}))
  // Filter data based on input value
  const filteredData = useMemo(() => {
    return data?.filter((each) => each.first_name?.toLowerCase().includes(inputVal.toLowerCase()))
  }, [data, inputVal])

  // Create separate datasets for active and inactive records

  // Table instances for active and inactive datasets
  const activeData = useMemo(() => {
    return filteredData?.filter((leave) => leave.offer_status === 'Pending')
  }, [filteredData])

  const inactiveData = useMemo(() => {
    return filteredData?.filter((leave) => leave.offer_status === 'Selected')
  }, [filteredData])

  // Table instances for active and inactive dataset
  const RejectedData = useMemo(() => {
    return filteredData?.filter((leave) => leave.offer_status === 'Rejected')
  }, [filteredData])

  // Table instances for active and inactive dataset
  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)
  const RejectedDataInstance = useTable({columns, data: RejectedData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  const RejectedPage = RejectedDataInstance.page
  const RejectedPageCount = RejectedDataInstance.pageCount
  const RejectedPageIndex = RejectedDataInstance.state.pageIndex
  const RejectedPageSize = RejectedDataInstance.state.pageSize

  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }

  const gotoRejectedPage = (pageIndex) => {
    RejectedDataInstance.gotoPage(pageIndex)
  }

  const setRejectedPage = (pageSize) => {
    RejectedDataInstance.setPageSize(pageSize)
  }

  const pageOptions = [5, 10, 20, 30, 40, 50]
  const {
    data: candidatesdata,
    isLoading,
    fetchData,
  } = useApiCall(`/recruitment/candidates/${VACANCYID}`)

  // Render the component
  return (
    <VacancyContext.Consumer>
      {(value) => {
        const {VACANCYID, updateVACANCYID} = value
        const {CANDIDATEID, updateCANDIDATEID} = value

        return (
          <>
            <>
              {loading ? (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : data.length > 0 ? (
                <>
                  <div className='card'>
                    <>
                      <div className='card-header border-0 pt-5'>
                        <div className='d-flex flex-wrap flex-stack'>
                          <div className='fw-bolder'>
                            <ul className='nav'>
                              <li className='nav-item'>
                                <a
                                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                                  data-bs-toggle='tab'
                                  href='#kt_table_widget_4_tab_1'
                                >
                                  {intl.formatMessage({id: 'LABEL.PENDING'})}
                                </a>
                              </li>
                              <li className='nav-item'>
                                <a
                                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                  data-bs-toggle='tab'
                                  href='#kt_table_widget_4_tab_2'
                                >
                                  {intl.formatMessage({id: 'LABEL.APPROVED'})}
                                </a>
                              </li>
                              <li className='nav-item'>
                                <a
                                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                  data-bs-toggle='tab'
                                  href='#kt_table_widget_4_tab_3'
                                >
                                  {intl.formatMessage({id: 'LABEL.REJECTED'})}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className='card-toolbar'>
                          <div className='d-flex align-items-center position-relative me-3'>
                            <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                            <input
                              type='search'
                              id='kt_filter_search'
                              className='form-control form-control-white form-control-sm w-150px ps-9'
                              placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                              value={inputVal}
                              onChange={(e) => setInputVal(e.target.value)}
                            />
                          </div>

                          <a
                            href='#'
                            className='btn btn-sm btn-light-primary mx-2'
                            id='addcandidate'
                            // onClick={() => {
                            //   updateVACANCYID({
                            //     type: 'add_candidates',
                            //     id: VACANCYID,
                            //     fetchData,
                            //   })
                            // }}
                          >
                            <KTIcon iconName='plus' className='fs-3' />
                            {intl.formatMessage({id: 'BTN.ADD_CANDIDATE'})}
                          </a>

                          <button className='btn btn-sm btn-light-primary'>
                            <Link to='/recruitment/vacancies'>
                              <KTIcon iconName='arrow-left' className='fs-3' />
                            </Link>
                          </button>
                        </div>
                      </div>
                      <div className='tab-content p-2 pt-0'>
                        <div
                          className='card-body tab-pane fade show active table-responsive'
                          id='kt_table_widget_4_tab_1'
                        >
                          <table
                            className='table table-row-dashed align-middle table-row-gray-300'
                            {...activeTableInstance.getTableProps()}
                          >
                            <thead>
                              <tr className='fw-bold text-muted'>
                                <th className='min-w-150px ms-9 text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.EMAIL'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.STATE'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.CITY'})}
                                </th>

                                <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.METHOD_OF_APPLICATION'})}
                                </th>

                                {/* <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.SOURCE_OF_HIRE'})}
                                </th> */}

                                {/* <th className='min-w-100px text-center default-cursor '>
                                  {intl.formatMessage({id: 'LABEL.STATUS'})}
                                </th> */}

                                <th className='min-w-100px text-center default-cursor '>
                                  {intl.formatMessage({id: 'MENU.ACTIONS'})}
                                </th>
                              </tr>
                            </thead>
                            <tbody {...activeTableInstance.getTableBodyProps()}>
                              {activePage.length > 0 ? (
                                activePage.map((row) => {
                                  activeTableInstance.prepareRow(row)
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => (
                                        <td className=' ' {...cell.getCellProps()}>
                                          {cell.render('Cell')}
                                        </td>
                                      ))}
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td>
                                    <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                      {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                    </p>
                                  </td>
                                  <td>
                                    <p></p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          {data.length > 10 && (
                            <Pagination>
                              <Pagination.First
                                onClick={() => gotoActivePage(0)}
                                disabled={!activeTableInstance.canPreviousPage}
                              />
                              <Pagination.Prev
                                onClick={activeTableInstance.previousPage}
                                disabled={!activeTableInstance.canPreviousPage}
                              />
                              {[...Array(activePageCount)].map((_, i) => (
                                <Pagination.Item
                                  key={i}
                                  active={i === activePageIndex}
                                  onClick={() => gotoActivePage(i)}
                                >
                                  {i + 1}
                                </Pagination.Item>
                              ))}
                              <Pagination.Next
                                onClick={activeTableInstance.nextPage}
                                disabled={!activeTableInstance.canNextPage}
                              />
                              <Pagination.Last
                                onClick={() => gotoActivePage(activePageCount - 1)}
                                disabled={!activeTableInstance.canNextPage}
                              />
                              <div className='d-flex align-items-center mx-5'>
                                <span className='me-2'>
                                  {intl.formatMessage({id: 'LABEL.SHOW'})}
                                </span>
                                <select
                                  className='form-select form-select-solid fw-bold w-75px me-2'
                                  value={activePageSize}
                                  onChange={(e) => setActivePageSize(Number(e.target.value))}
                                >
                                  {pageOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Pagination>
                          )}
                        </div>

                        <div
                          className='card-body tab-pane fade  table-responsive'
                          id='kt_table_widget_4_tab_2'
                        >
                          <table
                            className='table table-row-dashed align-middle table-row-gray-300'
                            {...inactiveTableInstance.getTableProps()}
                          >
                            <thead>
                              <tr className='fw-bold text-muted'>
                                <th className='min-w-150px ms-9 text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.EMAIL'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.STATE'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.CITY'})}
                                </th>

                                <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.METHOD_OF_APPLICATION'})}
                                </th>

                                {/* <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.SOURCE_OF_HIRE'})}
                                </th> */}
                                {/* 
                                <th className='min-w-100px text-center default-cursor '>
                                  {intl.formatMessage({id: 'LABEL.STATUS'})}
                                </th> */}

                                <th className='min-w-100px text-center default-cursor '>
                                  {intl.formatMessage({id: 'MENU.ACTIONS'})}
                                </th>
                              </tr>
                            </thead>
                            <tbody {...inactiveTableInstance.getTableBodyProps()}>
                              {inactivePage.length > 0 ? (
                                inactivePage.map((row) => {
                                  inactiveTableInstance.prepareRow(row)
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => (
                                        <td className=' ' {...cell.getCellProps()}>
                                          {cell.render('Cell')}
                                        </td>
                                      ))}
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td>
                                    <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                      {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                    </p>
                                  </td>
                                  <td>
                                    <p></p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          {data.length > 10 && (
                            <Pagination>
                              <Pagination.First
                                onClick={() => gotoInactivePage(0)}
                                disabled={!inactiveTableInstance.canPreviousPage}
                              />
                              <Pagination.Prev
                                onClick={inactiveTableInstance.previousPage}
                                disabled={!inactiveTableInstance.canPreviousPage}
                              />
                              {[...Array(inactivePageCount)].map((_, i) => (
                                <Pagination.Item
                                  key={i}
                                  active={i === inactivePageIndex}
                                  onClick={() => gotoInactivePage(i)}
                                >
                                  {i + 1}
                                </Pagination.Item>
                              ))}
                              <Pagination.Next
                                onClick={inactiveTableInstance.nextPage}
                                disabled={!inactiveTableInstance.canNextPage}
                              />
                              <Pagination.Last
                                onClick={() => gotoInactivePage(inactivePageCount - 1)}
                                disabled={!inactiveTableInstance.canNextPage}
                              />
                              <div className='d-flex align-items-center mx-5'>
                                <span className='me-2'>
                                  {intl.formatMessage({id: 'LABEL.SHOW'})}
                                </span>
                                <select
                                  className='form-select form-select-solid fw-bold w-75px me-2'
                                  value={inactivePageSize}
                                  onChange={(e) => setInactivePageSize(Number(e.target.value))}
                                >
                                  {pageOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Pagination>
                          )}
                        </div>

                        <div
                          className='card-body tab-pane fade  table-responsive'
                          id='kt_table_widget_4_tab_3'
                        >
                          <table
                            className='table table-row-dashed align-middle table-row-gray-300'
                            {...inactiveTableInstance.getTableProps()}
                          >
                            <thead>
                              <tr className='fw-bold text-muted'>
                                <th className='min-w-150px ms-9 text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.EMAIL'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.STATE'})}
                                </th>

                                <th className='min-w-120px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.CITY'})}
                                </th>

                                <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.METHOD_OF_APPLICATION'})}
                                </th>

                                {/* <th className='min-w-100px text-start default-cursor'>
                                  {intl.formatMessage({id: 'LABEL.SOURCE_OF_HIRE'})}
                                </th> */}

                                {/* <th className='min-w-100px text-center default-cursor '>
                                  {intl.formatMessage({id: 'LABEL.STATUS'})}
                                </th> */}

                                <th className='min-w-100px text-center default-cursor '>
                                  {intl.formatMessage({id: 'MENU.ACTIONS'})}
                                </th>
                              </tr>
                            </thead>
                            <tbody {...RejectedDataInstance.getTableBodyProps()}>
                              {RejectedPage.length > 0 ? (
                                RejectedPage.map((row) => {
                                  RejectedDataInstance.prepareRow(row)
                                  return (
                                    <tr {...row.getRowProps()}>
                                      {row.cells.map((cell) => (
                                        <td className=' ' {...cell.getCellProps()}>
                                          {cell.render('Cell')}
                                        </td>
                                      ))}
                                    </tr>
                                  )
                                })
                              ) : (
                                <tr>
                                  <td>
                                    <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                      {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                    </p>
                                  </td>
                                  <td>
                                    <p></p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          {data.length > 10 && (
                            <Pagination>
                              <Pagination.First
                                onClick={() => gotoRejectedPage(0)}
                                disabled={!RejectedDataInstance.canPreviousPage}
                              />
                              <Pagination.Prev
                                onClick={RejectedDataInstance.previousPage}
                                disabled={!RejectedDataInstance.canPreviousPage}
                              />
                              {[...Array(RejectedPageCount)].map((_, i) => (
                                <Pagination.Item
                                  key={i}
                                  active={i === RejectedPageIndex}
                                  onClick={() => gotoRejectedPage(i)}
                                >
                                  {i + 1}
                                </Pagination.Item>
                              ))}
                              <Pagination.Next
                                onClick={RejectedDataInstance.nextPage}
                                disabled={!RejectedDataInstance.canNextPage}
                              />
                              <Pagination.Last
                                onClick={() => gotoRejectedPage(RejectedPageCount - 1)}
                                disabled={!RejectedDataInstance.canNextPage}
                              />
                              <div className='d-flex align-items-center mx-5'>
                                <span className='me-2'>
                                  {intl.formatMessage({id: 'LABEL.SHOW'})}
                                </span>
                                <select
                                  className='form-select form-select-solid fw-bold w-75px me-2'
                                  value={RejectedPageSize}
                                  onChange={(e) => setRejectedPage(Number(e.target.value))}
                                >
                                  {pageOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Pagination>
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                </>
              ) : (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <h1 className='mb-5'>
                      {intl.formatMessage({id: 'LABEL.NO_CANDIDATES_FOUND'})}
                    </h1>
                  </div>
                </div>
              )}
              {/* <ScheduleInterview props={CANDIDATEID} /> */}
              <AddCandidateDrawer props={VACANCYID} fetchData={fetchData} />
              <SideDrawer value={CANDIDATEID} />
            </>
          </>
        )
      }}
    </VacancyContext.Consumer>
  )
}

const CandidatesTable = () => {
  const {VACANCYID} = useContext(VacancyContext)
  const intl = useIntl()
  const [showModal, setShow] = useState(false)
  const [imgUrl, setImgUrl] = useState(null)
  const [fileID, updatefileID] = useState('1')
  const handleClose = () => setShow(false)
  const handleShow = (fileID) => {
    updatefileID(fileID)
    setShow(true)
  }

  const docSampleImgs = {
    1: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/World_Passport_front_page.jpg/1200px-World_Passport_front_page.jpg',
    2: 'https://i.pinimg.com/474x/e5/c6/82/e5c682de11481536fd85b985a421122a.jpg',
  }
  const navigate = useNavigate()

  const getuserID = localStorage.getItem('CANDID')
  let candidateID
  if (VACANCYID != 0) {
    candidateID = VACANCYID
  } else if (getuserID) {
    candidateID = getuserID
  }

  const location = useLocation()
  const {
    data: filteredData,
    isLoading,
    fetchData,
  } = useApiCall(candidateID !== null ? `/recruitment/candidates/${candidateID}` : null)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    return () => {
      if (VACANCYID !== '0') {
        localStorage.setItem('CANDID', VACANCYID)
      }
    }
  }, [VACANCYID])
  // useEffect(() => {
  //   const targetElement = document.getElementById('side_activities')
  //   if (targetElement && location.pathname === '/recruitment/candidates') {
  //     const body = document.querySelector('body')
  //     const observer = new MutationObserver(() => {
  //       if (!body.hasAttribute('data-kt-drawer-side-activities')) {
  //         fetchData()
  //       }
  //     })
  //     observer.observe(targetElement, {attributes: true})
  //     return () => {
  //       observer.disconnect()
  //     }
  //   }
  // }, [location.pathname])

  const columns = useMemo(
    () => [
      {
        Header: 'Candidate',
        accessor: 'Candidate',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='text-start'>
                <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                  {row.original.first_name} {row.original.last_name}
                </p>
              </div>
            </div>
          )
        },
      },
      {
        Header: 'E-MAIL',
        accessor: 'E-MAIL',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.email}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Country',
        accessor: 'Country',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.country_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'State',
        accessor: 'State',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.state_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'City',
        accessor: 'City',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.city_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Method of Application',
        accessor: 'Method of Application',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.method_of_application}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <VacancyContext.Consumer>
              {(value) => {
                const {updateCANDIDATEID} = value

                return (
                  <>
                    <div className='d-flex flex-row justify-content-center align-items-center'>
                      <div className='d-flex justify-content-start flex-shrink-0'>
                        <button
                          className='btn btn-light-primary btn-sm me-1'
                          onClick={() => {
                            if (row.original.resume_file_url) {
                              window.open(row.original.resume_file_url, '_blank')
                            } else {
                              alert('Not Found')
                            }
                          }}
                        >
                          <KTIcon iconName='eye' className='fs-3 ' />
                        </button>
                      </div>
                      {/* {row.original.offer_status === 'Pending' && ( */}
                      <>
                        <div className='d-flex justify-content-start flex-shrink-0'>
                          <button
                            className='btn  btn-light-primary  btn-sm me-1'
                            id='side_activities_toggle'
                            onClick={() => {
                              updateCANDIDATEID({
                                id: row.original.id,
                                department_id: row.original.department_id,
                                type: 'schedule_interview',
                              })
                            }}
                          >
                            <KTIcon iconName='plus' className='fs-3' />
                            {intl.formatMessage({id: 'LABEL.SCHEDULE_INTERVIEW'})}
                          </button>
                        </div>
                        <div className='d-flex justify-content-start flex-shrink-0'>
                          <button
                            className='btn  btn-light-primary  btn-sm me-1'
                            id='side_activities_toggle'
                            onClick={() => {
                              updateCANDIDATEID({
                                id: row.original.id,
                                type: 'edit_candidates',
                                fetchData,
                              })
                            }}
                          >
                            <KTIcon iconName='plus' className='fs-3' />
                            {intl.formatMessage({id: 'LABEL.OFFER'})}
                          </button>
                        </div>
                      </>
                      {/* )} */}
                      <div className=''>
                        <Link
                          to='/recruitment/interviews'
                          className='btn  btn-light-primary  btn-sm me-1'
                          onClick={() => {
                            updateCANDIDATEID(row.original.id)
                          }}
                        >
                          <KTIcon iconName='arrow-right' className='fs-2' />
                        </Link>
                      </div>
                    </div>
                  </>
                )
              }}
            </VacancyContext.Consumer>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <DataTable columns={columns} data={filteredData ? filteredData : []} loading={isLoading} />
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <h3>{intl.formatMessage({id: 'BUTTON.PREVIEW'})}</h3>
          <div>
            <button className='btn btn-light-primary btn-sm me-1'>
              {intl.formatMessage({id: 'BUTTON.DOWNLOAD'})}
            </button>
            <button className='btn btn-light btn-sm ms-1' onClick={handleClose}>
              <KTIcon iconName='cross' className='fs-3' />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <img className='w-100' alt='img' src={docSampleImgs[fileID]} />
          <p>hi </p>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-light-primary btn-sm'>
            {intl.formatMessage({id: 'BUTTON.DOWNLOAD'})}
          </button>
          <button className='btn btn-light btn-sm' onClick={handleClose}>
            {intl.formatMessage({id: 'BTN.CLOSE'})}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CandidatesTable
