import {KTIcon} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import Swal from 'sweetalert2'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import useApiCall from '../../custom_hooks/useApiCall'
const AddJobRole = (props: any) => {
  const {refreshData} = props.id
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const {data: depName}: any | null = useApiCall(`/masterData/departments`)
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {organization_id} = userInfo || {}
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({id: 'LABEL.NAME_REQUIRED'})),
    code: Yup.string().required(intl.formatMessage({id: 'LABEL.CODE_REQUIRED'})),
    description: Yup.string().required(intl.formatMessage({id: 'LABEL.DESCRIPTION_REQUIRED'})),
    depName: Yup.string().required(intl.formatMessage({id: 'LABEL.DEP_NAME_REQUIRED'})),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `${baseUrl}/hrConfig/jobRole`
    try {
      const requestData = {
        organization_id: organization_id,
        role_name: values.name,
        role_description: values.description,
        job_role_code: values.code,
        department_id: parseInt(values.depName),
        status: values.status ? 1 : 0,
      }
      await execute(url, 'POST', requestData, id)
      resetForm()
      refreshData()
      const closeBtn = document.querySelector(
        '#kt_modal_add_jobRole [data-bs-dismiss="modal"]'
      ) as HTMLElement
      if (closeBtn) {
        closeBtn.click()
      }
      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.JOB_ROLE_TYPE_ADDED'}),
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <Formik
        initialValues={{
          name: '',
          description: '',
          code: '',
          depName: '',
          status: true,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({values, handleChange, resetForm}) => {
          return (
            <Form>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h3 className='modal-title fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL.ADD_JOB_ROLE'})}
                    </h3>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-danger'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTIcon iconName='cross' className='fs-2' />
                    </div>
                  </div>
                  <div className='modal-body position-relative'>
                    <div>
                      <div className='form-floating flex-fill mb-4'>
                        <Field
                          type='text'
                          className='form-control'
                          placeholder={intl.formatMessage({id: 'LABEL.NAME'})}
                          name='name'
                        />
                        <label htmlFor='name'>{intl.formatMessage({id: 'LABEL.NAME'})}</label>
                        <ErrorMessage component='div' className='text-danger' name='name' />
                      </div>
                      <div className='d-flex mb-4'>
                        <div className='form-floating flex-fill'>
                          <Field
                            type='text'
                            className='form-control'
                            placeholder={intl.formatMessage({id: 'LABEL.CODE'})}
                            name='code'
                            id='code'
                          />
                          <label htmlFor='code'>{intl.formatMessage({id: 'LABEL.CODE'})}</label>
                          <ErrorMessage component='div' className='text-danger' name='code' />
                        </div>
                        <div className='form-floating flex-fill ms-2'>
                          <Field
                            as='select'
                            name='depName'
                            className='form-select'
                            value={values.depName}
                            onChange={handleChange}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_DEPARTMENT_NAME'})}
                            </option>
                            {depName?.map((option: any) => (
                              <option key={option.department_id} value={option.department_id}>
                                {option.department_name}
                              </option>
                            ))}
                          </Field>
                          <label htmlFor='depName'>
                            {intl.formatMessage({id: 'LABEL.DEPARTMENT_NAME'})}
                          </label>
                          <ErrorMessage component='div' className='text-danger' name='depName' />
                        </div>
                      </div>
                      <div className='form-floating flex-fill mb-4'>
                        <Field
                          type='text'
                          className='form-control'
                          name='description'
                          placeholder={intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                        />
                        <label htmlFor='description'>
                          {intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                        </label>
                        <ErrorMessage component='div' className='text-danger' name='description' />
                      </div>
                      <div className='form-floating flex-fill'>
                        <div className='form-check form-check-solid form-switch mb-0'>
                          <Field
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            name='status'
                            id='toggle'
                          />
                          <span
                            className={`me-4 fw-bold ${
                              values.status ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {values.status ? 'Active' : 'Inactive'}
                          </span>
                          <ErrorMessage component='div' className='text-danger' name='status' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer text-end'>
                    <div
                      className='btn btn-sm btn-secondary m-2'
                      onClick={() => handleCancel(resetForm)}
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </div>
                    <button className='btn btn-light-primary btn-sm' type='submit'>
                      <KTIcon iconName='check' className='fs-7' />
                      {intl.formatMessage({id: 'BTN.SAVE'})}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {AddJobRole}
