import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import {useLocation} from 'react-router-dom'
import usePageTitle from '../../custom_hooks/usePageTitle'
import useApiCall from '../../custom_hooks/useApiCall'
import VacancyContext from '../vacancies/VacancyContext'
import {useContext} from 'react'
import DateFormatter from '../../DateFormatter'
const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  let emp_id
  let user_id
  let role
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id, role} = userInfo)
  }
  usePageTitle(intl.formatMessage({id: 'MENU.RECRUITMENT.INTERVIEWS'}))
  // Filter data based on input value
  const filteredData = useMemo(() => {
    return data.filter(
      (each) =>
        each.interview_type.toLowerCase().includes(inputVal.toLowerCase()) ||
        each.interviewer_name.toLowerCase().includes(inputVal.toLowerCase())
    )
  }, [data, inputVal])

  // Create separate datasets for active and inactive records

  // Table instances for active and inactive datasets
  const TableInstance = useTable({columns, data: filteredData}, usePagination)

  // Extract required values from table instances
  const activePage = TableInstance.page
  const activePageCount = TableInstance.pageCount
  const activePageIndex = TableInstance.state.pageIndex
  const activePageSize = TableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    TableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    TableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset

  const pageOptions = [5, 10, 20, 30, 40, 50]
  const offerStatus = filteredData.map((item) => item.offer_status)

  // Render the component
  return (
    <VacancyContext.Consumer>
      {(value) => {
        const {INTERVIEWID, updateINTERVIEWID} = value
        const {CANDIDATEID, updateCANDIDATEID} = value

        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='d-flex flex-wrap flex-stack mb-6'>
                  <div className='fw-bolder my-2'>
                    <h3 className='fw-bolder my-2'>
                      {intl.formatMessage({id: 'MENU.RECRUITMENT.INTERVIEWS'})}
                    </h3>
                  </div>
                  <button className='btn btn-sm btn-light-primary'>
                    <Link to='/recruitment/candidates'>
                      <KTIcon iconName='arrow-left' className='fs-3' />
                    </Link>
                  </button>
                  {/* <div className='d-flex'>
                    {role === 'Admin' && offerStatus === 'Pending' && (
                      <button
                        className='btn  btn-light-primary  btn-sm me-1'
                        id='scheduleinterview'
                        // onClick={() => {
                        //   updateCANDIDATEID(id)
                        // }}
                      >
                        <KTIcon iconName='plus' className='fs-3' />
                        {intl.formatMessage({id: 'BTN.SCHEDULE_INTERVIEW'})}
                      </button>
                    )}
                  </div> */}
                </div>
                <div className='card mb-5 mb-xl-8 p-10'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle'>
                    <thead>
                      <tr className='fw-bold text-muted'>
                        <th className='min-w-100px text-start default-cursor'>
                          {intl.formatMessage({id: 'LABEL.INTERVIEW_ROUND'})}
                        </th>

                        <th className='min-w-100px text-start default-cursor'>
                          {intl.formatMessage({id: 'LABEL.INTERVIEWER_NAME'})}
                        </th>

                        <th className='min-w-140px text-start default-cursor'>
                          {intl.formatMessage({id: 'LABEL.INTERVIEW_DATE'})}
                        </th>

                        <th className='min-w-140px text-start default-cursor'>
                          {intl.formatMessage({id: 'LABEL.INTERVIEW_TIME'})}
                        </th>
                        <th className='min-w-120px text-start default-cursor'>
                          {intl.formatMessage({id: 'LABEL.INTERVIEW_MODE'})}
                        </th>
                        <th className='min-w-100px text-start default-cursor'>
                          {intl.formatMessage({id: 'LABEL.INTERVIEW_LOCATION'})}
                        </th>

                        <th className='min-w-100px text-start default-cursor'>
                          {intl.formatMessage({id: 'LABEL.STATUS'})}
                        </th>

                        <th className='min-w-100px text-start'>
                          {intl.formatMessage({id: 'MENU.ACTIONS'})}
                        </th>
                      </tr>
                    </thead>

                    <tbody {...TableInstance.getTableBodyProps()}>
                      {activePage.length > 0 ? (
                        activePage.map((row) => {
                          TableInstance.prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td className=' ' {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              ))}
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td>
                            <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                              {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                            </p>
                          </td>
                          <td>
                            <p></p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {data.length > 5 && (
                  <Pagination>
                    <Pagination.First
                      onClick={() => gotoActivePage(0)}
                      disabled={!TableInstance.canPreviousPage}
                    />
                    <Pagination.Prev
                      onClick={TableInstance.previousPage}
                      disabled={!TableInstance.canPreviousPage}
                    />
                    {[...Array(activePageCount)].map((_, i) => (
                      <Pagination.Item
                        key={i}
                        active={i === activePageIndex}
                        onClick={() => gotoActivePage(i)}
                      >
                        {i + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={TableInstance.nextPage}
                      disabled={!TableInstance.canNextPage}
                    />
                    <Pagination.Last
                      onClick={() => gotoActivePage(activePageCount - 1)}
                      disabled={!TableInstance.canNextPage}
                    />
                    <div className='d-flex align-items-center mx-5'>
                      <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                      <select
                        className='form-select form-select-solid fw-bold w-75px me-2'
                        value={activePageSize}
                        onChange={(e) => setActivePageSize(Number(e.target.value))}
                      >
                        {pageOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Pagination>
                )}
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1 className='mb-5'>{intl.formatMessage({id: 'LABEL.NO_INTERVIEWS_FOUND'})}</h1>

                  {/* <button className='btn btn-sm btn-light-primary' id='scheduleinterview'>
                    <KTIcon iconName='plus' className='fs-3' />
                    {intl.formatMessage({id: 'BTN.SCHEDULE_INTERVIEW'})}
                  </button> */}
                </div>
              </div>
            )}
            <SideDrawer value={INTERVIEWID} candidateId={CANDIDATEID} />
            {/* <ScheduleInterview props={CANDIDATEID} /> */}
          </>
        )
      }}
    </VacancyContext.Consumer>
  )
}

const InterviewTable = () => {
  const {CANDIDATEID} = useContext(VacancyContext)

  const getuserID = localStorage.getItem('INTERVIEW')
  let interviewID
  if (CANDIDATEID != 0) {
    interviewID = CANDIDATEID
  } else if (getuserID) {
    interviewID = getuserID
  }

  const location = useLocation()
  const {
    data: TableData,
    isLoading,
    fetchData,
  } = useApiCall(`/recruitment/interview/${interviewID}`)

  useEffect(() => {
    fetchData()
  }, [interviewID, CANDIDATEID])

  useEffect(() => {
    return () => {
      if (CANDIDATEID !== '0') {
        secureLocalStorage.setItem('INTERVIEW', CANDIDATEID)
        localStorage.setItem('INTERVIEW', CANDIDATEID)
      }
    }
  })

  useEffect(() => {
    const targetElement = document.getElementById('side_activities')
    if (targetElement && location.pathname === '/recruitment/interviews') {
      const body = document.querySelector('body')
      const observer = new MutationObserver(() => {
        if (!body.hasAttribute('data-kt-drawer-side-activities')) {
          fetchData()
        }
      })
      observer.observe(targetElement, {attributes: true})
      return () => {
        observer.disconnect()
      }
    }
  }, [location.pathname])

  const columns = useMemo(
    () => [
      {
        Header: 'Interview Type',
        accessor: 'Interview Type',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.interview_type}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Interviewer Name',
        accessor: 'Interviewer Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.interviewer_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Interview Date',
        accessor: 'Interview Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.interview_timestamp.slice(0, 10))}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Interview Time',
        accessor: 'Interview Time',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.interview_timestamp.slice(10, 16))}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Interviewer Mode',
        accessor: 'Interviewer Mode',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.interview_mode}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Interviewer Location',
        accessor: 'Interviewer Location',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.interview_location}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Interviewer Status',
        accessor: 'Interviewer Status',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.status}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <VacancyContext.Consumer>
              {(value) => {
                const {updateINTERVIEWID} = value

                return (
                  <>
                    <button
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      id='side_activities_toggle'
                      onClick={() => {
                        updateINTERVIEWID({
                          id: row.original.interview_id,
                          type: 'edit_interviews',
                        })
                      }}
                    >
                      <KTIcon iconName='eye' className='fs-3' />
                    </button>
                  </>
                )
              }}
            </VacancyContext.Consumer>
          )
        },
      },
    ],
    []
  )

  return <DataTable columns={columns} data={TableData ? TableData : []} loading={isLoading} />
}

export default InterviewTable
