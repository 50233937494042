import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import {useParams} from 'react-router-dom'
import usePostApi from '../../../custom_hooks/usePostApi'
import {baseUrl} from '../../../core'
import {KTIcon} from '../../../../../_metronic/helpers'

const AddState = (props: any) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {organization_id} = userInfo || {}
  const validationSchema = Yup.object().shape({
    stateName: Yup.string().required(intl.formatMessage({id: 'LABEL.NAME_REQUIRED'})),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `${baseUrl}/state`
    try {
      const requestData = {
        organization_id: organization_id,
        state_name: values.stateName,
        country_id: props?.id.id,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'POST', requestData, id)
      props.id.functionCall.refreshData()
      resetForm()
      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.STATE_ADDED'}),
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <Formik
        initialValues={{
          stateName: '',
          status: true,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({values, resetForm}) => {
          return (
            <Form autoComplete='off'>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title'>
                      {intl.formatMessage({id: 'LABEL.ADD_STATE_NAME'})}
                    </h5>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-danger'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTIcon iconName='cross' className='fs-2' />
                    </div>
                  </div>
                  <div className='modal-body position-relative'>
                    <div>
                      <div className='form-floating mb-4'>
                        <Field
                          type='text'
                          className='form-control'
                          id='floatingInput'
                          placeholder='type...'
                          name='stateName'
                        />
                        <label htmlFor='floatingInput'>
                          {intl.formatMessage({id: 'LABEL.NAME'})}
                        </label>
                        <ErrorMessage component='div' className='text-danger' name='stateName' />
                      </div>

                      <div className='form-floating flex-fill'>
                        <div className='form-check form-check-solid form-switch mb-0'>
                          <Field
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            name='status'
                            id='toggle'
                          />
                          <span
                            className={`me-4 fw-bold ${
                              values.status ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {values.status ? 'Active' : 'Inactive'}
                          </span>
                          <ErrorMessage component='div' className='text-danger' name='status' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer text-end'>
                    <div
                      className='btn btn-sm btn-secondary m-2'
                      onClick={() => handleCancel(resetForm)}
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </div>
                    <button
                      className='btn btn-light-primary btn-sm'
                      type='submit'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTIcon iconName='check' className='fs-7' />
                      {intl.formatMessage({id: 'BTN.SAVE'})}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {AddState}
