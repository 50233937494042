import {useState} from 'react'

import {useLocation} from 'react-router-dom'

import {PageTitle} from '../../../_metronic/layout/core'

import PerformanceNavBar from './PerformanceNavbar'

import Context from '../../../_metronic/partials/layout/activity-drawer/context'

import TeamReviewsTable from './team_reviews/TeamReviewsTable'

import {useIntl} from 'react-intl'

import AssignkpiTable from './assigning_kpi/AssignkpiTable'
import MyKPITable from './role_kpi/MyKPITable'
import MyInputsTable from './my_inputs/MyInputsTable'

const PerformanceRoutes = () => {
  const intl = useIntl()
  const PerformanceBreadCrumbs = [
    {
      title: intl.formatMessage({id: 'MENU.PERFORMANCE_MANAGEMENT'}),
      path: '/performance-management/assigningkpi',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const {pathname} = useLocation()
  const [SideDrawerId, updateId] = useState('0')

  const switchMethod = () => {
    switch (pathname) {
      case '/performance-management/objectives':
        return (
          <>
            <PageTitle breadcrumbs={PerformanceBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.PERFORMANCE_MANAGEMENT'})}
            </PageTitle>
            <AssignkpiTable />
          </>
        )

      case '/performance-management/assigningkpi':
        return (
          <>
            <PageTitle breadcrumbs={PerformanceBreadCrumbs}>My KPI</PageTitle>
            <MyKPITable />
          </>
        )
      case '/performance-management/my-inputs':
        return (
          <>
            <PageTitle breadcrumbs={PerformanceBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.PERFORMANCE_MANAGEMENT.MY_INPUTS'})}
            </PageTitle>
            <MyInputsTable />
          </>
        )

      case '/performance-management/team-reviews':
        return (
          <>
            <PageTitle breadcrumbs={PerformanceBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.PERFORMANCE_MANAGEMENT.TEAM_REVIEW'})}
            </PageTitle>
            <TeamReviewsTable />
          </>
        )

      default:
        return (
          <>
            <PageTitle breadcrumbs={PerformanceBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.PERFORMANCE_MANAGEMENT.MY_INPUTS'})}
            </PageTitle>
            <MyKPITable />
          </>
        )
    }
  }
  const UpdatingId = (id) => {
    updateId(id)
  }

  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        <PerformanceNavBar />
        {switchMethod()}
      </Context.Provider>
    </>
  )
}

export default PerformanceRoutes
