import DatePicker from 'react-datepicker'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import {useParams} from 'react-router-dom'
import usePostApi from '../../custom_hooks/usePostApi'
import {useThemeMode} from '../../../../_metronic/partials'
import useApiCall from '../../custom_hooks/useApiCall'
import {baseUrl} from '../../core'
import {DateBasicFormat} from '../../DateHelpers'
import {KTIcon} from '../../../../_metronic/helpers'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'

const AddProject = (props: any) => {
  const intl = useIntl()
  const lang = useLang()
  const {execute} = usePostApi()
  const {mode} = useThemeMode()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {organization_id, emp_id} = userInfo || {}
  const brandLogos: any | null = secureLocalStorage.getItem('brand_logos')
  const {data: headName}: any | null = useApiCall('/masterData/employees')
  const {brand_logo_url} = brandLogos || {}
  const brandName = localStorage.getItem('displayName') || 'Kloudworx'
  const {data, isLoading}: any | null = useApiCall(`/organization/1`)
  const locationName = data && data[0]
  const validationSchema = Yup.object().shape({
    project_name: Yup.string().required(intl.formatMessage({id: 'LABEL.PROJECT_NAME_REQUIRED'})),
    project_code: Yup.string().required(intl.formatMessage({id: 'PROJECT_CODE_REQUIRED'})),
    project_manager: Yup.string().required(intl.formatMessage({id: 'PROJECT_MANAGER_REQUIRED'})),
    start_date: Yup.string().required(intl.formatMessage({id: 'ERR.MSG.START_DATE_IS_REQUIRED'})),
    end_date: Yup.string().required(intl.formatMessage({id: 'ERR.MSG.END_DATE_IS_REQUIRED'})),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `${baseUrl}/hrConfig/projects`
    try {
      const requestData = {
        organization_id: organization_id,
        employee_id: emp_id,
        exit_date: DateBasicFormat(values.exit_date),
        project_code: values.project_code,
        project_name: values.project_name,
        project_start_date: DateBasicFormat(values.start_date),
        project_end_date: DateBasicFormat(values.end_date),
        project_manager_id: values.project_manager,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'POST', requestData, id)
      resetForm()
      props.props.fetchData()
      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.PROJECT_ADDED'}),
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('side_activities_close')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <div className='card shadow-none rounded-0'>
        <Formik
          initialValues={{
            project_name: '',
            project_code: '',
            project_manager: '',
            start_date: '',
            end_date: '',
            status: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, handleChange, resetForm}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL.ADD_PROJECT'})}
                  </h3>
                  <div className='card-toolbar'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                      id='side_activities_close'
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative me-n5 pe-5 w-400px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      {isLoading ? (
                        <div className='card card-xl-stretch'>
                          <div className='m-auto d-flex flex-column align-items-center'>
                            <div className='spinner-border spinner-primary mr-15'></div>
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex align-items-start'>
                          <img
                            src={brand_logo_url}
                            alt='img'
                            className='activity-img rounded w-65px h-65px me-3'
                          />
                          <div className='mt-0 pt-0'>
                            <p className='text-dark fw-bold text-hover-primary m-0'>{brandName}</p>
                            <p className='text-dark fw-bold text-hover-primary text-muted m-0 mt-1'>
                              <KTIcon iconName='geolocation' className='fs-7 me-1' />
                              {locationName?.country_name}
                            </p>
                            <p className='text-dark fw-bold text-hover-primary text-muted m-0'>
                              <KTIcon iconName='bank' className='fs-7 me-1' />
                              {locationName?.address_line1}
                            </p>
                          </div>
                        </div>
                      )}
                      <hr className='text-dark mt-4 mb-4' />
                      <div className='timeline'>
                        <div className='timeline-item'>
                          <div className='timeline-line w-40px'></div>
                          <div className='timeline-icon symbol symbol-circle symbol-40px'>
                            <div className='symbol-label bg-light'>
                              <KTIcon
                                iconName='message-programming'
                                className='fs-2 text-gray-500'
                              />
                            </div>
                          </div>
                          <div className='timeline-content mb-4 mt-n1'>
                            <div className='overflow-auto pe-3'>
                              <div className='fw-bold mb-2 required'>
                                {intl.formatMessage({id: 'LABEL.PROJECT_NAME'})}
                              </div>
                              <div className='flex-fill'>
                                <Field
                                  type='text'
                                  className='form-control form-control-solid'
                                  id='floatingInput'
                                  name='project_name'
                                />
                                <ErrorMessage
                                  component='div'
                                  className='text-danger'
                                  name='project_name'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='timeline-item'>
                          <div className='timeline-line w-40px'></div>
                          <div className='timeline-icon symbol symbol-circle symbol-40px'>
                            <div className='symbol-label bg-light'>
                              <KTIcon iconName='code' className='fs-2 text-gray-500' />
                            </div>
                          </div>
                          <div className='timeline-content mb-8 mt-n2'>
                            <div className='overflow-auto pe-3'>
                              <div className='fw-bold mb-2 required'>
                                {intl.formatMessage({id: 'PROJECT_CODE'})}
                              </div>
                              <div className='flex-fill'>
                                <Field
                                  type='text'
                                  className='form-control form-control-solid'
                                  id='floatingInput'
                                  name='project_code'
                                />
                                <ErrorMessage
                                  component='div'
                                  className='text-danger'
                                  name='project_code'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className='text-dark mt-4 mb-4' />
                      <div className='mb-4'>
                        <div className=' fw-bold mb-2 required'>
                          {intl.formatMessage({id: 'PROJECT_MANAGER'})}
                        </div>
                        <div className='flex-fill'>
                          <Field
                            as='select'
                            id='headName'
                            name='project_manager'
                            className='form-select form-select-solid'
                            onChange={handleChange}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_MANAGER'})}
                            </option>
                            {headName?.map((option: any) => (
                              <option key={option.employee_id} value={option.employee_id}>
                                {option.employee_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='project_manager'
                          />
                        </div>
                      </div>
                      <div className='d-flex mb-4'>
                        <div className='flex-fill'>
                          <div className='fw-bold mb-2 required'>
                            {intl.formatMessage({id: 'LABEL.START_DATE'})}
                          </div>
                          <Field name='start_date'>
                            {({form, field}: any) => {
                              const {setFieldValue} = form
                              const {value} = field
                              const handleChange = (date: any) => {
                                setFieldValue('start_date', date)
                              }
                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable={value !== null && value !== ''}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({
                                      id: 'LABEL.SELECT_START_DATE',
                                    })}
                                    showMonthDropdown
                                    locale={lang || 'en'}
                                    useShortMonthInDropdown
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage component='div' className='text-danger' name='start_date' />
                        </div>
                        <div className='ms-2'>
                          <div className='fw-bold mb-2 required'>
                            {intl.formatMessage({id: 'LABEL.END_DATE'})}
                          </div>
                          <div className='flex-fill'>
                            <Field name='end_date'>
                              {({form, field}: any) => {
                                const {setFieldValue} = form
                                const {value} = field
                                const handleChange = (date: any) => {
                                  setFieldValue('end_date', date)
                                }
                                const startDate = form.values.start_date
                                return (
                                  <div
                                    className={`react-datepicker-fullwidth-wrapper test ${
                                      mode === 'dark' ? 'dark-mode' : 'light-mode'
                                    }`}
                                  >
                                    <DatePicker
                                      className='form-control form-control-solid'
                                      selected={value}
                                      isClearable={value !== null && value !== ''}
                                      showYearDropdown
                                      scrollableYearDropdown
                                      dateFormat='dd/MM/yyyy'
                                      onChange={(date) => {
                                        handleChange(date)
                                      }}
                                      placeholderText={intl.formatMessage({
                                        id: 'LABEL.SELECT_END_DATE',
                                      })}
                                      showMonthDropdown
                                      useShortMonthInDropdown
                                      locale={lang || 'en'}
                                      minDate={startDate} // Set the minimum date to the selected start date
                                    />
                                  </div>
                                )
                              }}
                            </Field>
                            <ErrorMessage component='div' className='text-danger' name='end_date' />
                          </div>
                        </div>
                      </div>

                      <div className='form-floating flex-fill'>
                        <div className='form-check form-check-solid form-switch mb-0'>
                          <Field
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            name='status'
                            id='toggle'
                          />
                          <span
                            className={`me-4 fw-bold ${
                              values.status ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {values.status ? 'Active' : 'Inactive'}
                          </span>
                          <ErrorMessage component='div' className='text-danger' name='status' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-footer text-end' id='side_activities_footer'>
                  <div
                    className='btn btn-sm btn-secondary m-2'
                    onClick={() => handleCancel(resetForm)}
                    id='side_activities_close'
                  >
                    {intl.formatMessage({id: 'LABEL.CANCEL'})}
                  </div>
                  <button className='btn btn-light-primary btn-sm' type='submit'>
                    <KTIcon iconName='check' className='fs-7' />
                    {intl.formatMessage({id: 'BTN.SAVE'})}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {AddProject}
