import DateFormatter from '../../../DateFormatter'
import {useIntl} from 'react-intl'
import {FirstUserId} from '../../../core'

function Manager({managerDetails}) {
  const intl = useIntl()

  const {
    manager_reviewed_status = null,
    manager_review_comments = '',
    manager_reviewed_amount = 0,
    manager_reviewed_timestamp = '',
    currency_code = null,
    itemStatus = null,
    employee_id = null,
  } = managerDetails

  const getManagerView = (status) => {
    if (status === null) {
      return null
    } else if (status === 'Approved' || status === 'Rejected') {
      return (
        <>
          <p className='m-0 text-muted default-cursor'>
            {manager_reviewed_timestamp ? (
              <>
                {manager_reviewed_timestamp.length >= 10 &&
                  DateFormatter(manager_reviewed_timestamp.slice(0, 10))}{' '}
                {manager_reviewed_timestamp.length >= 19 &&
                  manager_reviewed_timestamp.slice(10, 19)}
              </>
            ) : (
              '-'
            )}
          </p>
          <p className='m-0 text-muted default-cursor'>{`${
            manager_review_comments ? manager_review_comments : '-'
          }`}</p>
        </>
      )
    } else {
      return null
    }
  }

  const getStatusMsg = (itemStatus, managerStatus) => {
    if (itemStatus === 'Revoked' && managerStatus === null && employee_id !== FirstUserId) {
      return 'LABLE.NO_ACTION'
    }
    if (itemStatus === 'Manager Pending') {
      return 'LABEL.PENDING'
    } else if (itemStatus !== 'Manager Pending' && managerStatus) {
      if (managerStatus === 'Approved') {
        return 'LABEL.APPROVED'
      } else {
        return 'LABEL.REJECTED'
      }
    } else if (itemStatus !== 'Manager Pending' && managerStatus === null) {
      return 'LABEL.NOT_APPLICABLE'
    } else {
      return '-'
    }
  }

  const getStatusColor = (itemStatus, managerStatus) => {
    if (itemStatus === 'Revoked' && managerStatus === null && employee_id !== FirstUserId) {
      return 'secondary'
    }
    if (itemStatus === 'Manager Pending') {
      return 'muted'
    } else if (itemStatus !== 'Manager Pending' && managerStatus) {
      if (managerStatus === 'Approved') {
        return 'success'
      } else {
        return 'danger'
      }
    } else if (itemStatus !== 'Manager Pending' && managerStatus === null) {
      return 'muted'
    } else {
      return 'secondary'
    }
  }

  const getIconColor = (status) => {
    if (status === null) {
      return 'secondary'
    } else if (status === 'Approved') {
      return 'success'
    } else if (status === 'Rejected') {
      return 'danger'
    } else {
      return 'muted'
    }
  }

  const getApprovedAmount = () => {
    if (manager_reviewed_status === 'Approved') {
      return ` (${currency_code}: ${manager_reviewed_amount})`
    }
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-20px me-4'>
        <div className={`symbol-label bg-${getIconColor(manager_reviewed_status)}`}></div>
      </div>

      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p
            className={`mb-0 fw-bold text-${getStatusColor(
              itemStatus,
              manager_reviewed_status
            )} default-cursor`}
          >
            {/* {getStatusMsg(itemStatus, manager_reviewed_status)} */}
            {intl.formatMessage({id: getStatusMsg(itemStatus, manager_reviewed_status)})}
            <span className='text-muted fw-normal'>{getApprovedAmount()}</span>
          </p>
          <div className=''>
            <p className='designation-btn bg-secondary fs-7 m-auto default-cursor'>
              {intl.formatMessage({id: 'LABEL.MANAGER'})}
            </p>
          </div>
        </div>
        {getManagerView(manager_reviewed_status)}
      </div>
    </div>
  )
}

export default Manager
