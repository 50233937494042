import {useState, useEffect} from 'react'

import secureLocalStorage from 'react-secure-storage'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import {baseUrl} from '../../core'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {DateBasicFormat} from '../../DateHelpers'
import {useThemeMode} from '../../../../_metronic/partials'
import {KTIcon} from '../../../../_metronic/helpers'
import useApiCall from '../../custom_hooks/useApiCall'
import React from 'react'
import usePostApi from '../../custom_hooks/usePostApi'
import es from 'date-fns/locale/es'
import ar from 'date-fns/locale/ar'
import fr from 'date-fns/locale/fr'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'

import {registerLocale} from 'react-datepicker'

const IssueOffer = (props) => {
  const intl = useIntl()
  const [offerdata, setData] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {data: currencytype} = useApiCall('/currencyTypes')
  const {execute} = usePostApi()
  const {mode} = useThemeMode()
  const {data, fetchData, isLoading} = useApiCall(
    `/recruitment/candidates/candidate/${props.props.id}`
  )
  let candidateData = data && data[0]
  const [locale, setLocale] = React.useState('en')

  const lang = useLang()

  const [first_name, setFirstName] = useState([])
  const [last_name, setLastName] = useState([])
  const [email, setemail] = useState([])
  const [role_name, setroleName] = useState([])
  const [job_title, settitleName] = useState([])
  const [vacancy_id, setvacancyname] = useState([])
  const [vacancy_currency_id, setvacancycurrencyid] = useState([])

  const [job_role_id, setjobroleid] = useState([])
  const [job_title_id, setjobtitleid] = useState([])
  const [city_id, setcityname] = useState([])

  useEffect(() => {
    if (candidateData) {
      setFirstName(candidateData?.first_name)
      setLastName(candidateData?.last_name)
      setemail(candidateData?.email)
      setroleName(candidateData?.role_name)
      settitleName(candidateData?.job_title)

      setvacancyname(candidateData?.vacancy_id)
      setvacancycurrencyid(candidateData?.vacancy_currency_id)
      setcityname(candidateData?.city_id)
      setjobroleid(candidateData?.job_role_id)
      setjobtitleid(candidateData?.job_title_id)
    }
  }, [candidateData])

  useEffect(() => {
    fetchData()
  }, [props.props.id])

  const validationSchemaEdit = Yup.object().shape({
    amount: Yup.string().required(intl.formatMessage({id: 'ERR.MSG.AMOUNT_IS_REQUIRED'})),
    // currency_id: Yup.string().required(
    //   `${intl.formatMessage({id: 'ERR.MSG.CURRENCY_IS_REQUIRED'})}`
    // ),
    date_of_joining: Yup.string().required(
      intl.formatMessage({id: 'ERR.MSG.DATE_OF_JOINING_IS_REQUIRED'})
    ),
  })

  const initialValues = {
    job_role_id: '',
    job_title_id: '',
    candidate_id: '',
    vacancy_id: '',
    currency_id: '',
    amount: '',
    location_id: '',
    attachment: '',
    date_of_joining: '',
  }

  useEffect(() => {
    if (lang === 'ar') {
      setLocale('ar')
    } else {
      setLocale(lang)
    }
  })

  registerLocale('es', es)
  registerLocale('ar', ar)
  registerLocale('fr', fr)

  const issueOffer = async (values, formikHelpers) => {
    // const {resetForm} = formikHelpers

    try {
      const organizationId = secureLocalStorage.getItem('organization_id')
      const url = `${baseUrl}/recruitment/offers`
      const formData = new FormData()
      const documentName = `${Date.now()}`

      const file = dataURLtoFile(offerdata, `image_${documentName}`)

      formData.append('attachment', file)

      formData.append('organization_id', organizationId)
      formData.append('candidate_id', props.props.id)
      formData.append('job_role_id', job_role_id)
      formData.append('job_title_id', job_title_id)
      formData.append('offer_status', 'issued')
      formData.append('currency_id', values.currency_id ? values.currency_id : vacancy_currency_id)
      formData.append('amount', parseFloat(values.amount + '.01'))
      formData.append('location_id', city_id)
      formData.append('Vacancy_id', vacancy_id)
      formData.append('date_of_joining', DateBasicFormat(values.date_of_joining))

      await execute(url, 'POST', formData)

      fetchData()
      props.props.fetchData()
      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.OFFER_ISSUED_SUCCESSFULLY'}),
        text: 'You Can View the Offers in "Offers" Section',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WENT_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  const handleSubmit = (values, {resetForm}) => {
    console.log(values)
    issueOffer(values, resetForm)
    resetForm()
  }

  const handleCancel = (resetForm, touched) => {
    if (Object.keys(touched).length === 0) {
      const sideDrawer = document.getElementById('side_activities_close')
      if (sideDrawer) {
        sideDrawer.click()
      }
    } else {
      resetForm()
    }
  }

  const dataURLtoFile = (dataURI, fileName) => {
    if (!dataURI) {
      return null // Add a null check to handle cases where dataURI is null
    }

    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new File([ab], fileName, {type: mimeString})
  }

  const handleCandidateSubmit = async (values, formikHelpers) => {
    // const {resetForm} = formikHelpers

    const organizationId = secureLocalStorage.getItem('organization_id')
    const url = `${baseUrl}/recruitment/offerStatus/${props.props.id}`
    const formData = {
      organization_id: organizationId,
      offer_status: 'Selected',
    }

    await execute(url, 'PATCH', formData)

    // resetForm()
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaEdit}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, handleChange, resetForm, touched}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {' '}
                    {intl.formatMessage({id: 'LABEL.ISSUE_OFFER'})}
                  </h3>
                  <div className='card-toolbar'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                      id='candidateInfoDrawerClose'
                    >
                      <i className='bi bi-x fs-1'></i>
                    </button>
                  </div>
                </div>

                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative scroll-y me-n5 pe-5 w-400px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      {isLoading ? (
                        <>
                          <div className='card h-350px'>
                            <div className='m-auto d-flex flex-column align-items-center'>
                              <div className='spinner-border spinner-primary mr-15'></div>
                            </div>
                          </div>
                        </>
                      ) : candidateData ? (
                        <>
                          <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {first_name} {last_name}
                              </span>
                            </div>
                          </div>

                          <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.EMAIL'})}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {email}
                              </span>
                            </div>
                          </div>

                          <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.ROLE'})}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {role_name}
                              </span>
                            </div>
                          </div>

                          {/* <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>
                              {' '}
                              {intl.formatMessage({id: 'LABEL.JOB_TITLE'})}
                            </label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {job_title}
                              </span>
                            </div>
                          </div> */}

                          {/* <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'> {intl.formatMessage({id: 'LABEL.LOCATION'})}</label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {vacancy_location_name}
                              </span>
                            </div>
                          </div> */}

                          {/* <div className='row mb-4'>
                            <label className='col-lg-4 fw-bold text-muted'>Currency</label>
                            <div className='col-lg-8'>
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {vacancy_currency_name}
                              </span>
                            </div>
                          </div> */}

                          <h5 className='mt-4 text-dark'>
                            {' '}
                            {intl.formatMessage({id: 'LABEL.WORKFLOW'})}
                          </h5>
                          <hr className='text-dark mt-2' />
                          <div className='card-body position-relative p-0' id='kt_activities_body'>
                            <div className='timeline'>
                              <div className='timeline-item'>
                                <div className='timeline-line w-20px'></div>
                                <div className='timeline-icon symbol symbol-circle symbol-20px'>
                                  <div
                                    className='symbol-label bg-primary
            
           icon-dotted-border text-primary'
                                  ></div>
                                </div>

                                <div className='timeline-content mb-12 mt-n1'>
                                  {/* <div className='d-flex justify-content-between mt-1'>
                                      <div className=''></div>
                                    </div> */}{' '}
                                  <div className='min-h-26px'>
                                    <label> {intl.formatMessage({id: 'LABEL.CURRENCY'})}</label>
                                    <br />
                                    <Field
                                      as='select'
                                      className='form-select form-select-solid'
                                      name='currency_id'
                                      value={values.currency_id}
                                      onChange={handleChange}
                                    >
                                      <option value=''>
                                        {intl.formatMessage({id: 'LABEL.SELECT_CURRENCY'})}
                                      </option>

                                      {currencytype?.map((currencyType) => (
                                        <option
                                          key={currencyType.currency_id}
                                          value={currencyType.currency_id}
                                        >
                                          {currencyType.currency_name}
                                        </option>
                                      ))}
                                    </Field>

                                    <ErrorMessage
                                      name='currency_id'
                                      component='div'
                                      className='text-danger'
                                    />
                                    <br />
                                    <label> {intl.formatMessage({id: 'LABEL.SALARY'})}</label>
                                    <br />
                                    <Field
                                      type='number'
                                      className='form-control form-control-solid'
                                      id='floatingInput'
                                      name='amount'
                                      value={values.amount}
                                      onChange={handleChange}
                                      placeholder={intl.formatMessage({id: 'LABEL.SELECT_SALARY'})}
                                    ></Field>
                                    <ErrorMessage
                                      name='amount'
                                      component='div'
                                      className='text-danger'
                                    />
                                    <br />
                                    <label>{intl.formatMessage({id: 'LABEL.OFFER_LETTER'})}</label>
                                    <br />
                                    <Field name='attachment'>
                                      {({form, field}) => {
                                        const {setFieldValue} = form
                                        const {value} = field
                                        const handleImageChange = (file) => {
                                          const reader = new FileReader()
                                          reader.onloadend = () => {
                                            const previewImage = reader.result
                                            setData(previewImage)
                                          }
                                          reader.readAsDataURL(file)
                                        }
                                        const handleHeaderChange = (e) => {
                                          const file = e.target.files?.[0]
                                          setFieldValue('attachment', file ? file.name : '')
                                          if (file) {
                                            handleImageChange(file)
                                          }
                                        }
                                        const handleInputClick = () => {
                                          document.getElementById('get-document-from-user').click()
                                        }
                                        return (
                                          <div>
                                            <input
                                              type='file'
                                              onChange={handleHeaderChange}
                                              id='get-document-from-user'
                                              className='form-control form-control-solid d-none'
                                            />
                                            <p
                                              className='form-control form-control-solid d-flex justify-content-between pe-10'
                                              onClick={handleInputClick}
                                            >
                                              <span>
                                                {value
                                                  ? value
                                                  : intl.formatMessage({
                                                      id: 'LABEL.CHOOSE_FILE_TO_UPLOAD',
                                                    })}
                                              </span>
                                              <span>
                                                <KTIcon
                                                  iconName='paper-clip'
                                                  className='fs-3 position-absolute ms-3'
                                                />
                                              </span>
                                            </p>
                                          </div>
                                        )
                                      }}
                                    </Field>
                                    <ErrorMessage
                                      name='attachment'
                                      component='div'
                                      className='text-danger'
                                    />

                                    <label>{intl.formatMessage({id: 'LABEL.SELECT_DATE'})}</label>
                                    <br />

                                    <Field name='date_of_joining'>
                                      {({form, field}) => {
                                        const {setFieldValue} = form
                                        const {value} = field

                                        const handleChange = (date) => {
                                          setFieldValue('date_of_joining', date)
                                        }

                                        return (
                                          <div
                                            className={`react-datepicker-fullwidth-wrapper test ${
                                              mode === 'dark' ? 'dark-mode' : 'light-mode'
                                            }`}
                                          >
                                            <DatePicker
                                              selected={value}
                                              minDate={new Date()}
                                              isClearable
                                              showYearDropdown
                                              scrollableYearDropdown
                                              onChange={(date) => {
                                                handleChange(date)
                                              }}
                                              placeholderText={intl.formatMessage({
                                                id: 'LABEL.SELECT_JOINING_DATE',
                                              })}
                                              showMonthDropdown
                                              useShortMonthInDropdown
                                              dateFormat='dd/MM/yyyy'
                                              className='form-control form-control-solid'
                                              popperPlacement='bottom'
                                              locale={locale}
                                            />
                                          </div>
                                        )
                                      }}
                                    </Field>
                                    <ErrorMessage
                                      name='date_of_joining'
                                      component='div'
                                      className='text-danger'
                                    />
                                    <br />
                                  </div>
                                  <div className='card-footer p-0 py-5 mt-5 text-end'>
                                    <button
                                      type='button'
                                      className='btn btn-light btn-sm me-2'
                                      onClick={() => handleCancel(resetForm, touched)}
                                    >
                                      {Object.keys(touched).length === 0 ? 'Close' : 'Clear'}
                                    </button>

                                    <button
                                      type='submit'
                                      className='btn btn-light-primary btn-sm ms-2'
                                      onClick={() => {
                                        setIsSubmitting(true)
                                        handleCandidateSubmit()
                                      }}
                                    >
                                      {intl.formatMessage({id: 'BTN.ISSUE_OFFER'})}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className='d-flex justify-content-center'>
                          <div className='h-250px d-flex flex-column justify-content-center'>
                            <button
                              className='btn btn-light-primary btn-sm w-100px m-auto'
                              onClick={() => fetchData()}
                            >
                              {intl.formatMessage({id: 'LABEL.RETRY'})}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default IssueOffer
