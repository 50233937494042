/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import usePageTitle from '../../custom_hooks/usePageTitle'
import {KTIcon} from '../../../../_metronic/helpers'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {GetOrganizationInfo} from '../OrganizationAPI'
import usePostApi from '../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import useApiCall from '../../custom_hooks/useApiCall'
import DateFormatter from '../../DateFormatter'
function OrganizationInfo() {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const {data: countriesData}: any | null = useApiCall(`/country`)
  usePageTitle(intl.formatMessage({id: 'MENU.ORGANIZATION_CONFIGURATION.ORGANIZATION'}))
  const [isEditMode, setIsEditMode] = useState(false)
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {is_admin, organization_id} = userInfo || {}
  const {organizationInfo, isLoading} = GetOrganizationInfo()
  const [isChecked, setIsChecked] = useState<boolean>(true)
  const [countryId, setCountryId]: any | null = useState(null)
  const [stateId, setStateId]: any | null = useState(null)
  const [cityId, setCityId]: any | null = useState(null)
  const {data: states, fetchData: fetchStates}: any | null = useApiCall(
    countryId
      ? `/state/${countryId}`
      : organizationInfo?.state_id
      ? `/state/${organizationInfo?.country_id}`
      : null
  )
  const {data: citiesData, fetchData: fetchCities}: any | null = useApiCall(
    stateId
      ? `/city/${stateId}`
      : organizationInfo?.city_id
      ? `/city/${organizationInfo?.state_id}`
      : null
  )
  let cities: any | null = citiesData ? citiesData : null
  useEffect(() => {
    fetchStates()
  }, [countryId])
  useEffect(() => {
    fetchCities()
  }, [stateId])
  const handleEditClick = () => {
    setIsEditMode(true)
  }
  const handleCancelClick = (resetForm: any) => {
    setIsEditMode(false)
    resetForm()
  }
  const initialValues = {
    organization_name: organizationInfo?.organization_name || '',
    tax_id: organizationInfo?.tax_id || '',
    registration_number: organizationInfo?.registration_number || '',
    address_line1: organizationInfo?.address_line1 || '',
    address_line2: organizationInfo?.address_line2 || '',
    state_name: stateId,
    city_name: cityId,
    country_name: countryId,
    postal_code: organizationInfo?.postal_code || '',
    status: isChecked || true,
  }
  const validationSchema = Yup.object().shape({
    organization_name: Yup.string().required(intl.formatMessage({id: 'LABEL.NAME_REQUIRED'})),
    address_line1: Yup.string().required(intl.formatMessage({id: 'LABEL.ADDRESS_LINE1_REQUIRED'})),
    address_line2: Yup.string().required(intl.formatMessage({id: 'LABEL.ADDRESS_LINE2_REQUIRED'})),
    country_name: Yup.string().required(intl.formatMessage({id: 'LABEL.COUNTRY_NAME_REQUIRED'})),
    postal_code: Yup.string().required(intl.formatMessage({id: 'LABEL.POSTAL_CODE_REQUIRED'})),
  })
  const handleSaveClick = async (values: any) => {
    setIsEditMode(false)
    try {
      const url = `${baseUrl}/organization/${organization_id}`
      const result = await Swal.fire({
        title: intl.formatMessage({id: 'LABEL.UPDATING_ORGANIZATION_INFORMATION'}),
        text: intl.formatMessage({id: 'LABEL.CONFIRM_ORGANIZATION_INFORMATION'}),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: intl.formatMessage({id: 'BTN.CONFIRM'}),
        cancelButtonText: intl.formatMessage({id: 'LABEL.CANCEL'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
          cancelButton: 'btn btn-sm btn-secondary',
        },
      })
      if (result.isConfirmed) {
        const organizationdata = {
          organization_id: organization_id,
          tenant_id: organizationInfo?.tenant_id,
          billing_organization_id: organizationInfo?.billing_organization_id,
          organization_code: organizationInfo?.organization_code,
          organization_name: values.organization_name,
          start_date: organizationInfo?.date_of_incorporation,
          date_of_incorporation: organizationInfo?.date_of_incorporation,
          organization_address_id: organizationInfo?.organization_address_id,
          tax_id: values.tax_id,
          registration_number: values.registration_number,
          status: values.status ? 1 : 0,
          address_line1: values.address_line1,
          address_line2: values.address_line2,
          state_id: parseInt(stateId) || 0,
          city_id: parseInt(cityId) || 0,
          country_id: parseInt(countryId),
          postal_code: values.postal_code,
        }
        await execute(url, 'PATCH', organizationdata, id)
        setIsEditMode(false)
        Swal.fire({
          title: intl.formatMessage({id: 'LABEL.ORGANIZATION_INFORMATION_EDITED'}),
          text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-light-primary btn-sm',
          },
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
      console.error('Error updating data:', error)
    }
  }
  useEffect(() => {
    if (organizationInfo) {
      setIsChecked(organizationInfo?.status === 1)
      setStateId(organizationInfo?.state_id)
      setCountryId(organizationInfo?.country_id)
      setCityId(organizationInfo?.city_id)
    }
  }, [organizationInfo?.country_id])
  const handleChangeMethod = (selectedValue: any) => {
    setCountryId(selectedValue)
    setStateId(null)
    setCityId(null)
  }
  const handleChangeState = (selectedValue: any) => {
    setStateId(selectedValue)
    setCityId(null)
  }
  const handleChangeCity = (selectedValue: any) => {
    setCityId(selectedValue)
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          {intl.formatMessage({id: 'LABEL.ORGANIZATION_INFORMATION'})}
        </h3>
      </div>
      <div className='row g-5 g-xxl-8'>
        <div className='col-xl-6'>
          {isLoading ? (
            <div className='card h-250px'>
              <div className='m-auto d-flex flex-column align-items-center'>
                <div className='spinner-border spinner-primary mr-15'></div>
              </div>
            </div>
          ) : (
            <div className={`card mb-4 card-lg-stretch`}>
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={handleSaveClick}
              >
                {({values, handleChange, resetForm}) => {
                  return (
                    <Form>
                      <div className='card-header'>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-3 mb-1'>
                            {intl.formatMessage({id: 'MENU.LABEL.INFORMATION'})}
                          </span>
                        </h3>
                        {is_admin === 1 && (
                          <div className='card-toolbar'>
                            {!isEditMode ? (
                              <button
                                className='btn btn-sm btn-light-primary btn-ripple'
                                onClick={handleEditClick}
                              >
                                <KTIcon iconName='pencil' className='fs-7' />
                                {intl.formatMessage({id: 'BTN.EDIT'})}
                              </button>
                            ) : (
                              <div>
                                <button className='btn btn-light-primary btn-sm' type='submit'>
                                  <KTIcon iconName='check' className='fs-7' />
                                  {intl.formatMessage({id: 'BTN.SAVE'})}
                                </button>
                                <div
                                  className='btn btn-icon btn-sm btn-active-light-danger ms-2'
                                  onClick={() => handleCancelClick(resetForm)}
                                >
                                  <KTIcon iconName='cross' className='fs-2' />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className='card-body'>
                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-bold text-muted'>
                            {intl.formatMessage({id: 'LABEL.ORGANIZATION_NAME'})}
                          </label>
                          <div className='col-lg-8'>
                            {isEditMode ? (
                              <>
                                <Field
                                  type='text'
                                  name='organization_name'
                                  className='form-control'
                                  placeholder={intl.formatMessage({id: 'LABEL.ORGANIZATION_NAME'})}
                                  id='organization_name'
                                  value={values.organization_name}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name='organization_name'
                                  component='div'
                                  className='text-danger'
                                />
                              </>
                            ) : (
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {organizationInfo?.organization_name}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-bold text-muted'>
                            {intl.formatMessage({id: 'LABEL.TAX_ID'})}
                          </label>
                          <div className='col-lg-8 fv-row'>
                            {isEditMode ? (
                              <>
                                <Field
                                  type='text'
                                  name='tax_id'
                                  className='form-control'
                                  placeholder={intl.formatMessage({id: 'LABEL.TAX_ID'})}
                                  id='tax_id'
                                  value={values.tax_id}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name='tax_id'
                                  component='div'
                                  className='text-danger'
                                />
                              </>
                            ) : (
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {organizationInfo?.tax_id}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-bold text-muted'>
                            {intl.formatMessage({id: 'LABEL.ORGANIZATION_REGISTRATION_NUMBER'})}
                          </label>
                          <div className='col-lg-8'>
                            {isEditMode ? (
                              <>
                                <Field
                                  type='text'
                                  name='registration_number'
                                  className='form-control'
                                  placeholder={intl.formatMessage({
                                    id: 'LABEL.ORGANIZATION_REGISTRATION_NUMBER',
                                  })}
                                  id='registration_number'
                                  value={values.registration_number}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name='registration_number'
                                  component='div'
                                  className='text-danger'
                                />
                              </>
                            ) : (
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {organizationInfo?.registration_number}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-bold text-muted'>
                            {intl.formatMessage({id: 'LABEL.ORGANIZATION_REGISTRATION_ADDRESS'})}
                          </label>
                          <div className='col-lg-8'>
                            {isEditMode ? (
                              <>
                                <div className='form-floating mb-4'>
                                  <Field
                                    type='text'
                                    className='form-control'
                                    id='address_line1'
                                    value={values.address_line1}
                                    onChange={handleChange}
                                    name='address_line1'
                                  />
                                  <label htmlFor='address_line1'>
                                    {intl.formatMessage({id: 'LABEL.ADDRESS_LINE-1'})}
                                  </label>
                                  <ErrorMessage
                                    component='div'
                                    className='text-danger'
                                    name='address_line1'
                                  />
                                </div>
                                <div className='form-floating mb-4'>
                                  <Field
                                    type='text'
                                    className='form-control'
                                    id='address_line2'
                                    value={values.address_line2}
                                    onChange={handleChange}
                                    name='address_line2'
                                  />
                                  <label htmlFor='address_line2'>
                                    {intl.formatMessage({id: 'LABEL.ADDRESS_LINE-2'})}
                                  </label>
                                  <ErrorMessage
                                    component='div'
                                    className='text-danger'
                                    name='address_line2'
                                  />
                                </div>
                                <div className='form-floating my-2'>
                                  <Field
                                    as='select'
                                    id='floatingInput'
                                    className='form-select'
                                    name='country_name'
                                    value={countryId ? countryId : ''}
                                    onChange={(e: any) => handleChangeMethod(e.target.value)}
                                  >
                                    <option value=''>
                                      {intl.formatMessage({id: 'LABEL.SELECT_COUNTRY'})}
                                    </option>
                                    {countriesData?.map((option: any) => (
                                      <option key={option.country_id} value={option.country_id}>
                                        {option.country_name}
                                      </option>
                                    ))}
                                  </Field>
                                  <label htmlFor='floatingInput'>
                                    {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                                  </label>
                                  <ErrorMessage
                                    component='div'
                                    className='text-danger'
                                    name='country_name'
                                  />
                                </div>
                                {(states?.length > 0 || organizationInfo?.state_id) && (
                                  <div className='form-floating my-2'>
                                    <Field
                                      as='select'
                                      id='floatingInput'
                                      className='form-select'
                                      name='state_name'
                                      value={stateId ? stateId : ''}
                                      onChange={(e: any) => handleChangeState(e.target.value)}
                                    >
                                      <option value=''>
                                        {intl.formatMessage({id: 'LABEL.SELECT_STATE'})}
                                      </option>
                                      {states?.map((option: any) => (
                                        <option key={option.state_id} value={option.state_id}>
                                          {option.state_name}
                                        </option>
                                      ))}
                                    </Field>
                                    <label htmlFor='floatingInput'>
                                      {intl.formatMessage({id: 'LABEL.STATE'})}
                                    </label>
                                    <ErrorMessage
                                      component='div'
                                      className='text-danger'
                                      name='state_name'
                                    />
                                  </div>
                                )}
                                {cities?.length > 0 && stateId && (
                                  <div className='form-floating my-2'>
                                    <Field
                                      as='select'
                                      id='floatingInput'
                                      className='form-select'
                                      name='city_name'
                                      value={cityId ? cityId : ''}
                                      onChange={(e: any) => handleChangeCity(e.target.value)}
                                    >
                                      <option value=''>
                                        {intl.formatMessage({id: 'LABEL.SELECT_CITY'})}
                                      </option>
                                      {cities?.map((option: any) => (
                                        <option key={option.city_id} value={option.city_id}>
                                          {option.city_name}
                                        </option>
                                      ))}
                                    </Field>
                                    <label htmlFor='floatingInput'>
                                      {intl.formatMessage({id: 'LABEL.CITY'})}
                                    </label>
                                    <ErrorMessage
                                      component='div'
                                      className='text-danger'
                                      name='city_name'
                                    />
                                  </div>
                                )}
                                <div className='form-floating my-2'>
                                  <Field
                                    type='text'
                                    className='form-control'
                                    id='floatingInput'
                                    placeholder='type...'
                                    name='postal_code'
                                    value={values.postal_code}
                                  />
                                  <label htmlFor='floatingInput'>
                                    {intl.formatMessage({id: 'LABEL.POSTAL_CODE'})}
                                  </label>
                                  <ErrorMessage
                                    component='div'
                                    className='text-danger'
                                    name='postal_code'
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                  {organizationInfo?.country_name}
                                  {organizationInfo?.state_name &&
                                    ` , ${organizationInfo.state_name}`}
                                  {organizationInfo?.city_name &&
                                    ` , ${organizationInfo.city_name}`}
                                </span>
                                <br />
                                <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                  {organizationInfo?.address_line1}
                                </span>
                                <br />
                                <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                  {organizationInfo?.address_line2}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-bold text-muted'>
                            {intl.formatMessage({id: 'LABEL.ORGANIZATION_DATE_OF_INCORPORATION'})}
                          </label>
                          <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                              {DateFormatter(organizationInfo?.date_of_incorporation)}
                            </span>
                          </div>
                        </div>
                        <div className='row mb-4'>
                          <label className='col-lg-4 fw-bold text-muted'>
                            {intl.formatMessage({id: 'LABEL.ORGANIZATION_COMPANY_STATUS'})}
                          </label>
                          <div className='col-lg-8'>
                            {isEditMode ? (
                              <div className='form-check form-check-solid form-switch'>
                                <Field
                                  className='form-check-input w-40px h-20px'
                                  type='checkbox'
                                  id='toggle'
                                  name='status'
                                  checked={values.status}
                                  onChange={handleChange}
                                />
                                <span
                                  className={`me-4 fw-bold ${
                                    values.status ? 'text-success' : 'text-danger'
                                  }`}
                                >
                                  {values.status ? 'Active' : 'Inactive'}
                                </span>
                                <ErrorMessage
                                  component='div'
                                  className='text-danger'
                                  name='status'
                                />
                              </div>
                            ) : (
                              <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                {organizationInfo?.status === 1 ? 'Active' : 'Inactive'}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default OrganizationInfo
