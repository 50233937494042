import {useEffect, useState} from 'react'
import useApiCall from '../custom_hooks/useApiCall'
import secureLocalStorage from 'react-secure-storage'
export function GetAllInputs() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: performanceData,
    isLoading: isperformanceLoading,
    fetchData,
  } = useApiCall(`/performance/epi/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  return {performanceData, isperformanceLoading, fetchData}
}

export function GetAllApprovedTasks() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: approvedtasksData,
    isLoading: isapprovedLoading,
    fetchData,
  } = useApiCall(`/performance/epi/${emp_id}`)

  useEffect(() => {
    fetchData()
  }, [])

  // Filter and return only approved leaves
  const approvedtasks = approvedtasksData?.filter(
    (task) => task.performance_input_status === 'Approved'
  )

  return {approvedtasks, isapprovedLoading}
}

export function GetAllEmpinputs() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: allinputData,
    isinputLoading,
    fetchData,
  } = useApiCall(emp_id ? `/performance/TeamEpi/${emp_id}` : null)

  useEffect(() => {
    fetchData()
  }, [])

  return {allinputData, isinputLoading}
}

export function GetAllApprovedEmpinputs() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {
    data: getallinputData,
    isallapprovedempLoading,
    fetchData,
  } = useApiCall(emp_id ? `/performance/TeamEpi/${emp_id}` : null)

  useEffect(() => {
    fetchData()
  }, [])

  // Filter and return only approved leaves
  const allapprovedinputs = getallinputData?.filter(
    (input) => input.performance_input_status === 'Approved'
  )

  return {allapprovedinputs, isallapprovedempLoading}
}
