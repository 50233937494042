import {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Swal from 'sweetalert2'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import useApiCall from '../../custom_hooks/useApiCall'
const EditChecklist = (props: any) => {
  const {checklistId, refreshData} = props.id
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {organization_id} = userInfo || {}
  const {data, fetchData, isLoading}: any | null = useApiCall(
    checklistId ? `/hrConfig/checklistTypes/${checklistId}` : null
  )
  let checkListData = data && data[0]
  const [checklistTypeName, setChecklistTypeName] = useState<string>('')
  const [checklistTypeCode, setChecklistTypeCode] = useState<string>('')
  const [isChecked, setIsChecked] = useState<boolean>(true)
  useEffect(() => {
    if (checkListData) {
      setChecklistTypeName(checkListData.checklist_name)
      setChecklistTypeCode(checkListData.checklist_code)
      setIsChecked(checkListData.status === 1)
    }
  }, [checkListData])

  const validationSchemaEdit = Yup.object().shape({
    checklist_name: Yup.string().required(intl.formatMessage({id: 'LABEL.NAME_REQUIRED'})),
    checklist_code: Yup.string().required(intl.formatMessage({id: 'LABEL.CODE_REQUIRED'})),
    status: Yup.boolean().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })

  const initialValues = {
    checklist_name: checklistTypeName || '',
    checklist_code: checklistTypeCode || '',
    status: isChecked || true,
  }

  const handleEditSubmit = async (values: any) => {
    const url = `${baseUrl}/hrConfig/checklistTypes/${checklistId}`
    try {
      const requestData = {
        organization_id: organization_id,
        checklist_name: values.checklist_name,
        checklist_code: values.checklist_code,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'PATCH', requestData, id)
      refreshData()
      const closeBtn = document.querySelector(
        '#kt_modal_edit_checklist [data-bs-dismiss="modal"]'
      ) as HTMLElement
      if (closeBtn) {
        closeBtn.click()
      }
      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.CHECKLIST_EDITED'}),
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [checklistId])

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchemaEdit}
        onSubmit={handleEditSubmit}
      >
        {({values, handleChange}) => {
          return (
            <Form>
              <div className='modal-dialog'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h3 className='modal-title fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL.EDIT_CHECKLIST_TYPE'})}
                    </h3>
                    <div className='modal-toolbar'>
                      <div
                        className='btn btn-icon btn-sm btn-active-light-danger'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <KTIcon iconName='cross' className='fs-2' />
                      </div>
                    </div>
                  </div>
                  <div className='modal-body position-relative'>
                    <div>
                      {isLoading ? (
                        <div className='card card-xl-stretch'>
                          <div className='m-auto d-flex flex-column align-items-center'>
                            <div className='spinner-border spinner-primary mr-15'></div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='form-floating mb-4'>
                            <Field
                              type='text'
                              className='form-control'
                              id='floatingInput'
                              placeholder='type...'
                              name='checklist_name'
                              value={values.checklist_name}
                              onChange={handleChange}
                            />
                            <label htmlFor='floatingInput'>
                              {intl.formatMessage({id: 'LABEL.NAME'})}
                            </label>
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='checklist_name'
                            />
                          </div>
                          <div className='form-floating mb-4'>
                            <Field
                              type='text'
                              className='form-control'
                              id='floatingInput'
                              placeholder='type...'
                              name='checklist_code'
                              value={values.checklist_code}
                              onChange={handleChange}
                            />
                            <label htmlFor='floatingInput'>
                              {intl.formatMessage({id: 'LABEL.CHECKLIST_CODE'})}
                            </label>
                            <ErrorMessage
                              component='div'
                              className='text-danger'
                              name='checklist_code'
                            />
                          </div>
                          <div className='form-floating mb-0'>
                            <div className='form-check form-check-solid form-switch'>
                              <Field
                                className='form-check-input w-40px h-20px'
                                type='checkbox'
                                id='toggle'
                                name='status'
                                checked={values.status}
                                onChange={handleChange}
                              />
                              <span
                                className={`me-4 fw-bold ${
                                  values.status ? 'text-success' : 'text-danger'
                                }`}
                              >
                                {values.status ? 'Active' : 'Inactive'}
                              </span>
                            </div>
                            <ErrorMessage component='div' className='text-danger' name='status' />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='modal-footer text-end'>
                    <div
                      className='btn btn-sm btn-secondary m-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      {intl.formatMessage({id: 'LABEL.CANCEL'})}
                    </div>
                    <button className='btn btn-light-primary btn-sm' type='submit'>
                      <KTIcon iconName='check' className='fs-7' />
                      {intl.formatMessage({id: 'BTN.SAVE'})}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export {EditChecklist}
