//businessCard
import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Multiselect from 'multiselect-react-dropdown'
import Swal from 'sweetalert2'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import usePostApi from '../../custom_hooks/usePostApi'
import useApiCall from '../../custom_hooks/useApiCall'

const EditVacancy = (props) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const [isEditMode, setIsEditMode] = useState(false)
  const {data, fetchData, isLoading} = useApiCall(`/recruitment/vacancies/${props.props.id}`)
  console.log(data)
  const {data: jobroles} = useApiCall('/hrConfig/jobRole')
  const {data: jobtitles} = useApiCall('/hrConfig/jobTitle')
  const {data: departmenttypes} = useApiCall('/masterData/departments')
  const {data: employmenttype} = useApiCall('/masterData/employment_types')
  const {data: skillset} = useApiCall('/masterData/skillsets')

  const {data: currencytype} = useApiCall('/currencyTypes')

  const {data: countries} = useApiCall('/country')
  const [countryId, setCountryId] = useState(null)
  const [stateId, setStateId] = useState(null)

  const [vacancydepartmentid, setvacancydepartment] = useState(null)
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null)

  const {data: hiringManager, fetchData: fetchManager} = useApiCall(
    vacancydepartmentid ? `/masterData/managers/${vacancydepartmentid}` : null
  )
  const [selectedskills, setSelectedskills] = useState([])

  const handleSelect = (selectedList, selectedItem) => {
    setSelectedskills(selectedList.map((item) => item.value))
  }

  const handleRemove = (selectedList, removedItem) => {
    setSelectedskills(selectedList.map((item) => item.value))
  }

  const {
    data: states,

    fetchData: fetchStates,
  } = useApiCall(countryId ? `/state/${countryId}` : null)
  const {
    data: citiesData,
    isLoading: citiesLoading,
    fetchData: fetchCities,
  } = useApiCall(stateId ? `/city/${stateId}` : null)
  let cities = citiesData ? citiesData : null

  useEffect(() => {
    fetchStates()
    cities = []
  }, [countryId])
  useEffect(() => {
    fetchCities()
  }, [stateId])
  useEffect(() => {
    fetchManager()
  }, [vacancydepartmentid])

  let VacancyData = data && data[0]

  const validationSchema = Yup.object().shape({
    job_role_id: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.POSITION_IS_REQUIRED'})}`
    ),
    job_title_id: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.JOBTITLE_IS_REQUIRED'})}`
    ),
    qualifications: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.QUALIFICATION_IS_REQUIRED'})}`
    ),
    experience: Yup.number()
      .moreThan(
        -1,
        `${intl.formatMessage({id: 'ERR.MSG.EXPERIENCE_MUST_BE_A_POSITIVE_NUMBER_OR_ZERO'})}`
      )
      .required(`${intl.formatMessage({id: 'ERR.MSG.EXPERIENCE_IS_REQUIRED'})}`),
    number_of_vacancies: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.NUMBER_OF_VACANCIES_IS_REQUIRED'})}`
    ),
    salary_start: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.SALARY_IS_REQUIRED'})}`
    ),
    salary_end: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.SALARY_IS_REQUIRED'})}`),
    currency_code: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.CURRENCY_IS_REQUIRED'})}`
    ),
    service_agreement: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.SERVICE_AGREEMENT_IS_REQUIRED'})}`
    ),
    // skills: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.SKILLS_IS_REQUIRED'})}`),
    job_description: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.DESCRIPTION_IS_REQUIRED'})}`
    ),
    department_id: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.DEPARTMENT_IS_REQUIRED'})}`
    ),
    city_id: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.CITY_IS_REQUIRED'})}`),
    state_id: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.STATE_IS_REQUIRED'})}`),
    country_id: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.COUNTRY_IS_REQUIRED'})}`),
    employment_type: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.EMPLOYMENTTYPE_IS_REQUIRED'})}`
    ),
    hiring_manager_id: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.HIRING_MANAGER_IS_REQUIRED'})}`
    ),
  })
  const initialValues = {
    job_role_id: VacancyData?.job_role_id || '',
    job_title_id: VacancyData?.job_title_id || '',

    job_description: VacancyData?.job_description || '',
    department_id: VacancyData?.department_id ? VacancyData?.department_id : 0,
    number_of_vacancies: VacancyData?.number_of_vacancies || '',

    country_id: VacancyData?.country_id ? VacancyData?.country_id : 0,
    state_id: VacancyData?.state_id ? VacancyData?.state_id : 0,
    city_id: VacancyData?.city_id ? VacancyData?.city_id : 0,
    salary_start: VacancyData?.salary_start || '',
    salary_end: VacancyData?.salary_end || '',

    qualifications: VacancyData?.qualifications || '',

    experience: VacancyData?.experience || '',
    service_agreement: VacancyData?.service_agreement || '',

    skills: VacancyData?.skill || '',

    employment_type: VacancyData?.employment_type || '',
    currency_code: VacancyData?.currency_code || '',
    hiring_manager_id: VacancyData?.hiring_manager_id ? VacancyData?.hiring_manager_id : 0,
    vacancy_status: VacancyData?.vacancy_status || '',
  }

  const handleEditSubmit = async (values) => {
    const url = `${baseUrl}/recruitment/vacancies/${props.props.id}`
    try {
      const organizationId = secureLocalStorage.getItem('organization_id')
      const selectedCurrency = currencytype.find(
        (currencyType) => currencyType.currency_code === values.currency_code
      )

      const formData = {
        organization_id: organizationId,

        job_role_id: parseInt(values.job_role_id),
        job_title_id: parseInt(values.job_title_id),
        department_id: parseInt(values.department_id),

        hiring_manager_id: parseInt(values.hiring_manager_id) || 0,
        number_of_vacancies: values.number_of_vacancies,
        job_description: values.job_description,
        state_id: parseInt(values.state_id) || 0,
        city_id: parseInt(values.city_id) || 0,
        country_id: parseInt(values.country_id),
        salary_start: values.salary_start,
        salary_end: values.salary_end,

        salary_currency_id: selectedCurrency.currency_id,
        qualifications: values.qualifications,
        skills: values.skills,
        experience: values.experience,
        service_agreement: values.service_agreement,
        employment_type: values.employment_type,
        vacancy_status: values.vacancy_status ? 'Open' : 'Closed',
      }

      setIsEditMode(false)

      await execute(url, 'PATCH', formData)
      fetchData()
      props.props.fetchData()
      Swal.fire({
        title: intl.formatMessage({id: 'MSG.VACANCY_CREATED_SUCCESSFULLY'}),
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [props.props.id])

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleSaveClick = () => {
    setIsEditMode(false)
    // Add your save logic here
  }
  return (
    <div>
      <div className='card shadow-none rounded-0'>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleEditSubmit}
        >
          {({values, setFieldValue, handleChange}) => {
            return (
              <>
                <Form>
                  <div className='card-header' id='side_activities_header'>
                    <h3 className='card-title fw-bolder text-dark'>
                      {intl.formatMessage({id: 'LABEL.EDIT_VACANCY'})}
                    </h3>
                    <div className='card-toolbar '>
                      {!isEditMode ? (
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary px-4 me-3'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-start'
                          data-kt-menu-flip='top-end'
                          onClick={handleEditClick}
                        >
                          <KTIcon iconName='pencil' className='fs-3' />
                          {intl.formatMessage({id: 'BTN.EDIT'})}
                        </button>
                      ) : (
                        <React.Fragment>
                          <div
                            className='btn btn-icon btn-sm btn-active-danger'
                            onClick={handleSaveClick}
                            id='side_activities_close'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr061.svg'
                              className='svg-icon svg-icon-2x'
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className='card-body position-relative' id='side_activities_body'>
                    <div>
                      <div
                        id='side_activities_scroll'
                        className='position-relative scroll-y me-n5 pe-5 w-400px'
                        data-kt-scroll='true'
                        data-kt-scroll-height='auto'
                        data-kt-scroll-wrappers='#side_activities_body'
                        data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                        data-kt-scroll-offset='5px'
                      >
                        <div>
                          {isLoading ? (
                            <div className='card h-350px'>
                              <div className='m-auto d-flex flex-column align-items-center'>
                                <div className='spinner-border spinner-primary mr-15'></div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.POSITION'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        as='select'
                                        id='floatingInput'
                                        className='form-select'
                                        name='job_role_id'
                                        onChange={(e) => {
                                          const selectedJobRoleId = e.target.value
                                          // Fetch the corresponding department ID based on the selected job role
                                          const correspondingDepartmentId = jobroles.find(
                                            (jobrole) =>
                                              jobrole.job_role_id === parseInt(selectedJobRoleId)
                                          )?.department_id

                                          // Set the selected values in the form
                                          setFieldValue('job_role_id', selectedJobRoleId)
                                          setFieldValue('department_id', correspondingDepartmentId)
                                          setvacancydepartment(correspondingDepartmentId)
                                          handleChange(e)
                                        }}
                                      >
                                        <option value=''>
                                          {' '}
                                          {intl.formatMessage({id: 'LABEL.SELECT_JOB_ROLE'})}
                                        </option>

                                        {jobroles?.map((jobrole) => (
                                          <option
                                            key={jobrole.job_role_id}
                                            value={jobrole.job_role_id}
                                          >
                                            {jobrole.role_name}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name='job_role_id'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.role_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.EMP.JOB_TITLE'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        as='select'
                                        id='floatingInput'
                                        className='form-select'
                                        name='job_title_id'
                                        onChange={handleChange}
                                      >
                                        <option value=''>
                                          {intl.formatMessage({id: 'LABEL.SELECT_JOB_TITLE'})}
                                        </option>

                                        {jobtitles?.map((jobtitle) => (
                                          <option
                                            key={jobtitle.job_title_id}
                                            value={jobtitle.job_title_id}
                                          >
                                            {jobtitle.job_title}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name='job_title_id'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.title_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='job_description'
                                        className='form-control'
                                        placeholder={intl.formatMessage({id: 'LABEL.DESCRIPTION'})}
                                      />
                                      <ErrorMessage
                                        name='job_description'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.job_description}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.DEPARTMENTS'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        as='select'
                                        id='floatingInput'
                                        className='form-select'
                                        name='department_id'
                                        onChange={(e) => {
                                          const selectedId = e.target.value
                                          setvacancydepartment(selectedId)

                                          handleChange(e)
                                        }}
                                      >
                                        <option value=''>
                                          {' '}
                                          {intl.formatMessage({id: 'LABEL.SELECT_DEPARTMENT'})}
                                        </option>

                                        {departmenttypes.map((departmentType) => (
                                          <option
                                            key={departmentType.department_id}
                                            value={departmentType.department_id} // Change this to use the department_id
                                          >
                                            {departmentType.department_name}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name='department_id'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.department_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.QUALIFICATION'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='qualifications'
                                        className='form-control'
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.QUALIFICATION',
                                        })}
                                      />
                                      <ErrorMessage
                                        name='qualifications'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.qualifications}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.SKILLS'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='skills'
                                        className='form-control form-control-solid'
                                        value={values.skills}
                                        placeholder='skills'
                                        onChange={handleChange}
                                      />

                                      <ErrorMessage
                                        name='skills'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.skill}
                                    </span>
                                  )}
                                </div>
                              </div>
                              {hiringManager?.length > 0 && (
                                <>
                                  <div className='row mb-4'>
                                    <label className='col-lg-4 fw-bold text-muted'>
                                      {intl.formatMessage({id: 'LABEL.HIRING_MANAGER'})}
                                    </label>
                                    <div className='col-lg-8'>
                                      {isEditMode ? (
                                        <>
                                          <Field
                                            as='select'
                                            id='floatingInput'
                                            className='form-select form-select-solid'
                                            name='hiring_manager_id'
                                            onChange={handleChange}
                                          >
                                            <option value=''>
                                              {intl.formatMessage({
                                                id: 'LABEL.SELECT_HIRING_MANAGER',
                                              })}
                                            </option>

                                            {hiringManager?.map((hiringmanager) => (
                                              <option
                                                key={hiringmanager.employee_id}
                                                value={hiringmanager.employee_name}
                                              >
                                                {hiringmanager.employee_name}
                                              </option>
                                            ))}
                                          </Field>
                                          <ErrorMessage
                                            name='hiring_manager_id'
                                            component='div'
                                            className='text-danger'
                                          />
                                        </>
                                      ) : (
                                        <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                          {VacancyData?.hiring_manager_name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}{' '}
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.NO_OF_VACANCIES'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='number'
                                        name='number_of_vacancies'
                                        className='form-control'
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.NO_OF_VACANCIES',
                                        })}
                                      />
                                      <ErrorMessage
                                        name='number_of_vacancies'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.number_of_vacancies}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.EXPERIENCE'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='number'
                                        name='experience'
                                        className='form-control'
                                        placeholder={intl.formatMessage({id: 'LABEL.EXPERIENCE'})}
                                      />
                                      <ErrorMessage
                                        name='experience'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.experience}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        as='select'
                                        name='country_id'
                                        className='form-select'
                                        onChange={(e) => {
                                          setCountryId(e.target.value)
                                          handleChange(e)
                                        }}
                                      >
                                        <option value=''>
                                          {' '}
                                          {intl.formatMessage({id: 'LABEL.SELECT_COUNTRY'})}
                                        </option>
                                        {countries?.map((each) => (
                                          <option key={each.country_id} value={each.country_id}>
                                            {each.country_name}
                                          </option>
                                        ))}
                                      </Field>

                                      <ErrorMessage
                                        name='country_id'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.country_name}
                                    </span>
                                  )}
                                </div>
                              </div>
                              {states?.length > 0 && (
                                <>
                                  <div className='row mb-4'>
                                    <label className='col-lg-4 fw-bold text-muted'>
                                      {intl.formatMessage({id: 'LABEL.STATE'})}
                                    </label>
                                    <div className='col-lg-8'>
                                      {isEditMode ? (
                                        <>
                                          <Field
                                            as='select'
                                            name='state_id'
                                            className='form-select'
                                            onChange={(e) => {
                                              setStateId(e.target.value)
                                              handleChange(e)
                                            }}
                                          >
                                            <option value=''>
                                              {' '}
                                              {intl.formatMessage({id: 'LABEL.SELECT_STATE'})}
                                            </option>
                                            {states?.map((each) => (
                                              <option key={each.state_id} value={each.state_id}>
                                                {each.state_name}
                                              </option>
                                            ))}
                                          </Field>
                                          <ErrorMessage
                                            name='state_id'
                                            component='div'
                                            className='text-danger'
                                          />
                                        </>
                                      ) : (
                                        <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                          {VacancyData?.state_name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                              {cities?.length > 0 && (
                                <>
                                  <div className='row mb-4'>
                                    <label className='col-lg-4 fw-bold text-muted'>
                                      {intl.formatMessage({id: 'LABEL.CITY'})}
                                    </label>
                                    <div className='col-lg-8'>
                                      {isEditMode ? (
                                        <>
                                          <Field as='select' name='city_id' className='form-select'>
                                            <option value=''>
                                              {' '}
                                              {intl.formatMessage({id: 'LABEL.SELECT_CITY'})}
                                            </option>
                                            {cities?.map((each) => (
                                              <option key={each.city_id} value={each.city_id}>
                                                {each.city_name}
                                              </option>
                                            ))}
                                          </Field>
                                          <ErrorMessage
                                            name='city_id'
                                            component='div'
                                            className='text-danger'
                                          />
                                        </>
                                      ) : (
                                        <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                          {VacancyData?.city_name}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.SERVICE_AGREEMENT'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='number'
                                        name='service_agreement'
                                        className='form-control'
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.SERVICE_AGREEMENT',
                                        })}
                                      />
                                      <ErrorMessage
                                        name='service_agreement'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.service_agreement}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.CURRENCY'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        as='select'
                                        id='floatingInput'
                                        className='form-select'
                                        name='currency_code'
                                        value={values.currency_code}
                                        onChange={handleChange}
                                      >
                                        <option value=''>
                                          {' '}
                                          {intl.formatMessage({id: 'LABEL.SELECT_CURRENCY'})}
                                        </option>

                                        {currencytype.map((currencyType) => (
                                          <option
                                            key={currencyType.currency_id}
                                            value={currencyType.currency_code}
                                          >
                                            {currencyType.currency_code}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name='currency_code'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.currency_code}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.MIN_SALARY'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='number'
                                        name='salary_start'
                                        className='form-control'
                                        placeholder={intl.formatMessage({id: 'LABEL.MIN_SALARY'})}
                                      />
                                      <ErrorMessage
                                        name='salary_start'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.salary_start}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.MAX_SALARY'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='number'
                                        name='salary_end'
                                        className='form-control'
                                        placeholder={intl.formatMessage({id: 'LABEL.MAX_SALARY'})}
                                      />
                                      <ErrorMessage
                                        name='salary_end'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.salary_end}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.EMPLOYEMENT_TYPE'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        as='select'
                                        id='floatingInput'
                                        className='form-select'
                                        name='employment_type'
                                        value={values.employment_type}
                                        onChange={handleChange}
                                      >
                                        <option value=''>
                                          {' '}
                                          {intl.formatMessage({id: 'LABEL.SELECT_EMPLOYMENT_TYPE'})}
                                        </option>

                                        {employmenttype.map((employmentType) => (
                                          <option
                                            key={employmentType.employment_type_id}
                                            value={employmentType.employment_type_name}
                                          >
                                            {employmentType.employment_type_name}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name='employment_type'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.employment_type}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.STATUS'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <div className='form-check form-check-solid form-switch'>
                                        <Field
                                          className='form-check-input w-40px h-20px'
                                          type='checkbox'
                                          id='toggle'
                                          name='vacancy_status'
                                          checked={values.vacancy_status}
                                          onChange={(e) =>
                                            setFieldValue('vacancy_status', e.target.checked)
                                          }
                                        />

                                        <span
                                          className={`me-4 fw-bold ${
                                            values.vacancy_status ? 'text-success' : 'text-danger'
                                          }`}
                                        >
                                          {values.vacancy_status ? 'Open' : 'Closed'}
                                        </span>
                                      </div>
                                      <ErrorMessage
                                        component='div'
                                        className='text-danger'
                                        name='vacancy_status'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {VacancyData?.vacancy_status}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer text-end' id='side_activities_footer'>
                    {isEditMode ? (
                      <>
                        {!isEditMode ? (
                          <button
                            className='btn btn-sm btn-secondary m-2'
                            type='button'
                            id='side_activities_close'
                            onClick={handleSaveClick}
                          >
                            {intl.formatMessage({id: 'BTN.CLOSE'})}
                          </button>
                        ) : (
                          <div className='btn btn-sm btn-secondary m-2' onClick={handleSaveClick}>
                            {intl.formatMessage({id: 'LABEL.CANCEL'})}
                          </div>
                        )}
                        <button
                          className='btn  btn-sm btn-primary'
                          type='submit'
                          disabled={!isEditMode}
                        >
                          {intl.formatMessage({id: 'BTN.SAVE'})}
                        </button>
                      </>
                    ) : (
                      <button
                        className='btn btn-sm btn-secondary m-2 mt-0'
                        type='button'
                        id='side_activities_close'
                        onClick={handleSaveClick}
                      >
                        {intl.formatMessage({id: 'BTN.CLOSE'})}
                      </button>
                    )}
                  </div>
                </Form>
              </>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {EditVacancy}
