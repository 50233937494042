import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import {useState} from 'react'
import {useIntl} from 'react-intl'
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import usePostApi from '../../custom_hooks/usePostApi'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../../custom_hooks/useApiCall'

const RaiseDataRequest = (props) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {data: requesttypeDetails} = useApiCall('/selfService/requestTypes')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const validationSchema = Yup.object().shape({
    requestType: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.REQUEST_TYPE_IS_REQUIRED'})}`
    ),
    requestDetails: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.REQUEST_DETAILS_IS_REQUIRED'})}`
    ),
    oldValue: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.OLD_VALUE_IS_REQUIRED'})}`),
    newValue: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.NEW_VALUE_IS_REQUIRED'})}`),
  })

  const initialValues = {
    requestType: '',
    requestDetails: '',
    oldValue: '',
    newValue: '',
  }

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    try {
      const organizationId = secureLocalStorage.getItem('organization_id')
      const url = `${baseUrl}/selfService/request`
      const selectedrequestType = requesttypeDetails.find(
        (requestType) => requestType.request_type === values.requestType
      )
      const formData = {
        organization_id: organizationId,
        request_type_id: selectedrequestType.request_type_id,
        hr_reviewed_status: 'Pending',
        request_details: values.requestDetails,
        request_status: 'HR Pending',
        old_value: values.oldValue,
        new_value: values.newValue,
      }

      await execute(url, 'POST', formData)
      props.props.fetchData()
      resetForm()

      Swal.fire({
        title: intl.formatMessage({id: 'MSG.REQUEST_RAISED_SUCCESSFULLY'}),
        text: intl.formatMessage({
          id: 'YOU_CAN_NOW_TRACK_THE_STATUS_OF_THE_REQUEST_IN_THE_MY_REQUESTS_SECTION',
        }),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('side_activities_close')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  const handleCancel = (resetForm, touched) => {
    if (Object.keys(touched).length === 0) {
      const sideDrawer = document.getElementById('side_activities_close')
      if (sideDrawer) {
        sideDrawer.click()
      }
    } else {
      resetForm()
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, resetForm, touched}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {intl.formatMessage({id: 'MENU.LABEL_RAISE_REQUEST'})}
                  </h3>
                  <div className='card-toolbar '>
                    <React.Fragment>
                      <div
                        className='btn btn-icon btn-sm btn-active-danger'
                        id='side_activities_close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </React.Fragment>
                  </div>
                </div>

                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative scroll-y me-n5 pe-5 w-450px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.SELECT_REQUEST_TYPE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='requestType'
                            value={values.requestType || ''}
                            onChange={(e) => setFieldValue('requestType', e.target.value)}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_REQUEST_TYPE'})}
                            </option>

                            {requesttypeDetails?.map((requestType) => (
                              <option
                                key={requestType.request_type_id}
                                value={requestType.request_type}
                              >
                                {requestType.request_type}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='requestType'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.REQUEST_DETAILS'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='requestDetails'
                            className='form-control form-control-solid'
                            value={values.requestDetails || ''}
                            onChange={(e) => setFieldValue('requestDetails', e.target.value)}
                            placeholder={intl.formatMessage({id: 'LABEL.REQUEST_DETAILS'})}
                          />
                          <ErrorMessage
                            name='requestDetails'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.OLD_VALUE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='oldValue'
                            className='form-control form-control-solid'
                            value={values.oldValue || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.OLD_VALUE'})}
                            onChange={(e) => setFieldValue('oldValue', e.target.value)}
                          />
                          <ErrorMessage name='oldValue' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.NEW_VALUE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='newValue'
                            className='form-control form-control-solid'
                            value={values.newValue || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.NEW_VALUE'})}
                            onChange={(e) => setFieldValue('newValue', e.target.value)}
                          />
                          <ErrorMessage name='newValue' component='div' className='text-danger' />
                        </div>
                      </div>
                    </div>
                    <div className='card-footer p-0 py-5 text-end'>
                      <button
                        type='button'
                        className='btn btn-light me-2 btn-sm'
                        onClick={() => handleCancel(resetForm, touched)}
                      >
                        {Object.keys(touched).length === 0
                          ? intl.formatMessage({id: 'BTN.CLOSE'})
                          : intl.formatMessage({id: 'BTN.CLEAR'})}
                      </button>
                      <button
                        type='submit'
                        className='btn btn-sm btn-light-primary ms-2'
                        onClick={() => {
                          setIsSubmitting(true)
                        }}
                      >
                        {intl.formatMessage({id: 'LABEL.RAISE_REQUEST'})}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default RaiseDataRequest
