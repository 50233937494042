import {KTIcon} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {useIntl} from 'react-intl'
import useApiCall from '../../custom_hooks/useApiCall'
import secureLocalStorage from 'react-secure-storage'
import {defaultProfileImage} from '../../core'
import {FormatDateToExperience} from '../../DateHelpers'

const ITAdminNav = () => {
  const location = useLocation()
  const intl = useIntl()
  let emp_id
  let role
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, role} = userInfo)
  }
  const {data, isLoading} = useApiCall('/employee/details', emp_id)
  const userData = data ? data[0] : null

  return (
    <>
      <div className='card mb-4 mb-xl-6 bg-warning p-4 d-flex align-items-center d-flex flex-row justify-content-start align-items-center'>
        <span className='pt-1'>
          <KTIcon iconName='information-2' className='fs-4 me-2' />
        </span>
        <span>You are now viewing this page as IT Admin.</span>
      </div>
      <div className='card mb-5 mb-xl-6'>
        <div className='card-body pt-8 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            <div className='me-4 mb-3'>
              <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
                <img
                  src={
                    userData?.employee_profile_pic
                      ? userData?.employee_profile_pic
                      : defaultProfileImage
                  }
                  alt='img'
                />
              </div>
            </div>

            {userData ? (
              <div className='flex-grow-1 d-flex flex-column justify-content-between'>
                <div className='d-flex justify-content-between align-items-start flex-wrap'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <p className='text-gray-800 text-hover-primary fs-2 fw-bolder m-0 me-1'>
                        {`${userData?.first_name}${
                          userData?.middle_name ? ' ' + userData?.middle_name : ''
                        } ${userData?.last_name}`}
                      </p>
                      <p className='m-0'></p>
                    </div>

                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                      <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2'>
                        <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                        {userData?.designation_name}
                      </p>
                      <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                        <KTIcon iconName='geolocation' className='fs-4 me-1' />
                        {userData?.location_name}
                      </p>
                      <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2'>
                        <KTIcon iconName='sms' className='fs-4 me-1' />
                        {userData?.email}
                      </p>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap flex-stack'>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>
                      <div className='border border-gray-300 border-dashed rounded min-w-100px py-2 px-3 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <div className='fs-5 fw-bolder'>
                            {userData?.hire_date
                              ? FormatDateToExperience(userData?.hire_date)
                              : '-'}
                          </div>
                        </div>

                        <div className='fs-7 text-muted fw-bold'>Experience</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='m-auto d-flex flex-column align-items-center'>
                {isLoading ? (
                  <div className='spinner-border spinner-primary mr-15'></div>
                ) : (
                  <h3>{intl.formatMessage({id: 'NO_DATA_FOUND'})}</h3>
                )}
              </div>
            )}
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  to='/it-admin/assets'
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/it-admin/assets' && 'active')
                  }
                >
                  {intl.formatMessage({id: 'LABEL.ASSET_ISSUANCE'})}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default ITAdminNav
