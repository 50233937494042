import {lazy, FC, Suspense, ComponentType} from 'react'
import {Route, Routes, Navigate, RouteProps} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import * as MetronicI18n from '../../_metronic/i18n/Metronici18n'
import OrganizationRoutes from '../modules/organization_config/OrganizationRoutes'
import {
  GetBrandPreference,
  GetFontPreferences,
  GetLogoPreferences,
  GetThemePreferences,
  GetWorkPreferences,
} from '../modules/organization_config/OrganizationAPI'
import ExpenseAdvancesMain from '../modules/expense_advances/ExpenseAdvancesRoute'
import RecruitmentRoutes from '../modules/recruitment/RecruitmentRoutes'
import PayrollRoutes from '../modules/payroll_management/PayrollRoutes'
import TimeRoutes from '../modules/time_attendance/TimeRoutes'
import LeaveRoutes from '../modules/leave_management/LeaveRoutes'
import SelfServiceRoutes from '../modules/employee_selfservice/SelfServiceRoutes'
import AssetManagementRoutes from '../modules/asset_management/AssetManagementRoutes'
import TravelRoutes from '../modules/travel/TravelRoutes'
import PerformanceRoutes from '../modules/performance_management/PerformanceRoutes'
import secureLocalStorage from 'react-secure-storage'
import AllAssetRouting from '../modules/all_assets/AllAssetRouting'
import TravelDeskNav from '../modules/workflows/traveldesk_workflow/TravelDeskNav'
import TravelDeskRoutes from '../modules/workflows/traveldesk_workflow/TravelDeskRoutes'
import ITAdminRoutes from '../modules/workflows/itadmin_workflow/ITAdminWFRoutes'
import {registerLocale} from 'react-datepicker'
import es from 'date-fns/locale/es'
import ar from 'date-fns/locale/ar'
import fr from 'date-fns/locale/fr'

interface ProtectedRouteProps {
  element: React.ReactElement
}

function calculateHoverColor(originalColor: any) {
  const modifiedColor = originalColor.replace(/^#/, '')
  const r = parseInt(modifiedColor.substr(0, 2), 16)
  const g = parseInt(modifiedColor.substr(2, 2), 16)
  const b = parseInt(modifiedColor.substr(4, 2), 16)
  const modifiedR = Math.min(r + Math.round(r * 0.2), 255)
  const modifiedG = Math.min(g + Math.round(g * 0.2), 255)
  const modifiedB = Math.min(b + Math.round(b * 0.2), 255)
  const hoverColor = `#${modifiedR.toString(16).padStart(2, '0')}${modifiedG
    .toString(16)
    .padStart(2, '0')}${modifiedB.toString(16).padStart(2, '0')}`

  return hoverColor
}

function hexToRgb(hex: any) {
  hex = hex.replace(/^#/, '')
  const bigint = parseInt(hex, 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255
  return r + ', ' + g + ', ' + b
}

function updateThemeColors(primaryBrandColor: any) {
  const root = document.documentElement
  root.style.setProperty('--bs-primary', primaryBrandColor.code)
  root.style.setProperty('--bs-primary-rgb', hexToRgb(primaryBrandColor.code))
  root.style.setProperty('--bs-text-primary', primaryBrandColor.code)
  root.style.setProperty('--bs-primary-active', calculateHoverColor(primaryBrandColor.code))
  root.style.setProperty('--bs-component-active-bg', primaryBrandColor.code)
  root.style.setProperty('--bs-component-hover-color', primaryBrandColor.code)
  root.style.setProperty('--bs-menu-link-color-active', primaryBrandColor.code)
  root.style.setProperty('--bs-scrolltop-bg-color', primaryBrandColor.code)
}

const PrivateRoutes = () => {
  registerLocale('es', es)
  registerLocale('ar', ar)
  registerLocale('fr', fr)

  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {is_hr, is_admin, is_itadmin, is_manager, is_finance} = userInfo || {}

  const selectedLang = MetronicI18n.getLanguage()
  const {colorData} = GetThemePreferences()
  const {fontPreferences} = GetFontPreferences()
  const {brandlogosData} = GetLogoPreferences()
  const {dateFormat, displayName, welcomeMessage} = GetBrandPreference()
  const {workPrefereceData} = GetWorkPreferences()
  const WeekFormats: Record<string, string> = {
    Sunday: '0',
    Monday: '1',
    Tuesday: '2',
    Wednesday: '3',
    Thursday: '4',
    Friday: '5',
    Saturday: '6',
  }

  if (workPrefereceData?.week_start_date) {
    const weekStartValue = WeekFormats[workPrefereceData.week_start_date]
    if (weekStartValue !== undefined) {
      localStorage.setItem('weekStart', weekStartValue)
    } else {
      localStorage.setItem('weekStart', '0')
    }
  } else {
    localStorage.setItem('weekStart', '0')
  }

  if (dateFormat && displayName && welcomeMessage) {
    localStorage.setItem('dateFormat', dateFormat)
    localStorage.setItem('displayName', displayName)
    localStorage.setItem('welcomeMessage', welcomeMessage)
  } else {
    localStorage.setItem('dateFormat', 'dd/MM/yyyy')
    localStorage.setItem('displayName', 'KLOUDWORX')
    localStorage.setItem('welcomeMessage', 'Welcome to Kloudworx')
  }

  if (fontPreferences) {
    const FontStyle = fontPreferences.find((font: any) => font.locale_code === selectedLang)
    if (FontStyle?.default_font) {
      const selectedFont = FontStyle.default_font || 'Inter'
      localStorage.setItem('themeFont', selectedFont)
      const root = document.documentElement
      root.style.setProperty('--bs-font-sans-serif', "'" + selectedFont + "'")
      const fontLinkElement = document.getElementById('gfont-import-link') as HTMLAnchorElement
      if (fontLinkElement) {
        const fontURL =
          'https://fonts.googleapis.com/css?family=' + selectedFont.replaceAll(' ', '+')
        fontLinkElement.href = fontURL
      }
    }
  } else {
    localStorage.setItem('themeFont', 'Inter')
  }

  const timestamp = Date.now()
  const faviconLink = document.querySelector("link[rel='shortcut icon']") as HTMLLinkElement
  const brandLogos = {
    brand_logo_url: brandlogosData?.brand_logo_url,
    brand_header_image_url: brandlogosData?.brand_header_image_url,
    brand_favicon_url: brandlogosData?.brand_favicon_url,
  }
  secureLocalStorage.setItem('brand_logos', brandLogos)
  localStorage.setItem('brand_header_image_url', brandlogosData?.brand_header_image_url)
  const updatedFaviconUrl = brandlogosData?.brand_favicon_url || ''
  if (faviconLink && updatedFaviconUrl) {
    const updatedFaviconUrlWithTimestamp = `${updatedFaviconUrl}?timestamp=${timestamp}`
    faviconLink.href = updatedFaviconUrlWithTimestamp
  }
  if (colorData) {
    const ThemeMode = localStorage.getItem('kt_theme_mode_value') || 'light'
    const primaryBrandColor = colorData.find((color) => color.id === 1)
    const primaryDarkMode = colorData.find((color) => color.id === 3)
    if (primaryBrandColor?.code && ThemeMode === 'light') {
      localStorage.setItem('themeColor', primaryBrandColor.code)
      updateThemeColors(primaryBrandColor)
    } else if (primaryDarkMode?.code && ThemeMode === 'dark') {
      localStorage.setItem('themeColor', primaryDarkMode.code)
      updateThemeColors(primaryDarkMode)
    }
  }

  const MyInfo = lazy(() => import('../modules/employee_details/EmpInfoRoutes'))
  const HrConfigurationRoutes = lazy(
    () => import('../modules/hr_configuration/HrConfigurationRoutes')
  )
  const ExitSeparationRoutes = lazy(
    () => import('../modules/exit_and_separation/ExitSeparationRoutes')
  )
  const AllEmployees = lazy(() => import('../modules/all_employees/AllEmpRouting'))

  const CreateNewEmployee = lazy(
    () => import('../modules/all_employees/create_new_emp/CreateNewEmployee')
  )

  const ManagerWorkflow = lazy(
    () => import('../modules/workflows/manager_workflow/ManagerWFRoutes')
  )
  const HRWorkFlow = lazy(() => import('../modules/workflows/hr_workflow/HRWorkflowRoutes'))
  const FinanceWorkFlow = lazy(
    () => import('../modules/workflows/finance_workflow/FinanceWFRoutes')
  )

  const AdminRoutes = lazy(() => import('../modules/employee_details/admin_modules/AdminRoutes'))

  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({element}) => {
    return is_hr === 1 || is_itadmin === 1 || is_admin === 1 ? (
      element
    ) : (
      <Navigate to='/employees/my-info/information' replace />
    )
  }

  const AdminHRProtectedRoute: React.FC<ProtectedRouteProps> = ({element}) => {
    return is_hr === 1 || is_admin === 1 ? (
      element
    ) : (
      <Navigate to='/employees/my-info/information' replace />
    )
  }

  const ManagerProtectedRoute: React.FC<ProtectedRouteProps> = ({element}) => {
    return is_manager === 1 ? element : <Navigate to='/employees/my-info/information' replace />
  }

  const HrProtectedRoute: React.FC<ProtectedRouteProps> = ({element}) => {
    return is_hr === 1 ? element : <Navigate to='/employees/my-info/information' replace />
  }

  const FinanceProtectedRoute: React.FC<ProtectedRouteProps> = ({element}) => {
    return is_finance === 1 ? element : <Navigate to='/employees/my-info/information' replace />
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/employees/my-info/information' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route
          path='/employees/my-info/*'
          element={
            <>
              <SuspensedView>
                <MyInfo />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='/organization-configuration/*'
          element={
            <SuspensedView>
              <OrganizationRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='/hr-configuration/*'
          element={
            <SuspensedView>
              <HrConfigurationRoutes />
            </SuspensedView>
          }
        />

        <Route
          path='/hr/employee-management'
          element={
            <ProtectedRoute
              element={
                <SuspensedView>
                  <AllEmployees />
                </SuspensedView>
              }
            />
          }
        />

        <Route
          path='/hr-config-admin/create-new-employee'
          element={
            <AdminHRProtectedRoute
              element={
                <SuspensedView>
                  <CreateNewEmployee />
                </SuspensedView>
              }
            />
          }
        />
        {/* <Route
          path='/exit-and-separation/*'
          element={
            <SuspensedView>
              <ExitSeparationRoutes />
            </SuspensedView>
          }
        /> */}

        <Route
          path='/admin/emp-info/:name/:id'
          element={
            <AdminHRProtectedRoute
              element={
                <SuspensedView>
                  <AdminRoutes />
                </SuspensedView>
              }
            />
          }
        />
        <Route
          path='/expenses-advances/*'
          element={
            <SuspensedView>
              <ExpenseAdvancesMain />
            </SuspensedView>
          }
        />

        <Route
          path='/recruitment/*'
          element={
            <>
              <SuspensedView>
                <RecruitmentRoutes />
              </SuspensedView>
            </>
          }
        />
        {/* <Route
          path='/payroll-management/*'
          element={
            <>
              <SuspensedView>
                <PayrollRoutes />
              </SuspensedView>
            </>
          }
        /> */}
        <Route
          path='/time-and-attendance/*'
          element={
            <>
              <SuspensedView>
                <TimeRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='/leave-management/*'
          element={
            <>
              <SuspensedView>
                <LeaveRoutes />
              </SuspensedView>
            </>
          }
        />

        <Route
          path='/employee-self-service/*'
          element={
            <>
              <SuspensedView>
                <SelfServiceRoutes />
              </SuspensedView>
            </>
          }
        />

        <Route
          path='/asset-management/*'
          element={
            <>
              <SuspensedView>
                <AssetManagementRoutes />
              </SuspensedView>
            </>
          }
        />

        <Route
          path='/travel/*'
          element={
            <>
              <SuspensedView>
                <TravelRoutes />
              </SuspensedView>
            </>
          }
        />

        <Route
          path='/performance-management/*'
          element={
            <>
              <SuspensedView>
                <PerformanceRoutes />
              </SuspensedView>
            </>
          }
        />

        <Route
          path='/manager-workflow/*'
          element={
            <ManagerProtectedRoute
              element={
                <>
                  <SuspensedView>
                    <ManagerWorkflow />
                  </SuspensedView>
                </>
              }
            />
          }
        />
        <Route
          path='/hr-workflow/*'
          element={
            <HrProtectedRoute
              element={
                <>
                  <SuspensedView>
                    <HRWorkFlow />
                  </SuspensedView>
                </>
              }
            />
          }
        />
        <Route
          path='/financer-workflow/*'
          element={
            <FinanceProtectedRoute
              element={
                <>
                  <SuspensedView>
                    <FinanceWorkFlow />
                  </SuspensedView>
                </>
              }
            />
          }
        />
        <Route
          path='/traveldesk-workflow/*'
          element={
            <>
              <SuspensedView>
                <TravelDeskRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route
          path='/it-admin/*'
          element={
            <>
              <SuspensedView>
                <ITAdminRoutes />
              </SuspensedView>
            </>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
