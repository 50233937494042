import React, {useState, useEffect} from 'react'

import {useIntl} from 'react-intl'
import MyLeavesCalender from './MyLeavesCalendar'
import MyLeavesTable from './MyLeavesTable'
import AddLeaveDrawer from './AddLeaveDrawer'
import secureLocalStorage from 'react-secure-storage'
import {KTIcon} from '../../../../_metronic/helpers'
import usePageTitle from '../../custom_hooks/usePageTitle'
import useApiCall from '../../custom_hooks/useApiCall'
export default function MyLeavesMain() {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const intl = useIntl()
  const {data, isLoading, fetchData} = useApiCall(`/leave/emp/${emp_id}`)
  usePageTitle(intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.MY_LEAVES'}))
  return (
    <>
      {isLoading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : data && data.length > 0 ? (
        <>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <div className='fw-bolder my-2'>
              <h3 className='fw-bolder my-2 default-cursor'>
                {intl.formatMessage({id: 'MENU.LEAVE_MANAGEMENT.MY_LEAVES'})}
              </h3>
            </div>

            <div className='fw-bolder my-2'>
              <button className='btn btn-sm btn-light-primary' id='applyleave'>
                <KTIcon iconName='plus' className='fs-3' />
                {intl.formatMessage({id: 'BTN.APPLY_LEAVE'})}
              </button>
            </div>
          </div>
          <div className='row g-5 g-xl-8 d-flex justify-content-between'>
            <div className='col-lg-6 col-xl-6'>
              {isLoading ? (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : data && data.length > 0 ? (
                <div className='card p-4'>
                  <MyLeavesTable data={data} fetchData={fetchData} />
                </div>
              ) : (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center default-cursor'>
                    <h1 className='mb-5'>{intl.formatMessage({id: 'MSG.NO_LEAVES_FOUND'})}</h1>
                    <button className='btn btn-sm btn-light-primary' id='applyleave'>
                      <KTIcon iconName='plus' className='fs-3' />
                      {intl.formatMessage({id: 'BTN.APPLY_LEAVE'})}
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className='col-lg-6 col-xl-6'>
              {isLoading ? (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : data && data.length > 0 ? (
                <div className='card p-4'>
                  <MyLeavesCalender data={data} />
                </div>
              ) : (
                <div className='card h-250px'>
                  <div className='m-auto d-flex flex-column align-items-center default-cursor'>
                    <h1 className='mb-5'>{intl.formatMessage({id: 'MSG.NO_LEAVES_FOUND'})}</h1>
                    <button className='btn btn-sm btn-light-primary' id='applyleave'>
                      <KTIcon iconName='plus' className='fs-3' />
                      {intl.formatMessage({id: 'BTN.APPLY_LEAVE'})}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center default-cursor'>
            <h1 className='mb-5'>{intl.formatMessage({id: 'MSG.NO_LEAVES_FOUND'})}</h1>
            <button className='btn btn-sm btn-light-primary' id='applyleave'>
              <KTIcon iconName='plus' className='fs-3' />
              {intl.formatMessage({id: 'BTN.APPLY_LEAVE'})}
            </button>
          </div>
        </div>
      )}
      <AddLeaveDrawer updateLeaveFunc={fetchData} />
    </>
  )
}
