import clsx from 'clsx'
import {KTIcon} from '../../../helpers'
import {ThemeModeComponent} from '../../../assets/ts/layout'
import {ThemeModeType, useThemeMode} from './ThemeModeProvider'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../../../app/modules/core'
import * as MetronicI18n from '../../../i18n/Metronici18n'
import usePostApi from '../../../../app/modules/custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'

const ThemeModeSwitcher = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'fs-1',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const intl = useIntl()
  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()
  const calculatedMode = mode === 'system' ? systemMode : mode

  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode)
    updateMode(_mode)
  }

  const selectedLang = MetronicI18n.getLanguage()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {organization_id, user_id} = userInfo || {}
  const updateThemeMode = async (_mode: ThemeModeType) => {
    const url = `${baseUrl}/pref/user/${user_id}`
    try {
      const requestData = {
        dir: selectedLang === 'ar' ? 'rtl' : 'ltr',
        lang: selectedLang,
        theme_mode: _mode,
        organization_id: organization_id,
      }
      await execute(url, 'PATCH', requestData, id)
      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href='#'
        className={clsx('btn btn-icon ', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        {calculatedMode === 'dark' && (
          <KTIcon iconName='moon' className={clsx('theme-light-hide', toggleBtnIconClass)} />
        )}

        {calculatedMode === 'light' && (
          <KTIcon iconName='night-day' className={clsx('theme-dark-hide', toggleBtnIconClass)} />
        )}
      </a>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: menuMode === 'light'})}
            onClick={() => {
              switchMode('light')
              updateThemeMode('light')
            }}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTIcon iconName='night-day' className='fs-1' />
            </span>
            <span className='menu-title'>{intl.formatMessage({id: 'LABEL.LIGHT'})}</span>
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: menuMode === 'dark'})}
            onClick={() => {
              switchMode('dark')
              updateThemeMode('dark')
            }}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTIcon iconName='moon' className='fs-1' />
            </span>
            <span className='menu-title'>{intl.formatMessage({id: 'LABEL.DARK'})}</span>
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', {active: menuMode === 'system'})}
            onClick={() => {
              switchMode('system')
              updateThemeMode('system')
            }}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTIcon iconName='screen' className='fs-1' />
            </span>
            <span className='menu-title'>{intl.formatMessage({id: 'LABEL.SYSTEM'})}</span>
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {ThemeModeSwitcher}
