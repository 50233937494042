import {AddExpenseType} from '../../../../app/modules/hr_configuration/hr_config_expense_types/AddExpenseType'
import {RaiseRequest} from '../../../../app/modules/exit_and_separation/RaiseRequest'
import {AddChecklist} from '../../../../app/modules/hr_configuration/hr_config_checklist/AddChecklist'
import {AddJobRole} from '../../../../app/modules/hr_configuration/hr_config_job_roles/AddJobRole'
import {AddSkillSet} from '../../../../app/modules/hr_configuration/hr_config_skillsets/AddSkillSet'
import {FontPreview} from '../../../../app/modules/organization_config/organization_preferences/preferences_cards/font_preferences/FontPreview'
import {EditAsset} from '../../../../app/modules/all_assets/EditAsset'
import {AddBusinessUnit} from '../../../../app/modules/organization_config/business_Units/AddBusinessUnit'
import {EditBusinessUnit} from '../../../../app/modules/organization_config/business_Units/EditBusinessUnit'
import {AddDepartment} from '../../../../app/modules/organization_config/organization_departments/AddDepartment'
import {EditDepartment} from '../../../../app/modules/organization_config/organization_departments/EditDepartment'
import {AddLocation} from '../../../../app/modules/organization_config/organization_locations/AddLocation'
import {EditLocation} from '../../../../app/modules/organization_config/organization_locations/EditLocation'
import {EditJobRole} from '../../../../app/modules/hr_configuration/hr_config_job_roles/EditJobRole'
import {EditSkillSet} from '../../../../app/modules/hr_configuration/hr_config_skillsets/EditSkillSet'
import {EditExpenseType} from '../../../../app/modules/hr_configuration/hr_config_expense_types/EditExpenseType'
import TeamRequestsDrawer from '../../../../app/modules/employee_selfservice/self_service/TeamRequestsDrawer'
import RaiseDataRequest from '../../../../app/modules/employee_selfservice/self_service/RaiseDataRequest'
import TeamReviewsDrawer from '../../../../app/modules/performance_management/team_reviews/TeamReviewsDrawer'
import {EditChecklist} from '../../../../app/modules/hr_configuration/hr_config_checklist/EditChecklist'
import {EditVacancy} from '../../../../app/modules/recruitment/vacancies/EditVacancy'
import TeamTimeSheetDrawer from '../../../../app/modules/time_attendance/team_timesheet/team_timetable/TeamTimeSheetDrawer'
import InterviewsDrawer from '../../../../app/modules/recruitment/interviews/InterviewsDrawer'
import AddVacancy from '../../../../app/modules/recruitment/vacancies/AddVacancy'
import CreateTimeSheet from '../../../../app/modules/time_attendance/my_timesheet/CreateTimesheet'
import RaiseTravelRequest from '../../../../app/modules/travel/my_trips/RaiseTravelRequest'
import TeamTripsDrawer from '../../../../app/modules/travel/team_trips/TeamTripsDrawer'
import LeaveApprovalDrawer from '../../../../app/modules/leave_management/leave_approvals/LeaveApprovalDrawer'
import CandidateDrawer from '../../../../app/modules/recruitment/candidates/CandidateDrawer'
import AddAsset from '../../../../app/modules/all_assets/AddAsset'
import AddLeaveTypes from '../../../../app/modules/leave_management/leave_types/AddLeaveTypes'
import {EditEmployeeExit} from '../../../../app/modules/exit_and_separation/employee_exit/EditEmployeeExit'
import AddInputs from '../../../../app/modules/performance_management/my_inputs/AddInputs'
import IssueAsset from '../../../../app/modules/all_assets/IssueAsset'
import RequestAsset from '../../../../app/modules/all_assets/RequestAsset'
import UpdateAssetDrawer from '../../../../app/modules/asset_management/issuance/UpdateAssetDrawer'
import IssueOffer from '../../../../app/modules/recruitment/candidates/IssueOffer'
import {AddProject} from '../../../../app/modules/hr_configuration/hr_config_projects/AddProject'
import ScheduleInterview from '../../../../app/modules/recruitment/interviews/ScheduleInterview'
import {EditProject} from '../../../../app/modules/hr_configuration/hr_config_projects/EditProject'
import ViewTeamTrips from '../../../../app/modules/travel/team_trips/ViewTeamTrips'
import AddRoleKpi from '../../../../app/modules/performance_management/assigning_kpi/AddRoleKpi'
import {AddLeaveType} from '../../../../app/modules/hr_configuration/hr_config_leave_types/AddLeaveType'
import {EditLeaveType} from '../../../../app/modules/hr_configuration/hr_config_leave_types/EditLeaveType'
const SideDrawer = (props) => {
  const {value} = props
  const sideDrawerMethod = () => {
    if (value && value.type) {
      switch (value.type) {
        case 'add_Business_unit':
          return <AddBusinessUnit props={value} />
        case 'edit_Business_unit':
          return <EditBusinessUnit props={value} />
        case 'add_Department':
          return <AddDepartment props={value} />
        case 'edit_Department':
          return <EditDepartment props={value} />
        case 'add_Location':
          return <AddLocation props={value} />
        case 'edit_Location':
          return <EditLocation props={value} />
        case 'add_jobrole_type':
          return <AddJobRole />
        case 'edit_jobrole_type':
          return <EditJobRole props={value} />
        case 'add_skillset_type':
          return <AddSkillSet />
        case 'edit_skillset_type':
          return <EditSkillSet props={value} />
        case 'add_expense_type':
          return <AddExpenseType />
        case 'edit_expense_type':
          return <EditExpenseType props={value} />
        case 'fontpreview':
          return <FontPreview props={value} />
        case 'fontpreview':
          return <FontPreview props={value} />
        case 'add_checklist':
          return <AddChecklist props={value} />
        case 'add_exit_request':
          return <RaiseRequest props={value} />
        case 'edit_checklist':
          return <EditChecklist props={value} />
        case 'edit_Leaves':
          return <LeaveApprovalDrawer props={value} />
        case 'raise_request':
          return <RaiseDataRequest props={value} />
        case 'edit_employee_exit':
          return <EditEmployeeExit props={value} />
        case 'raise_travel_request':
          return <RaiseTravelRequest props={value} />
        case 'edit_vacancies':
          return <EditVacancy props={value} />
        case 'edit_Timesheets':
          return <TeamTimeSheetDrawer props={value} />
        case 'edit_interviews':
          return <InterviewsDrawer props={value} />
        case 'add_asset':
          return <AddAsset />
        case 'add_kpi':
          return <AddRoleKpi props={value} />
        case 'add_epi':
          return <AddInputs props={value} />
        case 'add_vacancy':
          return <AddVacancy props={value} />
        case 'add_timesheet':
          return <CreateTimeSheet props={value} />
        case 'edit_Requests':
          return <TeamRequestsDrawer props={value} />
        case 'edit_TravelRequests':
          return <TeamTripsDrawer props={value} />
        case 'add_candidate':
          return <CandidateDrawer props={value} />
        case 'team_reviews':
          return <TeamReviewsDrawer props={value} />
        case 'edit_asset':
          return <EditAsset props={value} />
        case 'edit_candidates':
          return <IssueOffer props={value} />
        case 'issue_asset':
          return <IssueAsset props={value} />
        case 'request_asset':
          return <RequestAsset props={value} />
        case 'update_asset':
          return <UpdateAssetDrawer props={value} />
        case 'add_project':
          return <AddProject props={value} />
        case 'edit_project':
          return <EditProject props={value} />
        case 'schedule_interview':
          return <ScheduleInterview props={value} />
        case 'view_TravelRequests':
          return <ViewTeamTrips props={value} />
        // case 'create_leave_type':
        //   return <AddLeaveTypes />
        case 'add_leave_type':
          return <AddLeaveType props={value} />
        case 'edit_leave_type':
          return <EditLeaveType props={value} />
        default:
          return <TeamTripsDrawer props={value} />
      }
    }
    return null
  }
  return (
    <div
      id='side_activities'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='side-activities'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#side_activities_toggle'
      data-kt-drawer-close='#side_activities_close'
    >
      {sideDrawerMethod()}
    </div>
  )
}
export {SideDrawer}
