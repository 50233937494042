import React from 'react'

import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'

import {Dropdown1} from '../../../_metronic/partials'
import {useLocation} from 'react-router'
import {useIntl} from 'react-intl'
import secureLocalStorage from 'react-secure-storage'
import {defaultProfileImage} from '../core'
import {GetAllApprovedTimesheets} from './TimeAPI'
import {GetAllTimesheets} from './TimeAPI'
import useApiCall from '../custom_hooks/useApiCall'
import {GetAllApprovedEmpTimesheets} from './TimeAPI'
import {GetAllEmptimesheets} from './TimeAPI'

const TimeNavBar = () => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const location = useLocation()
  const intl = useIntl()

  const {data, isLoading} = useApiCall('/employee/details', emp_id)
  const userData = data ? data[0] : null

  const {timesheetsData} = GetAllTimesheets()
  const {approvedtimesheets} = GetAllApprovedTimesheets()
  const {alltimesheetData} = GetAllEmptimesheets()
  const {allapprovedtimesheet} = GetAllApprovedEmpTimesheets()

  return (
    <div className='card mb-4 mb-xl-6'>
      <div className='card-body pt-8 pb-0'>
        {location.pathname === '/time-and-attendance/my-timesheets' ? (
          <>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='me-4 mb-3'>
                <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative default-cursor'>
                  <img
                    src={
                      userData?.employee_profile_pic
                        ? userData?.employee_profile_pic
                        : defaultProfileImage
                    }
                    alt='img'
                  />
                </div>
              </div>
              {userData ? (
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <p className='text-gray-800 text-hover-primary fs-2 fw-bolder  me-1 default-cursor mb-0'>
                          {`${userData?.first_name}${
                            userData?.middle_name ? ' ' + userData?.middle_name : ''
                          } ${userData?.last_name}`}
                        </p>
                      </div>

                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-0'>
                        <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2 default-cursor'>
                          <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                          {userData?.designation_name}
                        </p>
                        <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2 default-cursor'>
                          <KTIcon iconName='geolocation' className='fs-4 me-1' />
                          {userData?.location_name}
                        </p>
                        <p className='d-flex align-items-center text-gray-400 text-hover-primary   mb-2 default-cursor'>
                          <KTIcon iconName='sms' className='fs-4 me-1' />
                          {userData?.email}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                      <div className='d-flex flex-wrap'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>
                              {timesheetsData?.length > 0 ? timesheetsData?.length : 0}
                            </div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>My Timesheets</div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>
                              {approvedtimesheets?.length > 0 ? approvedtimesheets?.length : 0}
                            </div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>Approved Timesheets</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='m-auto d-flex flex-column align-items-center'>
                  {isLoading ? (
                    <div className='spinner-border spinner-primary mr-15'></div>
                  ) : (
                    <h3>No Data</h3>
                  )}
                </div>
              )}
            </div>

            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item nav-item-links'>
                  <Link
                    className={
                      `nav-link text-active-primary text-grey me-6 ` +
                      (location.pathname === '/time-and-attendance/my-timesheets' && 'active')
                    }
                    to='/time-and-attendance/my-timesheets'
                  >
                    {intl.formatMessage({id: 'LABEL.TIME_SHEET.SUB_ITEM1'})}
                  </Link>
                </li>
                {/* <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary  text-grey me-6 ` +
                      (location.pathname === '/time-and-attendance/team-timesheet' && 'active')
                    }
                    to='/time-and-attendance/team-timesheet'
                  >
                    {intl.formatMessage({id: 'LABEL.TIME_SHEET.SUB_ITEM2'})}
                  </Link>
                </li> */}
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='me-4 mb-3'>
                <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative default-cursor'>
                  <img
                    src={
                      userData?.employee_profile_pic
                        ? userData?.employee_profile_pic
                        : defaultProfileImage
                    }
                    alt='img'
                  />
                </div>
              </div>
              {userData ? (
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <p className='text-gray-800 text-hover-primary fs-2 fw-bolder  me-1 default-cursor mb-0'>
                          {`${userData?.first_name}${
                            userData?.middle_name ? ' ' + userData?.middle_name : ''
                          } ${userData?.last_name}`}
                        </p>
                      </div>

                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-0'>
                        <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2 default-cursor'>
                          <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                          {userData?.designation_name}
                        </p>
                        <p className='d-flex align-items-center text-gray-400 text-hover-primary  me-5 mb-2 default-cursor'>
                          <KTIcon iconName='geolocation' className='fs-4 me-1' />
                          {userData?.location_name}
                        </p>
                        <p className='d-flex align-items-center text-gray-400 text-hover-primary   mb-2 default-cursor'>
                          <KTIcon iconName='sms' className='fs-4 me-1' />
                          {userData?.email}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                      <div className='d-flex flex-wrap'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>
                              {alltimesheetData?.length > 0 ? alltimesheetData?.length : 0}
                            </div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>Team Timesheets</div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>
                              {allapprovedtimesheet?.length > 0 ? allapprovedtimesheet?.length : 0}
                            </div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>Approved Timesheets</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='m-auto d-flex flex-column align-items-center'>
                  {isLoading ? (
                    <div className='spinner-border spinner-primary mr-15'></div>
                  ) : (
                    <h3>No Data</h3>
                  )}
                </div>
              )}
            </div>
            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item nav-item-links'>
                  <Link
                    className={
                      `nav-link text-active-primary text-grey me-6 ` +
                      (location.pathname === '/time-and-attendance/my-timesheets' && 'active')
                    }
                    to='/time-and-attendance/my-timesheets'
                  >
                    {intl.formatMessage({id: 'LABEL.TIME_SHEET.SUB_ITEM1'})}
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary  text-grey me-6 ` +
                      (location.pathname === '/time-and-attendance/team-timesheet' && 'active')
                    }
                    to='/time-and-attendance/team-timesheet'
                  >
                    {intl.formatMessage({id: 'LABEL.TIME_SHEET.SUB_ITEM2'})}
                  </Link>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default TimeNavBar
