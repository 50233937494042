import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'

import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'

import secureLocalStorage from 'react-secure-storage'

import usePageTitle from '../../custom_hooks/usePageTitle'
import ManagerWFContext from '../../workflows/manager_workflow/ManagerWFContext'
import {DateRange} from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import classnames from 'classnames'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import {useThemeMode} from '../../../../_metronic/partials'
import 'react-datepicker/dist/react-datepicker.css'
import DateFormatter from '../../DateFormatter'
import useApiCall from '../../custom_hooks/useApiCall'
import React from 'react'
import {defaultProfileImage} from '../../core'
const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [currentStatus, setCurrentStatus] = useState('Pending')
  const lang = useLang()
  const [locale, setLocale] = React.useState('en')
  const [startDateHolder, setstartDateHolder] = useState('')
  const [endDateHolder, setendDateHolder] = useState('')
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ])
  const [showDateRange, setShowDateRange] = useState(false)
  const {mode} = useThemeMode()
  const [darkMode, setDarkMode] = useState(false)
  useEffect(() => {
    setDarkMode(mode !== 'light')
  }, [mode])

  useEffect(() => {
    if (lang === 'ar') {
      setLocale('arSA')
    } else {
      setLocale(lang)
    }
  }, [lang])

  const handleDatesChange = () => {
    setStartDate(null)
    setEndDate(null)
    setState([
      {
        startDate: new Date(),
        endDate: null,
        key: 'selection',
      },
    ])
  }

  const handleToggleDateRange = () => {
    setShowDateRange(!showDateRange)
  }

  const handleApplyDateRange = () => {
    setStartDate(startDateHolder)
    setEndDate(endDateHolder)
    setShowDateRange(false)
  }

  const calendarClasses = classnames('date-range-picker', {
    dark: darkMode,
    light: !darkMode,
  })

  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.LEAVE_APPROVALS'}))
  // Filter data based on input value
  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const leaveTypeMatches =
            each.leave_type_name &&
            each.leave_type_name.toLowerCase().includes(inputVal.toLowerCase())
          const firstNameMatches =
            each.first_name && each.first_name.toLowerCase().includes(inputVal.toLowerCase())

          const leaveStatus =
            each.status !== 'Approved' && each.status !== 'Rejected' ? 'Pending' : each.status
          const currentStatusMatches =
            leaveStatus && leaveStatus.toLowerCase() === currentStatus.toLowerCase()

          return leaveTypeMatches && currentStatusMatches && firstNameMatches
        })
      : []
  }, [inputVal, data, currentStatus])

  const filteredDataByDate = useMemo(() => {
    if (!startDate || !endDate) {
      return filteredData // No date filtering if startDate or endDate is not set
    }

    // Ensure that startDate and endDate are valid Date objects
    if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
      return [] // Return an empty array or handle the invalid date case as needed
    }

    startDate.setTime(startDate.getTime() + 5.5 * 60 * 60 * 1000)
    endDate.setTime(endDate.getTime() + 5.5 * 60 * 60 * 1000)

    return filteredData.filter((leave) => {
      const leaveStartDate = new Date(leave.start_date)
      const leaveEndDate = new Date(leave.end_date)

      return leaveStartDate >= startDate && leaveEndDate <= endDate
    })
  }, [startDate, endDate, filteredData])

  const tableInstance = useTable({columns, data: filteredDataByDate}, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [5, 10, 20, 30, 40, 50]

  // Render the component
  return (
    <ManagerWFContext.Consumer>
      {(value) => {
        const {ExpInfo, updateExpInfo} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='card'>
                  <>
                    <div className='card-header border-0 pt-5'>
                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='fw-bolder'>
                          <ul className='nav'>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                                data-bs-toggle='tab'
                                onClick={() => setCurrentStatus('Pending')}
                              >
                                {intl.formatMessage({id: 'LABEL.PENDING'})}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                onClick={() => setCurrentStatus('Approved')}
                              >
                                {intl.formatMessage({id: 'LABEL.APPROVED'})}
                              </a>
                            </li>

                            <li className='nav-item'>
                              <a
                                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                                data-bs-toggle='tab'
                                onClick={() => setCurrentStatus('Rejected')}
                              >
                                {intl.formatMessage({id: 'LABEL.REJECTED'})}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className='card-toolbar'>
                        <div className='d-flex align-items-center position-relative me-4'>
                          <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                          <input
                            type='search'
                            id='kt_filter_search'
                            className='form-control form-control-white form-control-sm w-150px ps-9'
                            placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                            value={inputVal}
                            onChange={(e) => setInputVal(e.target.value)}
                          />
                        </div>
                        <div className=''>
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary  me-2'
                            onClick={() => {
                              handleToggleDateRange()
                            }}
                          >
                            <KTIcon iconName='calendar' className='fs-2 m-0' />
                          </button>

                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary  me-2'
                            onClick={handleDatesChange}
                          >
                            <KTIcon iconName='setting-2' className='fs-2 m-0' />
                          </button>
                        </div>
                        {showDateRange && (
                          <div style={{position: 'absolute', right: '125px', zIndex: '9'}}>
                            <div
                              className={`card d-flex flex-wrap  mb-6 ${
                                darkMode ? 'dark-mode' : ''
                              }`}
                            >
                              <DateRange
                                editableDateInputs={true}
                                onChange={(item) => {
                                  setState([item.selection])
                                  setstartDateHolder(item.selection.startDate)
                                  setendDateHolder(item.selection.endDate)
                                }}
                                moveRangeOnFirstSelection={false}
                                ranges={state}
                                locale={locales[locale]}
                                className={calendarClasses}
                              />
                              <div
                                className='d-flex justify-content-end'
                                style={{position: 'absolute', bottom: '10px', right: '10px'}}
                              >
                                <button
                                  className='btn btn-primary btn-sm'
                                  onClick={() => {
                                    handleApplyDateRange()
                                  }}
                                >
                                  {intl.formatMessage({id: 'LABEL.OK'})}
                                </button>
                              </div>
                            </div>
                            <style>
                              {`
              .dark-mode {
                background-color: #333;
                color: #fff;
              }
              `}
                            </style>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='tab-content p-2 pt-0'>
                      <div className='card-body tab-pane fade show active table-responsive'>
                        <table
                          className='table table-row-dashed table-row-gray-300 align-middle'
                          {...getTableProps()}
                        >
                          <thead>
                            <tr className='fw-bold text-muted'>
                              <th className='min-w-140px p-5'>
                                {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                              </th>
                              <th className='min-w-140px text-start  default-cursor'>
                                {intl.formatMessage({id: 'LABEL.LEAVE_TYPE'})}
                              </th>
                              <th className='min-w-120px text-start  default-cursor'>
                                {intl.formatMessage({id: 'EMP.TRAININGS.START_DATE'})}
                              </th>
                              <th className='min-w-120px text-start  default-cursor'>
                                {intl.formatMessage({id: 'EMP.TRAININGS.END_DATE'})}
                              </th>
                              <th className='min-w-140px text-start default-cursor'>
                                {intl.formatMessage({id: 'LABEL.NO_OF_DAYS'})}
                              </th>

                              <th className='min-w-50px text-center default-cursor'>
                                {intl.formatMessage({id: 'MENU.ACTIONS'})}
                              </th>
                            </tr>
                          </thead>

                          <tbody {...getTableBodyProps()}>
                            {page && page.length > 0 ? (
                              page.map((row) => {
                                prepareRow(row)
                                return (
                                  <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                      <td className='text-center' {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })
                            ) : (
                              <tr>
                                <td>
                                  <p></p>
                                </td>
                                <td className='card'>
                                  <p className='text-dark fw-bold text-hover-primary d-block fs-6 m-0 my-5'>
                                    {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                  </p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>

                        {filteredData.length > 10 && (
                          <Pagination>
                            <Pagination.First
                              onClick={() => gotoPage(0)}
                              disabled={!canPreviousPage}
                            />
                            <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                            {[...Array(pageCount)].map((_, i) => (
                              <Pagination.Item
                                key={i}
                                active={i === pageIndex}
                                onClick={() => gotoPage(i)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            ))}

                            <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                            <Pagination.Last
                              onClick={() => gotoPage(pageCount - 1)}
                              disabled={!canNextPage}
                            />

                            <div className='d-flex align-items-center mx-5'>
                              <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                              <select
                                className='form-select form-select-solid fw-bold w-75px me-2'
                                value={pageSize}
                                onChange={(e) => setPageSize(Number(e.target.value))}
                              >
                                {pageOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Pagination>
                        )}
                      </div>
                    </div>
                  </>
                </div>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1>{intl.formatMessage({id: 'MSG.NO_LEAVES_FOUND'})}</h1>
                </div>
              </div>
            )}
            <SideDrawer value={ExpInfo} />
          </>
        )
      }}
    </ManagerWFContext.Consumer>
  )
}

const LeaveApprovalTable = () => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }

  const {
    data: filteredDataByDate,
    isLoading,
    fetchData,
  } = useApiCall(emp_id ? `/leave/teamLeaves/${emp_id}` : null)

  const columns = useMemo(
    () => [
      {
        Header: 'Employee Name',
        accessor: 'employee_name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-45px me-5'>
                <img
                  src={
                    row.original.employee_profile_pic
                      ? row.original.employee_profile_pic
                      : defaultProfileImage
                  }
                  alt='img'
                />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <p className='text-dark fw-bold text-hover-primary default-cursor fs-6 m-0'>
                  {row.original.first_name} {row.original.middle_name} {row.original.last_name}
                </p>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-start'>
                  {row.original.employee_code}
                </span>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Leave Type',
        accessor: 'Leave Type',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.leave_type_name}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'Start Date',
        accessor: 'Start Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.start_date.slice(0, 10))}
              </p>
            </div>
          )
        },
      },

      {
        Header: 'End Date',
        accessor: 'End Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.end_date.slice(0, 10))}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'No of Days',
        accessor: 'No of Days',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.no_of_days}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <ManagerWFContext.Consumer>
              {(value) => {
                const {updateExpInfo} = value
                const hrReviewedStatus = row.original.status

                return (
                  <div className='d-flex justify-content-center'>
                    {hrReviewedStatus === 'Manager Pending' ? (
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateExpInfo({
                            ...row.original,
                            type: 'edit_Leaves',
                            fetchData,
                          })
                        }}
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </div>
                    ) : (
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateExpInfo({
                            ...row.original,
                            type: 'edit_Leaves',
                            // for getting sidedrawer
                          })
                        }}
                      >
                        <KTIcon iconName='eye' className='fs-3' />
                      </div>
                    )}
                  </div>
                )
              }}
            </ManagerWFContext.Consumer>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={filteredDataByDate ? filteredDataByDate : []}
      loading={isLoading}
    />
  )
}

export default LeaveApprovalTable
