import {useState, useEffect} from 'react'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../core'
import usePostApi from '../../custom_hooks/usePostApi'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

function InterviewHR({hrDetails, hrID}) {
  const {execute} = usePostApi()
  const {
    feedback = ' ',
    status = null,

    fetchData = () => {},
  } = hrDetails

  let organization_id
  let emp_id
  let is_hr
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, organization_id, is_hr} = userInfo)
  }

  const intl = useIntl()

  const [hrComment, setHrComment] = useState('')
  const [comError, setComError] = useState(false)
  const [comLenErr, setComLenErr] = useState(false)

  useEffect(() => {
    setHrComment('')

    setComError(false)
    setComLenErr(false)
  }, [])

  useEffect(() => {
    if (hrComment !== '') {
      setComError(false)
    }
    if (hrComment.length >= 3) {
      setComLenErr(false)
    }
  }, [hrComment])

  const approveAndRejectBtns = async (event) => {
    if (hrComment === '') {
      setComError(true)
      return
    } else if (hrComment.length < 3) {
      setComLenErr(true)
      return
    }
    try {
      const url = `${baseUrl}/recruitment/interview/${hrID}`

      const formData = {
        organization_id: organization_id,

        feedback: hrComment,
        status: event.target.name,
      }

      await execute(url, 'PATCH', formData)
      fetchData()
      setHrComment('')
      // Determine the success message based on the request_status
      const successMessage =
        event.target.name === 'Accepted'
          ? intl.formatMessage({id: 'MSG.INTERVIEW_ACCEPTED_SUCCESSFULLY'})
          : intl.formatMessage({id: 'MSG.INTERVIEW_REJECTED_SUCCESSFULLY'})
      await Swal.fire({
        title: successMessage, // Use the successMessage variable
        text: intl.formatMessage({id: 'MSG.YOU_CAN_TRACK_THE_STATUS_IN_INTERVIEWS_SECTION'}),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error('Error:', error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }

  const getHrView = (hrStatus) => {
    if (hrStatus === 'Pending' && is_hr === 1) {
      return (
        <div className='min-h-26px'>
          <label>{intl.formatMessage({id: 'LABEL.FEEDBACK'})}</label>
          <br />
          <input
            type='text'
            placeholder={intl.formatMessage({id: 'LABEL.FEEDBACK'})}
            className='form-control mb-3 form-control-solid'
            value={hrComment}
            onChange={(e) => setHrComment(e.target.value)} // Update the local state managerComment
          />
          <div>
            <button
              className='btn btn-light-success btn-sm py-2'
              name='Accepted'
              onClick={approveAndRejectBtns}
              type='submit'
              // id='side_activities_close'
            >
              {intl.formatMessage({id: 'LABEL.APPROVE'})}
            </button>
            <button
              className='btn btn-light-danger btn-sm ms-2 py-2'
              name='Rejected'
              onClick={approveAndRejectBtns}
              type='submit'
              // id='side_activities_close'
            >
              {intl.formatMessage({id: 'LABEL.REJECT'})}
            </button>
          </div>
        </div>
      )
    } else if (hrStatus === 'Accepted' || hrStatus === 'Rejected') {
      return (
        <>
          {/* <p className='m-0 text-muted'>{status}</p> */}
          <p className='m-0 text-muted'>{feedback}</p>
        </>
      )
    } else {
      return null
    }
  }

  const getStatusMsg = (hrStatus) => {
    if (hrStatus === 'Pending') {
      return 'Pending'
    } else if (hrStatus === 'Rejected') {
      return 'Rejected'
    } else if (hrStatus === 'Accepted') {
      return status
    } else {
      return hrStatus
    }
  }

  const getStatusColor = (hrStatus) => {
    if (hrStatus === 'Pending') {
      return 'muted'
    } else if (hrStatus === 'Accepted') {
      return 'success'
    } else {
      return 'danger'
    }
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-20px'>
        <div
          className={`symbol-label bg-${getStatusColor(
            status
          )} icon-dotted-border text-${getStatusColor(status)}`}
        ></div>
      </div>

      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p className={`mb-0 fw-bold text-${getStatusColor(status)}`}>{getStatusMsg(status)}</p>
          <div className=''>
            <p className='designation-btn bg-secondary fs-7 m-auto'>
              {intl.formatMessage({id: 'LABEL.HR'})}
            </p>
          </div>
        </div>
        {getHrView(status)}
      </div>
    </div>
  )
}

export default InterviewHR
