import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import es from 'date-fns/locale/es'
import ar from 'date-fns/locale/ar'
import fr from 'date-fns/locale/fr'

import {registerLocale} from 'react-datepicker'
import Swal from 'sweetalert2'
import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import React from 'react'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {KTSVG} from '../../../_metronic/helpers'
import usePostApi from '../custom_hooks/usePostApi'
import {baseUrl} from '../core'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../custom_hooks/useApiCall'
import DatePicker from 'react-datepicker'
import '../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../_metronic/partials'
import {DateBasicFormat} from '../DateHelpers'

const AddAsset = ({}) => {
  const intl = useIntl()
  const lang = useLang()
  const {execute} = usePostApi()
  const {data: assettypeDetails} = useApiCall('/hrConfig/assetTypes')
  const {data: countries} = useApiCall('/country')
  const {mode} = useThemeMode()
  const {data: currencytype} = useApiCall('/currencyTypes')
  const [locale, setLocale] = React.useState('en')

  const [isSubmitting, setIsSubmitting] = useState(false)
  useEffect(() => {
    if (lang === 'ar') {
      setLocale('ar')
    } else {
      setLocale(lang)
    }
  })

  registerLocale('es', es)
  registerLocale('ar', ar)
  registerLocale('fr', fr)

  const validationSchema = Yup.object().shape({
    assetType: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.ASSET_TYPE_IS_REQUIRED'})}`
    ),
    brand: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.BRAND_IS_REQUIRED'})}`),
    make: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.MAKE_IS_REQUIRED'})}`),
    model: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.MODEL_IS_REQUIRED'})}`),
    serial_number: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.SERIAL_NUMBER_IS_REQUIRED'})}`
    ),
    pr_no: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.PURCHASE_NUMBER_IS_REQUIRED'})}`
    ),
    pr_date: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.PURCHASE_DATE_IS_REQUIRED'})}`
    ),
    po_no: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.PURCHASE_ORDER_IS_REQUIRED'})}`
    ),
    po_date: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.ORDER_DATE_IS_REQUIRED'})}`),
    po_amount: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.AMOUNT_IS_REQUIRED'})}`),
    unit_rate: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.UNIT_RATE_IS_REQUIRED'})}`
    ),
    supplier: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.SUPPLIER_IS_REQUIRED'})}`),
    working_status: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.STATUS_IS_REQUIRED'})}`
    ),
    salvage_value: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.SALVAGE_VALUE_IS_REQUIRED'})}`
    ),
    depreciation_percentage: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.DEPRICIATION_IS_REQUIRED'})}`
    ),
    asset_lifespan: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.ASSET_LIFE_SPAN_IS_REQUIRED'})}`
    ),
    asset_tag: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.ASSET_TAG_IS_REQUIRED'})}`
    ),
    first_installation_date: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.DATE_IS_REQUIRED'})}`
    ),
    warranty_start_date: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.WARRANTY_START_DATE_IS_REQUIRED'})}`
    ),
    warranty_expiry_date: Yup.string().when(
      'warranty_start_date',
      (warranty_start_date, schema) => {
        return schema
          .test(
            'warranty_expiry_date',
            intl.formatMessage({id: 'ERR.MSG.EXPIRY_DATE_CANNOT_BE_BEFORE_START_DATE'}),
            function (value) {
              return (
                !warranty_start_date || !value || new Date(value) >= new Date(warranty_start_date)
              )
            }
          )
          .required(`${intl.formatMessage({id: 'ERR.MSG.WARRANTY_EXPIRY_DATE_IS_REQUIRED'})}`)
      }
    ),
    currency_id: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.CURRENCY_IS_REQUIRED'})}`
    ),
    country_of_manufacture: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.COUNTRY_OF_MANUFACTURE_IS_REQUIRED'})}`
    ),
  })

  const initialValues = {
    assetType: '',
    currency_name: '',
    brand: '',
    make: '',
    model: '',
    country_name: '',
    serial_number: '',
    pr_no: '',
    pr_date: '',
    po_amount: '',
    unit_rate: '',
    po_no: '',
    po_date: '',
    supplier: '',
    working_status: '',
    salvage_value: '',
    depreciation_percentage: '',
    asset_lifespan: '',
    asset_tag: '',
    first_installation_date: '',
    in_stock: '',
    warranty_start_date: '',
    warranty_expiry_date: '',
    countryName: '',
    currency_id: '',
    country_of_manufacture: '',
  }

  const handleSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    try {
      const organizationId = secureLocalStorage.getItem('organization_id')
      const url = `${baseUrl}/assetManagement/assets`
      const selectedassetType = assettypeDetails.find(
        (assetType) => assetType.asset_type_name === values.assetType
      )
      const selectedCountry = countries.find(
        (countryName) => countryName.country_name === values.countryName
      )
      const formData = {
        organization_id: organizationId,
        asset_type_id: selectedassetType.asset_type_id,
        brand: values.brand,
        make: values.make,
        model: values.model,
        country_of_manufacture: parseInt(values.country_of_manufacture),
        serial_number: values.serial_number,
        pr_no: values.pr_no,
        pr_date: DateBasicFormat(values.pr_date),
        po_amount: parseFloat(values.po_amount + '.01'),
        unit_rate: parseFloat(values.unit_rate + '.01'),
        po_no: values.po_no,
        po_date: DateBasicFormat(values.po_date),
        supplier: values.supplier,
        working_status: values.working_status,
        salvage_value: parseFloat(values.salvage_value + '.01'),
        depreciation_percentage: parseFloat(values.depreciation_percentage + '.01'),
        asset_lifespan: parseInt(values.asset_lifespan),
        asset_tag: values.asset_tag,
        first_installation_date: DateBasicFormat(values.first_installation_date),
        in_stock: 1,
        warranty_start_date: DateBasicFormat(values.warranty_start_date),
        warranty_expiry_date: DateBasicFormat(values.warranty_expiry_date),
        currency_id: parseInt(values.currency_id),
      }

      await execute(url, 'POST', formData)
      resetForm()

      Swal.fire({
        title: 'Asset Added Successfuully',
        text: 'You can now track the status of the Asset in the “All Assets” section..',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('side_activities_close')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  const handleCancel = (resetForm, touched) => {
    if (Object.keys(touched).length === 0) {
      const sideDrawer = document.getElementById('side_activities_close')
      if (sideDrawer) {
        sideDrawer.click()
      }
    } else {
      resetForm()
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue, resetForm, touched}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {' '}
                    {intl.formatMessage({id: 'LABEL.ADD_ASSET'})}
                  </h3>
                  <div className='card-toolbar '>
                    <React.Fragment>
                      <div
                        className='btn btn-icon btn-sm btn-active-danger'
                        id='side_activities_close'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon svg-icon-2x'
                        />
                      </div>
                    </React.Fragment>
                  </div>
                </div>

                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative scroll-y me-n5 pe-5 w-450px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {' '}
                          {intl.formatMessage({id: 'LABEL.ASSET_TYPE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='assetType'
                            value={values.assetType || ''}
                            onChange={(e) => setFieldValue('assetType', e.target.value)}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_ASSET_TYPE'})}
                            </option>

                            {assettypeDetails?.map((assetType) => (
                              <option
                                key={assetType.asset_type_id}
                                value={assetType.asset_type_name}
                              >
                                {assetType.asset_type_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage name='assetType' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='brand'
                            className='form-control form-control-solid'
                            value={values.brand || ''}
                            onChange={(e) => setFieldValue('brand', e.target.value)}
                            placeholder={intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                          />
                          <ErrorMessage name='brand' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {' '}
                          {intl.formatMessage({id: 'LABEL.ASSET_MAKE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='make'
                            className='form-control form-control-solid'
                            value={values.make || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.ASSET_MAKE'})}
                            onChange={(e) => setFieldValue('make', e.target.value)}
                          />
                          <ErrorMessage name='make' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {' '}
                          {intl.formatMessage({id: 'LABEL.MODEL'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='model'
                            className='form-control form-control-solid'
                            value={values.model || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.MODEL'})}
                            onChange={(e) => setFieldValue('model', e.target.value)}
                          />
                          <ErrorMessage name='model' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='country_of_manufacture'
                            className='form-select form-select-solid'
                            value={values.country_of_manufacture || ''}
                            onChange={(e) =>
                              setFieldValue('country_of_manufacture', e.target.value)
                            }
                            placeholder={intl.formatMessage({id: 'LABEL.SELECT_COUNTRY'})}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_COUNTRY'})}
                            </option>
                            {countries?.map((countryName) => (
                              <option key={countryName.country_id} value={countryName.country_id}>
                                {countryName.country_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='country_of_manufacture'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.CURRENCY'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            className='form-select form-select-solid'
                            id='floatingInput'
                            name='currency_id'
                            value={values.currency_id}
                            onChange={(e) => setFieldValue('currency_id', e.target.value)}
                          >
                            <option key='' value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_CURRENCY'})}
                            </option>

                            {currencytype?.map((currencyType) => (
                              <option
                                key={currencyType.currency_id}
                                value={currencyType.currency_id}
                              >
                                {currencyType.currency_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='currency_id'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.SERIAL_NUMBER'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='serial_number'
                            className='form-control form-control-solid'
                            value={values.serial_number || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.SERIAL_NUMBER'})}
                            onChange={(e) => setFieldValue('serial_number', e.target.value)}
                          />
                          <ErrorMessage
                            name='serial_number'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.PURCHASE_NUMBER'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='pr_no'
                            className='form-control form-control-solid'
                            // value={values.pr_no || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.PURCHASE_NUMBER'})}
                            // onChange={(e) => setFieldValue('pr_no', e.target.value)}
                          />
                          <ErrorMessage name='pr_no' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.PURCHASE_DATE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field name='pr_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('pr_date', date)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({id: 'LABEL.SELECT_DATE'})}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    locale={locale}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage name='pr_date' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.PURCHASE_AMOUNT'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='po_amount'
                            className='form-control form-control-solid'
                            value={values.po_amount || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.SELECT_AMOUNT'})}
                            onChange={(e) => setFieldValue('po_amount', e.target.value)}
                          />
                          <ErrorMessage name='po_amount' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.UNIT_RATE'})}{' '}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='unit_rate'
                            className='form-control form-control-solid'
                            value={values.unit_rate || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.UNIT_RATE'})}
                            onChange={(e) => setFieldValue('unit_rate', e.target.value)}
                          />
                          <ErrorMessage name='unit_rate' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.PURCHASE_ORDER'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='po_no'
                            className='form-control form-control-solid'
                            value={values.po_no || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.PURCHASE_ORDER'})}
                            onChange={(e) => setFieldValue('po_no', e.target.value)}
                          />
                          <ErrorMessage name='po_no' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {' '}
                          {intl.formatMessage({id: 'LABEL.ORDER_DATE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field name='po_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('po_date', date)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({id: 'LABEL.SELECT_DATE'})}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    locale={locale}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage name='po_date' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.SUPPLIER'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='supplier'
                            className='form-control form-control-solid'
                            value={values.supplier || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.SUPPLIER'})}
                            onChange={(e) => setFieldValue('supplier', e.target.value)}
                          />
                          <ErrorMessage name='supplier' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.WORKING_STATUS'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='working_status'
                            className='form-control form-control-solid'
                            value={values.working_status || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.WORKING_STATUS'})}
                            onChange={(e) => setFieldValue('working_status', e.target.value)}
                          />
                          <ErrorMessage
                            name='working_status'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.SALVAGE_VALUE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='salvage_value'
                            className='form-control form-control-solid'
                            value={values.salvage_value || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.SALVAGE_VALUE'})}
                            onChange={(e) => setFieldValue('salvage_value', e.target.value)}
                          />
                          <ErrorMessage
                            name='salvage_value'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.DEPRECIATION_PERCENTAGE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='depreciation_percentage'
                            className='form-control form-control-solid'
                            value={values.depreciation_percentage || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.DEPRECIATION_PERCENTAGE'})}
                            onChange={(e) =>
                              setFieldValue('depreciation_percentage', e.target.value)
                            }
                          />
                          <ErrorMessage
                            name='depreciation_percentage'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.ASSET_LIFE_SPAN'})}(in yrs)
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='asset_lifespan'
                            className='form-control form-control-solid'
                            value={values.asset_lifespan || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.ASSET_LIFE_SPAN'})}
                            onChange={(e) => setFieldValue('asset_lifespan', e.target.value)}
                          />
                          <ErrorMessage
                            name='asset_lifespan'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.ASSET_TAG_ID'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field
                            type='text'
                            name='asset_tag'
                            className='form-control form-control-solid'
                            value={values.asset_tag || ''}
                            placeholder={intl.formatMessage({id: 'LABEL.ASSET_TAG_ID'})}
                            onChange={(e) => setFieldValue('asset_tag', e.target.value)}
                          />
                          <ErrorMessage name='asset_tag' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.INSTALLATION_DATE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field name='first_installation_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('first_installation_date', date)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({id: 'LABEL.SELECT_DATE'})}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    locale={locale}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage
                            name='first_installation_date'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.WARRANTY_START_DATE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field name='warranty_start_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('warranty_start_date', date)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({id: 'LABEL.START_DATE'})}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    locale={locale}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage
                            name='warranty_start_date'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.WARRANTY_END_DATE'})}
                        </label>
                        <div className='col-lg-8 text-start'>
                          <Field name='warranty_expiry_date'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('warranty_expiry_date', date)
                              }

                              let minDate = null
                              if (values && values.warranty_start_date) {
                                const startDate = new Date(values.warranty_start_date)
                                minDate = startDate.setDate(startDate.getDate() + 1)
                              }

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({id: 'LABEL.END_DATE'})}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    minDate={minDate}
                                    locale={locale}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage
                            name='warranty_expiry_date'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='card-footer p-0 py-5 text-end'>
                      <button
                        type='button'
                        className='btn btn-light me-2 btn-sm'
                        onClick={() => handleCancel(resetForm, touched)}
                      >
                        {Object.keys(touched).length === 0
                          ? intl.formatMessage({id: 'BTN.CLOSE'})
                          : intl.formatMessage({id: 'BTN.CLEAR'})}
                      </button>
                      <button
                        type='submit'
                        className='btn btn-sm btn-light-primary ms-2'
                        onClick={() => {
                          setIsSubmitting(true)
                        }}
                      >
                        {intl.formatMessage({id: 'LABEL.ADD'})}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default AddAsset
