import {FC, useEffect, useRef, useState} from 'react'
import {SearchComponent} from '../../../assets/ts/components'
import {KTIcon} from '../../../helpers'
import {useIntl} from 'react-intl'

const Search: FC = () => {
  const intl = useIntl()
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)

  const processs = (search: SearchComponent) => {
    setTimeout(function () {
      const inputElement = element.current?.querySelector(
        "input[name='search']"
      ) as HTMLInputElement // type assertion to specify it's an input element
      const searchValue = inputElement?.value.toLowerCase() // get the search value

      if (searchValue) {
        const filteredModules = moduleNames.filter(
          (module) =>
            module.enName.toLowerCase().includes(searchValue) ||
            module.arName.toLowerCase().includes(searchValue) ||
            module.esName.toLowerCase().includes(searchValue) ||
            module.frName.toLowerCase().includes(searchValue) // filter the modules based on the search value
        )

        suggestionsElement.current!.classList.add('d-none') // Hide recently viewed

        if (filteredModules.length === 0) {
          // Hide results
          resultsElement.current!.classList.add('d-none')
          // Show empty message
          emptyElement.current!.classList.remove('d-none')
        } else {
          // Show results
          resultsElement.current!.classList.remove('d-none')
          // Hide empty message
          emptyElement.current!.classList.add('d-none')

          // Update the resultsElement with the filtered module names
          const resultsDiv = resultsElement.current!.querySelector('.scroll-y')
          if (resultsDiv) {
            resultsDiv.innerHTML = '' // Clear previous results
            filteredModules.forEach((each) => {
              const moduleDiv = document.createElement('div')
              moduleDiv.className = 'd-flex align-items-center mb-5'
              const symbolDiv = document.createElement('div')
              symbolDiv.className = 'symbol symbol-40px me-4'
              const symbolLabel = document.createElement('span')
              symbolLabel.className = 'symbol-label w-10px h-10px rounded-circle bg-light'
              symbolDiv.appendChild(symbolLabel)
              moduleDiv.appendChild(symbolDiv)
              const flexDiv = document.createElement('div')
              flexDiv.className = 'd-flex flex-column'
              const link = document.createElement('a')
              link.className = 'fs-6 text-gray-800 text-hover-primary fw-bold'
              link.href = each.url
              link.textContent = intl.formatMessage({id: each.name})
              flexDiv.appendChild(link)
              moduleDiv.appendChild(flexDiv)
              resultsDiv.appendChild(moduleDiv)
            })
          }
        }
      }
    }, 500)
  }

  const clear = (search: SearchComponent) => {
    // Show recently viewed
    suggestionsElement.current!.classList.remove('d-none')
    // Hide results
    resultsElement.current!.classList.add('d-none')
    // Hide empty message
    emptyElement.current!.classList.add('d-none')
  }

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search')

    // Search handler
    searchObject!.on('kt.search.process', processs)

    // Clear handler
    searchObject!.on('kt.search.clear', clear)
  }, [])

  const moduleNames = [
    {
      name: 'LABEL.MY_INFORMATION',
      enName: 'My Information',
      arName: 'معلوماتي',
      esName: 'Mi Información',
      frName: 'Mes Informations',
      url: '/employees/my-info/information',
    },
    {
      name: 'MENU.EMPLOYEE_SELF_SERVICE.SELF_SERVICE_REQUEST',
      enName: 'Data Correction Request',
      arName: 'طلب تصحيح البيانات',
      esName: 'Solicitud de Corrección de Datos',
      frName: 'Demande de Correction des Données',
      url: '/employee-self-service/self-service-request',
    },
    {
      name: 'MENU.RECRUITMENT',
      enName: 'Recruitment',
      arName: 'التوظيف',
      esName: 'Reclutamiento',
      frName: 'Recrutement',
      url: '/recruitment/vacancies',
    },
    {
      name: 'MENU.LEAVE_MANAGEMENT',
      enName: 'Leave Management',
      arName: 'إدارة الإجازات',
      esName: 'Gestión de Ausencias',
      frName: 'Gestion des Congés',
      url: '/leave-management/my-leaves',
    },
    {
      name: 'MENU.EXPENSES_ADVANCES',
      enName: 'Expenses & Advances',
      arName: 'سلف المصاريف',
      esName: 'Adelantos de Gastos',
      frName: 'Avances de Frais',
      url: '/expenses-advances/my-expense-claims',
    },
    {
      name: 'MENU.ASSET_MANAGEMENT',
      enName: 'Asset Management',
      arName: 'إدارة الأصول',
      esName: 'Gestión de Activos',
      frName: 'Gestion des Actifs',
      url: '/asset-management/my-assets',
    },
    {
      name: 'MENU.TRAVEL',
      enName: 'Travel',
      arName: 'السفر',
      esName: 'Viajes',
      frName: 'Voyage',
      url: '/travel/my-travel',
    },
    {
      name: 'LABEL.TIME&ATTENDENCE',
      enName: 'Time and Attendence',
      arName: 'الوقت والحضور',
      esName: 'Tiempo y Asistencia',
      frName: 'Temps et Présence',
      url: '/time-and-attendance/my-timesheets',
    },
    {
      name: 'LABEL.ALL_EMPLOYEES',
      enName: 'All Employees',
      arName: 'جميع الموظفين',
      esName: 'Todos los Empleados',
      frName: 'Tous les Employés',
      url: '/hr/employee-management',
    },
  ]

  return (
    <>
      <div
        id='kt_header_search'
        className='d-flex align-items-stretch'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
            <KTIcon iconName='magnifier' className='fs-1' />
          </div>
        </div>

        <div
          data-kt-search-element='content'
          className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px'
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <form
              data-kt-search-element='form'
              className='w-100 position-relative mb-3'
              autoComplete='off'
            >
              <KTIcon
                iconName='magnifier'
                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-0'
              />

              <input
                type='text'
                className='form-control form-control-flush ps-10'
                name='search'
                placeholder={intl.formatMessage({id: 'ECOMMERCE.COMMON.SEARCH'})}
                data-kt-search-element='input'
              />

              <span
                className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-1'
                data-kt-search-element='spinner'
              >
                <span className='spinner-border h-15px w-15px align-middle text-gray-400' />
              </span>

              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTIcon iconName='cross' className='fs-2 text-lg-1 me-0' />
              </span>
            </form>

            <div ref={resultsElement} data-kt-search-element='results' className='d-none'>
              <div className='scroll-y mh-200px mh-lg-350px'>
                {/* <h3 className='fs-5 text-muted m-0 pb-5' data-kt-search-element='category-title'>
                  Users
                </h3> */}
                {moduleNames.map((each) => (
                  <div className='d-flex align-items-center mb-5' key={each.name}>
                    <div className='symbol symbol-40px me-4'>
                      <span className='symbol-label w-10px h-10px rounded-circle bg-light'></span>
                    </div>

                    <div className='d-flex flex-column'>
                      <a href={each.url} className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                        {/* {each.name} */}
                        {intl.formatMessage({id: each.name})}
                      </a>
                    </div>
                  </div>
                ))}

                {/* <h3
                  className='fs-5 text-muted m-0 pt-5 pb-5'
                  data-kt-search-element='category-title'
                >
                  Customers
                </h3>

                <a
                  href='/#'
                  className='d-flex text-dark text-hover-primary align-items-center mb-5'
                >
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <img
                        className='w-20px h-20px'
                        src={toAbsoluteUrl('/media/svg/brand-logos/volicity-9.svg')}
                        alt=''
                      />
                    </span>
                  </div>

                  <div className='d-flex flex-column justify-content-start fw-bold'>
                    <span className='fs-6 fw-bold'>Company Rbranding</span>
                    <span className='fs-7 fw-bold text-muted'>UI Design</span>
                  </div>
                </a> */}
              </div>
            </div>

            <div ref={suggestionsElement} className='mb-4' data-kt-search-element='main'>
              <div className='d-flex flex-stack fw-bold mb-4'>
                <span className='text-muted fs-6 me-2'>
                  {intl.formatMessage({id: 'LABEL.RECENTLY_SEARCHED'})}
                </span>
              </div>

              <div className='scroll-y mh-200px mh-lg-325px'>
                {moduleNames.slice(0, 3).map((each) => (
                  <div className='d-flex align-items-center mb-5' key={each.name}>
                    <div className='symbol symbol-40px me-4'>
                      <span className='symbol-label w-10px h-10px rounded-circle bg-light'></span>
                    </div>

                    <div className='d-flex flex-column'>
                      <a href={each.url} className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                        {intl.formatMessage({id: each.name})}
                      </a>
                    </div>
                  </div>
                ))}

                {/* <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTIcon iconName='tree' className='fs-2 text-primary' />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a
                      href='/expenses-advances/my-expense-claims'
                      className='fs-6 text-gray-800 text-hover-primary fw-bold'
                    >
                      Expenses & Advances
                    </a>
                  </div>
                </div>
                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTIcon iconName='cup' className='fs-2 text-primary' />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a
                      href='/leave-management/my-leaves'
                      className='fs-6 text-gray-800 text-hover-primary fw-bold'
                    >
                      Leave Info
                    </a>
                  </div>
                </div> */}
              </div>
            </div>

            <div ref={emptyElement} data-kt-search-element='empty' className='text-center d-none'>
              <div className='pt-10 pb-10'>
                <KTIcon iconName='search-list' className='fs-4x opacity-50' />
              </div>

              <div className='pb-15 fw-bold'>
                <h3 className='text-gray-600 fs-5 mb-2'>
                  {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                </h3>
                <div className='text-muted fs-7'>
                  {intl.formatMessage({id: 'ERR.MSG.TRY_AGAIN_DIFFERENT_QUERY'})}
                </div>
              </div>
            </div>
          </div>

          <form className={`pt-1 ${menuState === 'advanced' ? '' : 'd-none'}`}>
            <h3 className='fw-bold text-dark mb-7'>Advanced Search</h3>

            <div className='mb-5'>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid'
                placeholder='Contains the word'
                name='query'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='type'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    All
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='users' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Users
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='orders' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Orders
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='projects' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Projects
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='assignedto'
                className='form-control form-control-sm form-control-solid'
                placeholder='Assigned to'
              />
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='collaborators'
                className='form-control form-control-sm form-control-solid'
                placeholder='Collaborators'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='attachment'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    Has attachment
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='attachment' value='any' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Any
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <select
                name='timezone'
                aria-label='Select a Timezone'
                data-control='select2'
                data-placeholder='date_period'
                className='form-select form-select-sm form-select-solid'
              >
                <option value='next'>Within the next</option>
                <option value='last'>Within the last</option>
                <option value='between'>Between</option>
                <option value='on'>On</option>
              </select>
            </div>

            <div className='row mb-8'>
              <div className='col-6'>
                <input
                  type='number'
                  name='date_number'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Lenght'
                />
              </div>

              <div className='col-6'>
                <select
                  name='date_typer'
                  aria-label='Select a Timezone'
                  data-control='select2'
                  data-placeholder='Period'
                  className='form-select form-select-sm form-select-solid'
                >
                  <option value='days'>Days</option>
                  <option value='weeks'>Weeks</option>
                  <option value='months'>Months</option>
                  <option value='years'>Years</option>
                </select>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                Cancel
              </button>

              <a
                href='/#'
                className='btn btn-sm fw-bolder btn-primary'
                data-kt-search-element='advanced-options-form-search'
              >
                Search
              </a>
            </div>
          </form>

          <form className={`pt-1 ${menuState === 'preferences' ? '' : 'd-none'}`}>
            <h3 className='fw-bold text-dark mb-7'>Search Preferences</h3>

            <div className='pb-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Projects
                </span>

                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Targets
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Affiliate Programs
                </span>
                <input className='form-check-input' type='checkbox' value='1' />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                  Referrals
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>Users</span>
                <input className='form-check-input' type='checkbox' />
              </label>
            </div>

            <div className='d-flex justify-content-end pt-7'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'
              >
                Cancel
              </button>
              <button className='btn btn-sm fw-bolder btn-primary'>Save Changes</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {Search}
