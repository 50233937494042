import {useState} from 'react'

import {useLocation} from 'react-router-dom'

import {PageTitle} from '../../../_metronic/layout/core'

import Context from '../../../_metronic/partials/layout/activity-drawer/context'

import SelfServiceNavBar from './SelfServiceNavBar'

import {useIntl} from 'react-intl'

import SelfServiceTable from './self_service/SelfServiceTable'

const SelfServiceRoutes = () => {
  const intl = useIntl()
  const ServiceBreadCrumbs = [
    {
      title: intl.formatMessage({id: 'EMP.SELF_SERVICE'}),
      path: '/employee-self-service/self-service-request',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const {pathname} = useLocation()
  const [SideDrawerId, updateId] = useState('0')
  const switchMethod = () => {
    switch (pathname) {
      case '/employee-self-service/self-service-request':
        return (
          <>
            <PageTitle breadcrumbs={ServiceBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.EMPLOYEE_SELF_SERVICE.SELF_SERVICE_REQUEST'})}
            </PageTitle>
            <SelfServiceTable />
          </>
        )
      // case '/employee-self-service/data-correction-request':
      //   return (
      //     <>
      //       <PageTitle breadcrumbs={ServiceBreadCrumbs}>
      //         {intl.formatMessage({id: 'MENU.EMPLOYEE_SELF_SERVICE.DATA_CORRECTION_REQUEST'})}
      //       </PageTitle>
      //       <TeamRequests />
      //     </>
      //   )

      default:
        return (
          <>
            <PageTitle breadcrumbs={ServiceBreadCrumbs}>
              {intl.formatMessage({id: 'MENU.EMPLOYEE_SELF_SERVICE.SELF_SERVICE_REQUEST'})}
            </PageTitle>
            <SelfServiceTable />
          </>
        )
    }
  }

  const UpdatingId = (id) => {
    updateId(id)
  }
  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        <SelfServiceNavBar />
        {switchMethod()}
      </Context.Provider>
    </>
  )
}

export default SelfServiceRoutes
