import React, {useMemo, useState, useEffect} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {DateRange} from 'react-date-range'
import * as locales from 'react-date-range/dist/locale'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import classnames from 'classnames'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {useThemeMode} from '../../../../_metronic/partials'
import {useIntl} from 'react-intl'
import Pagination from 'react-bootstrap/Pagination'
import useApiCall from '../../custom_hooks/useApiCall'
import usePageTitle from '../../custom_hooks/usePageTitle'
import {format} from 'date-fns'
import secureLocalStorage from 'react-secure-storage'

const TeamLeavesTableView = () => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }

  const {
    data: employeesleaveDetails,
    isLoading,
    fetchData,
  } = useApiCall(emp_id ? `/leave/teamLeaves/${emp_id}` : null)

  const dateFormat = localStorage.getItem('dateFormat')

  useEffect(() => {
    fetchData()
  }, [])

  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'LABEL.TEAM_LEAVES'}))
  const lang = useLang()
  const [inputVal, setInputVal] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [locale, setLocale] = React.useState('en')
  const [startDateHolder, setstartDateHolder] = useState('')
  const [endDateHolder, setendDateHolder] = useState('')
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ])
  const [showDateRange, setShowDateRange] = useState(false)
  const {mode} = useThemeMode()
  const [darkMode, setDarkMode] = useState(false)

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(5)

  const pageOptions = [5, 10, 20, 30]

  const pageCount = Math.ceil(employeesleaveDetails && employeesleaveDetails.length / pageSize)
  const startIndex = pageIndex * pageSize
  const endIndex = startIndex + pageSize

  const gotoPage = (page) => {
    setPageIndex(page)
  }

  const previousPage = () => {
    setPageIndex((prev) => prev - 1)
  }

  const nextPage = () => {
    setPageIndex((prev) => prev + 1)
  }

  const canPreviousPage = pageIndex > 0
  const canNextPage = pageIndex < pageCount - 1

  useEffect(() => {
    setDarkMode(mode !== 'light')
  }, [mode])

  useEffect(() => {
    if (lang === 'ar') {
      setLocale('arSA')
    } else {
      setLocale(lang)
    }
  }, [lang])

  const handleDatesChange = () => {
    setStartDate(null)
    setEndDate(null)
    setState([
      {
        startDate: new Date(),
        endDate: null,
        key: 'selection',
      },
    ])
  }

  const handleSearch = (e) => {
    setInputVal(e.target.value)
  }

  const handleToggleDateRange = () => {
    setShowDateRange(!showDateRange)
  }

  const handleApplyDateRange = () => {
    setStartDate(startDateHolder)
    setEndDate(endDateHolder)
    setShowDateRange(false)
  }

  const calendarClasses = classnames('date-range-picker', {
    dark: darkMode,
    light: !darkMode,
  })

  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    setFilteredData(
      employeesleaveDetails &&
        employeesleaveDetails.filter(
          (leave) => leave.status === 'Approved' || leave.status === 'Reviewer Approved'
        )
    )
  }, [employeesleaveDetails])

  const uniqueMembers = [...new Set(filteredData?.map((leave) => leave.employee_id))]

  const tableData = useMemo(() => {
    if (!filteredData) {
      return []
    }

    let filteredLeaves = filteredData

    if (inputVal) {
      filteredLeaves = filteredLeaves.filter((leave) =>
        leave.first_name.toLowerCase().includes(inputVal.toLowerCase())
      )
    }

    if (startDate && endDate) {
      filteredLeaves = filteredLeaves.filter((leave) => {
        const leaveStartDate = new Date(leave.start_date)
        const leaveEndDate = new Date(leave.end_date)

        return (
          (leaveStartDate >= startDate && leaveStartDate <= endDate) ||
          (leaveEndDate >= startDate && leaveEndDate <= endDate) ||
          (leaveStartDate <= startDate && leaveEndDate >= endDate)
        )
      })
    }

    return filteredLeaves
  }, [filteredData, inputVal, startDate, endDate])

  return (
    <>
      {isLoading ? (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <div className='spinner-border spinner-primary mr-15'></div>
          </div>
        </div>
      ) : tableData.length > 0 ? (
        <>
          <div>
            <div className='d-flex flex-wrap flex-stack mb-6'>
              <h3 className='fw-bolder my-3 default-cursor'>
                {' '}
                {intl.formatMessage({id: 'LABEL.TEAM_LEAVES'})}
              </h3>
              <div className='d-flex align-items-center'>
                <div className='d-flex my-2'>
                  <div className='d-flex align-items-center position-relative me-4'>
                    <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                    <input
                      type='search'
                      id='kt_filter_search'
                      className='form-control form-control-white form-control-sm w-150px ps-9'
                      placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                      value={inputVal}
                      onChange={handleSearch}
                    />
                  </div>
                </div>

                {/* <div className=''>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => {
                handleToggleDateRange()
              }}
            >
              <KTIcon iconName='calendar' className='fs-2 m-0' />
            </button>

            <button className=' btn btn-icon btn-bg-light btn-sm me-2' onClick={handleDatesChange}>
              <KTIcon iconName='setting-2' className='fs-2 m-0' />
            </button>
          </div> */}
              </div>
              {showDateRange && (
                <div
                  style={{
                    position: 'absolute',
                    left: locale === 'arSA' ? '0' : 'auto',
                    right: locale === 'arSA' ? 'auto' : '125px',
                    zIndex: '9',
                  }}
                >
                  <div className={`card d-flex flex-wrap  mb-6 ${darkMode ? 'dark-mode' : ''}`}>
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => {
                        setState([item.selection])
                        setstartDateHolder(item.selection.startDate)
                        setendDateHolder(item.selection.endDate)
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={state}
                      locale={locales[locale]}
                      className={calendarClasses}
                    />
                    <div
                      className='d-flex justify-content-end'
                      style={{position: 'absolute', bottom: '10px', right: '10px'}}
                    >
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={() => {
                          handleApplyDateRange()
                        }}
                      >
                        {intl.formatMessage({id: 'LABEL.OK'})}
                      </button>
                    </div>
                  </div>
                  <style>
                    {`
              .dark-mode {
                background-color: #333;
                color: #fff;
              }
              `}
                  </style>
                </div>
              )}
            </div>

            <>
              <div className='card mb-5 mb-xl-8 p-10'>
                <table className='table table-row-dashed table-row-gray-300 align-middle'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-150px p-5 default-cursor'>
                        {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                      </th>
                      <th className='min-w-140px text-start default-cursor'>
                        {intl.formatMessage({id: 'LABEL.LEAVE_TYPE'})}
                      </th>
                      <th className='min-w-120px text-start default-cursor'>
                        {intl.formatMessage({id: 'EMP.TRAININGS.START_DATE'})}
                      </th>
                      <th className='min-w-100px text-start default-cursor'>
                        {intl.formatMessage({id: 'EMP.TRAININGS.END_DATE'})}
                      </th>
                      <th className='min-w-100px text-start default-cursor'>
                        {intl.formatMessage({id: 'LABEL.NO_OF_DAYS'})}
                      </th>

                      <th className='min-w-100px text-start default-cursor'>
                        {intl.formatMessage({id: 'LABEL.STATUS'})}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData &&
                      uniqueMembers.map((member) => {
                        const formattedStartDate = (date) => {
                          return format(new Date(date), dateFormat)
                        }
                        const formattedEndDate = (date) => {
                          return format(new Date(date), dateFormat)
                        }
                        const memberLeaves = tableData.filter(
                          (leave) => leave.employee_id === member
                        )
                        return memberLeaves.map((leave, index) => (
                          <tr key={leave.leave_id}>
                            {index === 0 && (
                              <td rowSpan={memberLeaves.length} className='align-top'>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-45px me-5 default-cursor'>
                                        <img
                                          src={toAbsoluteUrl('/media/avatars/300-15.jpg')}
                                          alt=''
                                        />
                                      </div>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <p className='text-dark fw-bold text-hover-primary fs-6 m-0 default-cursor'>
                                          {leave.first_name} {leave.middle_name} {leave.last_name}
                                        </p>
                                        <span className='text-muted fw-semibold text-muted fs-7 text-start default-cursor'>
                                          EMP00{leave.employee_id}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            )}
                            <td className='text-start text-dark fw-bold text-hover-primary default-cursor'>
                              {leave.leave_type_name}
                            </td>
                            <td className='text-start text-dark fw-bold text-hover-primary default-cursor'>
                              {formattedStartDate(leave.start_date.slice(0, 10))}
                            </td>
                            <td className='text-start text-dark fw-bold text-hover-primary default-cursor'>
                              {formattedEndDate(leave.end_date.slice(0, 10))}
                            </td>
                            <td className='text-start text-dark fw-bold text-hover-primary default-cursor'>
                              {leave.no_of_days}
                            </td>

                            <td className='text-start text-dark fw-bold text-hover-primary default-cursor'>
                              {leave.status}
                            </td>
                          </tr>
                        ))
                      })}
                  </tbody>
                </table>
              </div>
              {tableData.length > 10 && (
                <Pagination>
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />
                  {[...Array(pageCount)].map((_, i) => (
                    <Pagination.Item key={i} active={i === pageIndex} onClick={() => gotoPage(i)}>
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                  <Pagination.Last
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  />
                  <div className='d-flex align-items-center'>
                    <span className='me-2'>{intl.formatMessage({id: 'BTN.SHOW'})}</span>
                    <select
                      className='form-select form-select-solid fw-bold w-75px me-2'
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {pageOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </Pagination>
              )}
            </>
          </div>
        </>
      ) : (
        <div className='card h-250px'>
          <div className='m-auto d-flex flex-column align-items-center'>
            <h1 className='mb-5'>{intl.formatMessage({id: 'MSG.NO_LEAVES_FOUND'})}</h1>
          </div>
        </div>
      )}
    </>
  )
}

export default TeamLeavesTableView
