import {useContext, useEffect} from 'react'

import {KTIcon} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'

import {useLocation} from 'react-router'
import {useIntl} from 'react-intl'
import secureLocalStorage from 'react-secure-storage'
import VacancyContext from './vacancies/VacancyContext'
import {GetAllCandidates, GetAllEmployees} from './RecruitmentAPI'
import {GetAllVacancies} from './RecruitmentAPI'
import useApiCall from '../custom_hooks/useApiCall'

const RecruitmentNav = () => {
  const {VACANCYID} = useContext(VacancyContext)

  const vacancyIdString = parseInt(VACANCYID)

  const {data} = useApiCall('/organization/1')

  const brandName = localStorage.getItem('displayName') || 'Kloudworx'
  const brandLogos = secureLocalStorage.getItem('brand_logos')
  const {brand_logo_url} = brandLogos || {}
  const {employeesData} = GetAllEmployees()
  const {vacanciesData} = GetAllVacancies()
  const {candidatesData} = GetAllCandidates()

  const locationName = data && data[0]

  const getuserID = localStorage.getItem('CANDID')

  let candidateID = null

  if (vacancyIdString !== 0) {
    candidateID = vacancyIdString
  } else {
    candidateID = getuserID
  }

  // Rest of your component logic remains unchanged
  // ...

  const location = useLocation()
  const intl = useIntl()

  const {
    data: vacancyDetails,
    isLoading,
    fetchData,
  } = useApiCall(
    candidateID !== null && candidateID !== '"0"' ? `/recruitment/vacancies/${candidateID}` : null
  )

  useEffect(() => {
    fetchData()
  }, [candidateID])

  useEffect(() => {
    return () => {
      if (vacancyIdString !== 0) {
        localStorage.setItem('CANDID', VACANCYID)
      }
    }
  })

  return (
    <div className='card mb-4 mb-xl-6'>
      <div className='card-body pt-8 pb-0'>
        {location.pathname === '/recruitment/vacancies' ||
        location.pathname === '/recruitment/allcandidates' ||
        location.pathname === '/recruitment/all-job-offers' ? (
          <>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='me-4 mb-3'>
                <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
                  <img
                    src={
                      brand_logo_url
                        ? `${brand_logo_url}?v=${Date.now()}`
                        : 'https://www.doyonutilities.com/wp-content/uploads/profile-icon.png'
                    }
                    alt='Image'
                  />
                </div>
              </div>
              {isLoading ? (
                <div className='card h-100px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : (
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap '>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <a
                          href='#'
                          className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1 default-cursor mb-0'
                        >
                          {brandName}
                        </a>
                      </div>

                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2  default-cursor'
                        >
                          <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                          {locationName?.address_line1}
                        </a>
                        <a
                          href='#'
                          className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 default-cursor'
                        >
                          <KTIcon iconName='geolocation' className='fs-4 me-1' />
                          {locationName?.country_name}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                      <div className='d-flex flex-wrap'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>
                              {employeesData?.length > 0 ? employeesData?.length : 0}
                            </div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>
                            {intl.formatMessage({id: 'LABEL.NO_OF_EMPLOYEES'})}
                          </div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>
                              {vacanciesData?.length > 0 ? vacanciesData?.length : 0}
                            </div>
                          </div>

                          <div className='fw-bold fs-6 text-gray-400'>
                            {intl.formatMessage({id: 'MENU.RECRUITMENT.VACANCIES'})}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex overflow-auto'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item nav-item-links'>
                  <Link
                    className={
                      `nav-link text-active-primary text-grey me-6 ` +
                      (location.pathname === '/recruitment/vacancies' && 'active')
                    }
                    to='/recruitment/vacancies'
                  >
                    {intl.formatMessage({id: 'MENU.RECRUITMENT.VACANCIES'})}
                  </Link>
                </li>

                <li className='nav-item nav-item-links'>
                  <Link
                    className={
                      `nav-link text-active-primary text-grey me-6 ` +
                      (location.pathname === '/recruitment/allcandidates' && 'active')
                    }
                    to='/recruitment/allcandidates'
                  >
                    {intl.formatMessage({id: 'MENU.RECRUITMENT.ALLCANDIDATES'})}
                  </Link>
                </li>
                <li className='nav-item nav-item-links'>
                  <Link
                    className={
                      `nav-link text-active-primary text-grey me-6 ` +
                      (location.pathname === '/recruitment/all-job-offers' && 'active')
                    }
                    to='/recruitment/all-job-offers'
                  >
                    {intl.formatMessage({id: 'LABEL.ALL_OFFERS'})}
                  </Link>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <>
            {isLoading ? (
              <div className='card h-350px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : vacancyDetails === null || vacancyDetails.length === 0 ? (
              <div className='h-250px'>
                <div className='m-auto d-flex flex-column align-items-center default-cursor'>
                  <h1 className='mb-5'> {intl.formatMessage({id: 'LABEL.NO_DATA'})}</h1>
                </div>
              </div>
            ) : (
              <div>
                {vacancyDetails?.map((item, index) => (
                  <div key={index}>
                    <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                      <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                          <img
                            src={
                              brand_logo_url
                                ? `${brand_logo_url}?v=${Date.now()}`
                                : 'https://www.doyonutilities.com/wp-content/uploads/profile-icon.png'
                            }
                            alt='Image'
                          />
                        </div>
                      </div>

                      <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                          <div className='d-flex flex-column'>
                            <div className='d-flex align-items-center mb-2'>
                              <a
                                href='#'
                                className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                              >
                                {item.role_name}
                              </a>
                            </div>

                            <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                              <a
                                href='#'
                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                              >
                                <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                                {item.department_name}
                              </a>
                              <a
                                href='#'
                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                              >
                                <KTIcon iconName='geolocation' className='fs-4 me-1' />
                                {item.city_name}
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className='d-flex flex-wrap flex-stack'>
                          <div className='d-flex flex-column flex-grow-1 pe-8'>
                            <div className='d-flex flex-wrap'>
                              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                  <div className='fs-2 fw-bolder'>
                                    {candidatesData?.length > 0 ? candidatesData?.length : 0}
                                  </div>
                                </div>

                                <div className='fw-bold fs-6 text-gray-400'>
                                  {intl.formatMessage({id: 'LABEL.APPLIED_CANDIDATES'})}
                                </div>
                              </div>

                              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                  <div className='fs-2 fw-bolder'>
                                    {vacanciesData?.length > 0 ? vacanciesData?.length : 0}
                                  </div>
                                </div>

                                <div className='fw-bold fs-6 text-gray-400'>
                                  {' '}
                                  {intl.formatMessage({id: 'MENU.RECRUITMENT.VACANCIES'})}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex overflow-auto h-55px'>
                      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item nav-item-links'>
                          <Link
                            className={
                              `nav-link text-active-primary text-grey me-6 ` +
                              (location.pathname === '/recruitment/candidates' && 'active')
                            }
                            to='/recruitment/candidates'
                          >
                            {intl.formatMessage({id: 'MENU.RECRUITMENT.CANDIDATES'})}
                          </Link>
                        </li>
                        <li className='nav-item nav-item-links'>
                          <Link
                            className={
                              `nav-link text-active-primary text-grey me-6 ` +
                              (location.pathname === '/recruitment/interviews' && 'active')
                            }
                            to='/recruitment/interviews'
                          >
                            {intl.formatMessage({id: 'MENU.RECRUITMENT.INTERVIEWS'})}
                          </Link>
                        </li>
                        {/* <li className='nav-item nav-item-links'>
                          <Link
                            className={
                              `nav-link text-active-primary text-grey me-6 ` +
                              (location.pathname === '/recruitment/job-offers' && 'active')
                            }
                            to='/recruitment/job-offers'
                          >
                            {intl.formatMessage({id: 'MENU.RECRUITMENT.JOB_OFFERS'})}
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default RecruitmentNav
