import {useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../../_metronic/helpers'
import {baseUrl, defaultProfileImage} from '../../../core'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../../../custom_hooks/useApiCall'
import usePostApi from '../../../custom_hooks/usePostApi'
import DatePicker from 'react-datepicker'
import '../../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {DateBasicFormat} from '../../../DateHelpers'
import {useThemeMode} from '../../../../../_metronic/partials'
import {useAuth} from '../../../auth'
import {FirstUserId} from '../../../core'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'

const AddAdvanceSideDrawer = ({updateExpensesFunc}) => {
  const {execute} = usePostApi()
  const lang = useLang()
  const intl = useIntl()
  const {mode} = useThemeMode()
  const {currentUser} = useAuth()
  const [installmentsErr, setInstallmentsErr] = useState(false)

  const {data: currencyTypes} = useApiCall('/currencyTypes')
  const userInfo = secureLocalStorage.getItem('user_info')

  let organization_id
  if (userInfo) {
    ;({organization_id} = userInfo)
  }

  const validationSchema = Yup.object().shape({
    loanName: Yup.string()
      .required(`${intl.formatMessage({id: 'LABEL.LOAN_REASON_IS_REQUIRED'})}`)
      .min(3, `${intl.formatMessage({id: 'MIN_3_CHAR_REQUIRED'})}`),
    advanceAmount: Yup.number()
      .required(`${intl.formatMessage({id: 'ERR.MSG.AMOUNT_IS_REQUIRED'})}`)
      .min(0.1, `${intl.formatMessage({id: 'LABEL.ADV_AMOUNT_NOT_NEGATIVE'})}`),
    currencyType: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.CURRENCY_IS_REQUIRED'})}`
    ),
    reqTypeVal: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.REQUEST_TYPE_IS_REQUIRED'})}`
    ),
    needByDate: Yup.string().required(`${intl.formatMessage({id: 'LABEL.NEED_BY_DATE_IS_REQ'})}`),
  })

  const initialValues = {
    needByDate: '',
    loanName: '',
    advanceAmount: '',
    noOfInstallments: '',
    currencyType: '',
    reqTypeVal: '',
  }

  const AddNewAdvance = async (values, resetForm) => {
    try {
      const url = `${baseUrl}/employee/advances`

      const formData = {
        organization_id: organization_id,
        advance_amount: values.advanceAmount,
        currency_id: parseInt(values.currencyType),
        advance_date: DateBasicFormat(values.needByDate),
        advance_reason: values.loanName,
        advance_status: currentUser?.user_id === FirstUserId ? 'HR Pending' : 'Manager Pending',
        monthly_deductible: 'Fixed',
        deductible_value:
          values.advanceAmount /
          (parseInt(values.noOfInstallments) ? parseInt(values.noOfInstallments) : 1),
        no_of_installments:
          values.reqTypeVal === 'SAL_ADV'
            ? 1
            : parseInt(values.noOfInstallments)
            ? parseInt(values.noOfInstallments)
            : 1,
        loan_type: values.reqTypeVal,
      }

      const response = await execute(url, 'POST', formData)
      const advRes = JSON.parse(response.api_response_data)
      updateExpensesFunc()

      const sideDrawer = document.getElementById('addNewAdvanceDrawerClose')
      if (sideDrawer) {
        sideDrawer.click()
      }

      Swal.fire({
        title:
          values.reqTypeVal === 'SAL_ADV'
            ? `${intl.formatMessage({id: 'LABEL.YOUR_ADV_SUBMITTED_SUCCESS'})}`
            : `${intl.formatMessage({id: 'LABEL.YOUR_LOAN_SUBMITTED_SUCCESS'})}`,

        text:
          values.reqTypeVal === 'SAL_ADV'
            ? `${intl.formatMessage({id: 'LABEL.ADV_REF_ID'}, {advCode: advRes?.advance_code})}`
            : `${intl.formatMessage({id: 'LABEL.LOAN_REF_ID'}, {advCode: advRes?.advance_code})}`,
        icon: 'success',
        confirmButtonText: intl.formatMessage({id: 'BTN.OK'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
      resetForm()
      setInstallmentsErr(false)
    } catch (error) {
      console.error('Error:', error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'API_FAILURE_TEXT'}),
        confirmButtonText: intl.formatMessage({id: 'BTN.OK'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('addNewAdvanceDrawerClose')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    }
  }

  const handleSubmit = (values, {resetForm}) => {
    if (values.reqTypeVal === 'LOAN' && values.noOfInstallments === '') {
      setInstallmentsErr(true)
      return
    }
    AddNewAdvance(values, resetForm)
  }

  const handleCancel = (resetForm, touched) => {
    if (Object.keys(touched).length === 0) {
      const sideDrawer = document.getElementById('addNewAdvanceDrawerClose')
      if (sideDrawer) {
        sideDrawer.click()
      }
    } else {
      resetForm()
    }
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div
        id='addNewAdvance'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='activities'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'60%', 'lg': '450px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle={`#addNewAdvanceToggle`}
        data-kt-drawer-close={`#addNewAdvanceDrawerClose`}
      >
        <div className='w-100'>
          <div className='card-header'>
            <h3 className='card-title fw-bolder text-dark'>
              {intl.formatMessage({id: 'LABEL.APPLY_FOR_LOAN'})}
            </h3>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='addNewAdvanceDrawerClose'
              >
                <KTIcon iconName='cross' className='fs-1' />
              </button>
            </div>
          </div>
          <div className='m-8 mt-6 mb-7'>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({values, resetForm, errors, touched}) => {
                return (
                  <Form>
                    <div className='modal-body'>
                      <div className='d-flex align-items-start'>
                        <img
                          src={
                            currentUser && currentUser?.employee_profile_pic
                              ? currentUser?.employee_profile_pic
                              : defaultProfileImage
                          }
                          alt='img'
                          className='activity-img rounded w-60px h-60px me-3'
                        />
                        <div className='m-3 mt-0'>
                          <p className='text-dark fw-bold text-hover-primary fs-5 m-0'>
                            {currentUser?.first_name &&
                            currentUser?.middle_name &&
                            currentUser?.last_name
                              ? `${currentUser?.first_name} ${currentUser?.middle_name} ${currentUser?.last_name}`
                              : currentUser?.first_name && currentUser?.last_name
                              ? `${currentUser?.first_name} ${currentUser?.last_name}`
                              : '-'}
                          </p>
                          <p className='text-dark fw-bold text-hover-primary fs-7 text-muted m-0'>
                            {currentUser?.job_title ? currentUser?.job_title : '-'}
                          </p>
                          <p className='text-dark fw-bold text-hover-primary fs-7 text-muted m-0'>
                            {currentUser?.employee_code ? currentUser?.employee_code : '-'}
                          </p>
                        </div>
                      </div>
                      <h5 className='mt-2 text-muted'>
                        {intl.formatMessage({id: 'LABEL.LOAN_OR_ADV_INFO'})}
                      </h5>
                      <hr className='text-dark mt-2 mb-6' />

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.REQUEST_TYPE'})}
                        </label>

                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='reqTypeVal'
                            className='form-select form-select-solid'
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_REQUEST_TYPE'})}
                            </option>
                            <option value='LOAN'>{intl.formatMessage({id: 'LABEL.LOAN'})}</option>
                            <option value='SAL_ADV'>
                              {intl.formatMessage({id: 'LABEL.SAL_ADV'})}
                            </option>
                          </Field>
                          <ErrorMessage name='reqTypeVal' component='div' className='text-danger' />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.NEED_BY_DATE'})}
                        </label>

                        <div className='col-lg-8 text-start'>
                          <Field name='needByDate'>
                            {({form, field}) => {
                              const {setFieldValue} = form
                              const {value} = field

                              const handleChange = (date) => {
                                setFieldValue('needByDate', date)
                              }

                              const minDate = new Date()
                              minDate.setDate(minDate.getDate() + 1)

                              return (
                                <div
                                  className={`react-datepicker-fullwidth-wrapper test ${
                                    mode === 'dark' ? 'dark-mode' : 'light-mode'
                                  }`}
                                >
                                  <DatePicker
                                    className='form-control form-control-solid'
                                    selected={value}
                                    isClearable={value !== null && value !== ''}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormat='dd/MM/yyyy'
                                    onChange={(date) => {
                                      handleChange(date)
                                    }}
                                    placeholderText={intl.formatMessage({id: 'LABEL.NEED_BY_DATE'})}
                                    showMonthDropdown
                                    useShortMonthInDropdown
                                    minDate={minDate}
                                    locale={lang || 'en'}
                                  />
                                </div>
                              )
                            }}
                          </Field>
                          <ErrorMessage name='needByDate' component='div' className='text-danger' />
                        </div>
                      </div>

                      {values.reqTypeVal === 'LOAN' && (
                        <div className='row mb-5 d-flex align-items-center'>
                          <label className='col-lg-4 fw-bold text-muted text-start'>
                            {intl.formatMessage({id: 'LABEL.TOTAL_DURATION'})}
                          </label>

                          <div className='col-lg-8 text-start'>
                            <Field
                              as='select'
                              name='noOfInstallments'
                              className='form-select form-select-solid'
                            >
                              <option value=''>
                                {intl.formatMessage({id: 'LABEL.NO_OF_INSTALLMENTS'})}
                              </option>
                              <option value='3'>3</option>
                              <option value='6'>6</option>
                              <option value='9'>9</option>
                              <option value='12'>12</option>
                            </Field>
                            {installmentsErr && values.noOfInstallments === '' && (
                              <p className='text-danger'>
                                {intl.formatMessage({id: 'LABEL.NO_OF_INSTALLMENTS_REQ'})}
                              </p>
                            )}
                          </div>
                        </div>
                      )}

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.CURRENCY'})}
                        </label>

                        <div className='col-lg-8 text-start'>
                          <Field
                            as='select'
                            name='currencyType'
                            className='form-select form-select-solid'
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_CURRENCY'})}
                            </option>
                            {currencyTypes?.map((currency) => (
                              <option key={currency.currency_id} value={currency.currency_id}>
                                {currency.currency_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name='currencyType'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>

                      <div className='row mb-5 d-flex align-items-center'>
                        <label className='col-lg-4 fw-bold text-muted text-start'>
                          {intl.formatMessage({id: 'LABEL.AMOUNT'})}
                        </label>

                        <div className='col-lg-8 text-start'>
                          <Field
                            type='number'
                            name='advanceAmount'
                            className='form-control form-control-solid'
                            placeholder={intl.formatMessage({id: 'LABEL.AMOUNT'})}
                          />
                          <ErrorMessage
                            name='advanceAmount'
                            component='div'
                            className='text-danger'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-5 d-flex align-items-center'>
                      <label className='col-lg-4 fw-bold text-muted text-start'>
                        {intl.formatMessage({id: 'LABEL.REASON'})}
                      </label>

                      <div className='col-lg-8 text-start'>
                        <Field
                          as='textarea'
                          name='loanName'
                          className='form-control form-control-solid'
                          placeholder={intl.formatMessage({id: 'LABEL.REASON'})}
                        />
                        <ErrorMessage name='loanName' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='card-footer p-0 py-5 mt-5 text-end'>
                      <button
                        type='button'
                        className='btn btn-light me-2 btn-sm'
                        onClick={() => handleCancel(resetForm, touched)}
                      >
                        {Object.keys(touched).length === 0
                          ? intl.formatMessage({id: 'BTN.CLOSE'})
                          : intl.formatMessage({id: 'BTN.CLEAR'})}
                      </button>
                      <button type='submit' className='btn btn-light-primary ms-2 btn-sm'>
                        {intl.formatMessage({id: 'BTN.APPLY_FOR_LOAN'})}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddAdvanceSideDrawer
