import {useState, useEffect} from 'react'
import secureLocalStorage from 'react-secure-storage'
import {baseUrl} from '../../core'

import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'
import axios from 'axios'
import usePostApi from '../../custom_hooks/usePostApi'

function LeaveHR({hrDetails}) {
  const {execute} = usePostApi()
  const {
    reviewer_status = hrDetails.reviewer_status,
    hr_reviewed_status = null,
    fetchData = () => {},
    hr_review_comments = '',
    leave_id = '',
    leavefetchData,
  } = hrDetails

  let emp_id
  let organization_id
  let role
  let is_hr
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, organization_id, role, is_hr} = userInfo)
  }

  const intl = useIntl()
  const roles = role ? JSON.parse(role) : []
  const [hrComment, setHrComment] = useState('')

  const [comError, setComError] = useState(false)
  const [comLenErr, setComLenErr] = useState(false)

  useEffect(() => {
    setHrComment('')

    setComError(false)
    setComLenErr(false)
  }, [])

  useEffect(() => {
    if (hrComment !== '') {
      setComError(false)
    }
    if (hrComment.length >= 3) {
      setComLenErr(false)
    }
  }, [hrComment])

  const approveAndRejectBtns = async (event) => {
    if (hrComment === '') {
      setComError(true)
      return
    } else if (hrComment.length < 3) {
      setComLenErr(true)
      return
    }

    try {
      const url = `${baseUrl}/leave/hr/${leave_id}`
      const formData = {
        organization_id: organization_id,
        hr_reviewed_by: emp_id,
        hr_review_comments: hrComment,
        hr_reviewed_status: event.target.name,
        status: event.target.name,
      }

      await execute(url, 'PATCH', formData)
      fetchData()
      setHrComment('')
      leavefetchData()
      const successMessage =
        event.target.name === 'Approved'
          ? intl.formatMessage({id: 'MSG.LEAVE_APPROVED_SUCCESSFULLY'})
          : intl.formatMessage({id: 'MSG.LEAVE_REJECTED_SUCCESSFULLY'})

      await Swal.fire({
        title: successMessage,
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    } catch (error) {
      console.error('Error:', error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
      })
    }
  }
  // role === 'Admin'

  const getHrView = (ManagerStatus, hrStatus) => {
    if (ManagerStatus === 'Approved' && hrStatus === 'Pending' && is_hr === 1) {
      return (
        <div className='min-h-26px'>
          <label>{intl.formatMessage({id: 'LABEL.COMMENTS'})}</label>
          <br />
          <textarea
            placeholder={intl.formatMessage({id: 'LABEL.COMMENTS'})}
            className='form-control mb-3 form-control-solid'
            value={hrComment}
            onChange={(e) => setHrComment(e.target.value)} // Update the local state managerComment
          />
          {comError && (
            <p className='text-danger'>{intl.formatMessage({id: 'LABEL.COMMENTS_IS_REQUIRED'})}</p>
          )}
          {comLenErr && !comError && (
            <p className='text-danger'>
              {intl.formatMessage({id: 'LABEL.COMMENTS_MUST_BE_AT_LEAST_3_CHARACTERS'})}
            </p>
          )}
          <div>
            <button
              className='btn btn-light-success btn-sm py-2'
              name='Approved'
              onClick={approveAndRejectBtns}
              type='submit'
              // id='side_activities_close'
            >
              {intl.formatMessage({id: 'LABEL.APPROVE'})}
            </button>
            <button
              className='btn btn-light-danger btn-sm ms-2 py-2'
              name='Rejected'
              onClick={approveAndRejectBtns}
              type='submit'
              // id='side_activities_close'
            >
              {intl.formatMessage({id: 'LABEL.REJECT'})}
            </button>
          </div>
        </div>
      )
    } else if (hrStatus === 'Approved' || hrStatus === 'Rejected') {
      return (
        <>
          <p className='m-0 text-muted'>{hr_review_comments}</p>
        </>
      )
    } else {
      return null
    }
  }

  const getStatusMsg = (ManagerStatus, hrStatus) => {
    if (ManagerStatus === 'Pending') {
      return "Awaiting Manager's Action"
    } else if (ManagerStatus === 'Rejected') {
      return 'No Action'
    } else if (ManagerStatus === 'Approved' && hrStatus === 'Pending') {
      return 'Pending'
    } else if (hrStatus === 'Rejected') {
      return 'Rejected'
    } else if (ManagerStatus === 'Approved' && hrStatus === 'Approved') {
      return hr_reviewed_status
    } else {
      return hrStatus
    }
  }

  const getStatusColor = (ManagerStatus, hrStatus) => {
    if (ManagerStatus === 'Rejected') {
      return 'secondary'
    } else if (
      ManagerStatus === 'Pending' ||
      (ManagerStatus === 'Approved' && hrStatus === 'Pending')
    ) {
      return 'muted'
    } else if (ManagerStatus === 'Approved' && hrStatus === 'Approved') {
      return 'success'
    } else {
      return 'danger'
    }
  }

  return (
    <div className='timeline-item'>
      <div className='timeline-line w-20px'></div>

      <div className='timeline-icon symbol symbol-circle symbol-20px'>
        <div
          className={`symbol-label bg-${getStatusColor(
            reviewer_status,
            hr_reviewed_status
          )} icon-dotted-border text-${getStatusColor(reviewer_status, hr_reviewed_status)}`}
        ></div>
      </div>

      <div className='timeline-content mb-12 mt-n1'>
        <div className='d-flex justify-content-between mt-1'>
          <p className={`mb-0 fw-bold text-${getStatusColor(reviewer_status, hr_reviewed_status)}`}>
            {getStatusMsg(reviewer_status, hr_reviewed_status)}
          </p>
          <div className=''>
            <p className='designation-btn bg-secondary fs-7 m-auto'>
              {intl.formatMessage({id: 'LABEL.HR'})}
            </p>
          </div>
        </div>
        {getHrView(reviewer_status, hr_reviewed_status)}
      </div>
    </div>
  )
}

export default LeaveHR
