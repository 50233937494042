//businessCard
import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import es from 'date-fns/locale/es'
import ar from 'date-fns/locale/ar'
import fr from 'date-fns/locale/fr'

import {registerLocale} from 'react-datepicker'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import Swal from 'sweetalert2'
import {baseUrl} from '../core'
import DatePicker from 'react-datepicker'
import '../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../_metronic/partials'
import {DateBasicFormat} from '../DateHelpers'
import usePostApi from '../custom_hooks/usePostApi'
import useApiCall from '../custom_hooks/useApiCall'
import secureLocalStorage from 'react-secure-storage'

const EditAsset = (props) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const userInfo = secureLocalStorage.getItem('user_info')
  const {role, organization_id} = userInfo || {}
  const [isEditMode, setIsEditMode] = useState(false)
  const {data, fetchData, isLoading} = useApiCall(
    `/assetManagement/assets/asset/asset/${props.props.id}`
  )
  const {mode} = useThemeMode()
  const {data: assettypeDetails} = useApiCall('/hrConfig/assetTypes')
  const {data: countries} = useApiCall('/country')
  const [locale, setLocale] = React.useState('en')
  const {data: currencytype} = useApiCall('/currencyTypes')
  const lang = useLang()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (lang === 'ar') {
      setLocale('ar')
    } else {
      setLocale(lang)
    }
  })

  registerLocale('es', es)
  registerLocale('ar', ar)
  registerLocale('fr', fr)

  let assetData = data && data[0]

  const validationSchemaEdit = Yup.object().shape({
    asset_type_id: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.ASSET_TYPE_IS_REQUIRED'})}`
    ),
    brand: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.BRAND_IS_REQUIRED'})}`),
    make: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.MAKE_IS_REQUIRED'})}`),
    model: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.MODEL_IS_REQUIRED'})}`),
    serial_number: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.SERIAL_NUMBER_IS_REQUIRED'})}`
    ),
    pr_no: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.PURCHASE_NUMBER_IS_REQUIRED'})}`
    ),
    pr_date: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.PURCHASE_DATE_IS_REQUIRED'})}`
    ),
    po_no: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.PURCHASE_ORDER_IS_REQUIRED'})}`
    ),

    po_amount: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.AMOUNT_IS_REQUIRED'})}`),
    unit_rate: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.UNIT_RATE_IS_REQUIRED'})}`
    ),
    supplier: Yup.string().required(`${intl.formatMessage({id: 'ERR.MSG.SUPPLIER_IS_REQUIRED'})}`),
    working_status: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.STATUS_IS_REQUIRED'})}`
    ),
    salvage_value: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.SALVAGE_VALUE_IS_REQUIRED'})}`
    ),
    depreciation_percentage: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.DEPRICIATION_IS_REQUIRED'})}`
    ),
    asset_lifespan: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.ASSET_LIFE_SPAN_IS_REQUIRED'})}`
    ),
    asset_tag: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.ASSET_TAG_IS_REQUIRED'})}`
    ),
    first_installation_date: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.DATE_IS_REQUIRED'})}`
    ),
    warranty_start_date: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.WARRANTY_START_DATE_IS_REQUIRED'})}`
    ),
    warranty_expiry_date: Yup.string().when(
      'warranty_start_date',
      (warranty_start_date, schema) => {
        return schema
          .test(
            'warranty_expiry_date',
            intl.formatMessage({id: 'ERR.MSG.EXPIRY_DATE_CANNOT_BE_BEFORE_START_DATE'}),
            function (value) {
              return (
                !warranty_start_date || !value || new Date(value) >= new Date(warranty_start_date)
              )
            }
          )
          .required(`${intl.formatMessage({id: 'ERR.MSG.WARRANTY_EXPIRY_DATE_IS_REQUIRED'})}`)
      }
    ),
    currency_id: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.CURRENCY_IS_REQUIRED'})}`
    ),
    country_of_manufacture: Yup.string().required(
      `${intl.formatMessage({id: 'ERR.MSG.COUNTRY_OF_MANUFACTURE_IS_REQUIRED'})}`
    ),
  })
  const initialValues = {
    asset_type_id: assetData?.asset_type_id,
    country_of_manufacture: assetData?.country_of_manufacture || '',
    brand: assetData?.brand,
    make: assetData?.make,
    model: assetData?.model,
    serial_number: assetData?.serial_number,
    pr_no: assetData?.pr_no,
    pr_date: assetData?.pr_date,
    po_amount: assetData?.po_amount,
    unit_rate: assetData?.unit_rate,
    po_no: assetData?.po_no,
    po_date: assetData?.po_date,
    supplier: assetData?.supplier,
    working_status: assetData?.working_status,
    salvage_value: assetData?.salvage_value,
    depreciation_percentage: assetData?.depreciation_percentage,
    asset_lifespan: assetData?.asset_lifespan,
    asset_tag: assetData?.asset_tag,
    first_installation_date: assetData?.first_installation_date,
    in_stock: assetData?.in_stock,
    warranty_start_date: assetData?.warranty_start_date,
    warranty_expiry_date: assetData?.warranty_expiry_date,

    currency_id: assetData?.currency_id,
  }
  const handleEditSubmit = async (values, formikHelpers) => {
    const {resetForm} = formikHelpers
    try {
      const organizationId = secureLocalStorage.getItem('organization_id')
      const url = `${baseUrl}/assetManagement/assets/${props.props.id}`

      const formData = {
        organization_id: organizationId,
        asset_type_id: parseInt(values.asset_type_id),
        brand: values.brand,
        make: values.make,
        model: values.model,
        country_of_manufacture: parseInt(values.country_of_manufacture),
        serial_number: values.serial_number,
        pr_no: values.pr_no,
        pr_date: DateBasicFormat(values.pr_date),
        po_amount: parseFloat(values.po_amount + '.01'),
        unit_rate: parseFloat(values.unit_rate + '.01'),
        po_no: values.po_no,
        po_date: DateBasicFormat(values.po_date),
        supplier: values.supplier,
        working_status: values.working_status,
        salvage_value: parseFloat(values.salvage_value + '.01'),
        depreciation_percentage: parseFloat(values.depreciation_percentage + '.01'),
        asset_lifespan: parseInt(values.asset_lifespan),
        asset_tag: values.asset_tag,
        first_installation_date: DateBasicFormat(values.first_installation_date),
        in_stock: values.in_stock ? 1 : 0,
        warranty_start_date: DateBasicFormat(values.warranty_start_date),
        warranty_expiry_date: DateBasicFormat(values.warranty_expiry_date),
        currency_id: parseInt(values.currency_id),
      }

      await execute(url, 'PATCH', formData)
      resetForm()
      fetchData()
      props.props.fetchData()
      Swal.fire({
        title: 'Asset Edited Successfuully',
        text: 'The changes have been saved.',
        icon: 'success',
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [props.props.id])

  const handleEditClick = () => {
    setIsEditMode(true)
  }

  const handleSaveClick = () => {
    setIsEditMode(false)
    // Add your save logic here
  }
  return (
    <div>
      <div className='card shadow-none rounded-0'>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchemaEdit}
          onSubmit={handleEditSubmit}
        >
          {({values, setFieldValue, handleChange}) => {
            return (
              <>
                <Form>
                  <div className='card-header' id='side_activities_header'>
                    <>
                      <h3 className='card-title fw-bolder text-dark'>
                        {intl.formatMessage({id: 'LABEL.EDITS_ASSET'})}
                      </h3>
                      <div className='card-toolbar '>
                        {!isEditMode ? (
                          <button
                            type='button'
                            className='btn btn-sm btn-light-primary px-4 me-3'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-start'
                            data-kt-menu-flip='top-end'
                            onClick={handleEditClick}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                            {intl.formatMessage({id: 'BTN.EDIT'})}
                          </button>
                        ) : (
                          <React.Fragment>
                            <div
                              className='btn btn-icon btn-sm btn-active-danger'
                              onClick={handleSaveClick}
                              id='side_activities_close'
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr061.svg'
                                className='svg-icon svg-icon-2x'
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </>
                  </div>
                  <div className='card-body position-relative' id='side_activities_body'>
                    <div>
                      <div
                        id='side_activities_scroll'
                        className='position-relative scroll-y me-n5 pe-5 w-400px'
                        data-kt-scroll='true'
                        data-kt-scroll-height='auto'
                        data-kt-scroll-wrappers='#side_activities_body'
                        data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                        data-kt-scroll-offset='5px'
                      >
                        <div>
                          {isLoading ? (
                            <div className='card h-350px'>
                              <div className='m-auto d-flex flex-column align-items-center'>
                                <div className='spinner-border spinner-primary mr-15'></div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.ASSET_TYPE'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        as='select'
                                        className='form-select form-select-solid'
                                        id='floatingInput'
                                        name='asset_type_id'
                                        value={values.asset_type_id}
                                        onChange={(e) =>
                                          setFieldValue('asset_type_id', e.target.value)
                                        }
                                      >
                                        <option value=''>
                                          {' '}
                                          {intl.formatMessage({id: 'LABEL.SELECT_ASSET_TYPE'})}
                                        </option>

                                        {assettypeDetails?.map((assetType) => (
                                          <option
                                            key={assetType.asset_type_id}
                                            value={assetType.asset_type_id}
                                          >
                                            {assetType.asset_type_name}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name='asset_type_id'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.asset_type_name}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='brand'
                                        className='form-control form-control-solid'
                                        placeholder={intl.formatMessage({id: 'LABEL.ASSET_BRAND'})}
                                      />
                                      <ErrorMessage
                                        name='brand'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.brand}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.ASSET_MAKE'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='make'
                                        className='form-control form-control-solid'
                                        placeholder={intl.formatMessage({id: 'LABEL.ASSET_MAKE'})}
                                      ></Field>
                                      <ErrorMessage
                                        name='make'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.make}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {' '}
                                  {intl.formatMessage({id: 'LABEL.MODEL'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='model'
                                        className='form-control form-control-solid'
                                        placeholder={intl.formatMessage({id: 'LABEL.MODEL'})}
                                      />
                                      <ErrorMessage
                                        name='model'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.model}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        as='select'
                                        name='country_of_manufacture'
                                        className='form-select form-select-solid'
                                        value={values.country_of_manufacture || ''}
                                        onChange={(e) =>
                                          setFieldValue('country_of_manufacture', e.target.value)
                                        }
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.SELECT_COUNTRY',
                                        })}
                                      >
                                        <option value=''>
                                          {' '}
                                          {intl.formatMessage({id: 'LABEL.SELECT_COUNTRY'})}
                                        </option>
                                        {countries?.map((countryName) => (
                                          <option
                                            key={countryName.country_id}
                                            value={countryName.country_id}
                                          >
                                            {countryName.country_name}
                                          </option>
                                        ))}
                                      </Field>

                                      <ErrorMessage
                                        name='country_of_manufacture'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.country_name}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.CURRENCY'})}
                                </label>{' '}
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        as='select'
                                        className='form-select form-select-solid'
                                        id='floatingInput'
                                        name='currency_id'
                                        value={values.currency_id}
                                        onChange={(e) =>
                                          setFieldValue('currency_id', e.target.value)
                                        }
                                      >
                                        <option key='' value=''>
                                          {intl.formatMessage({id: 'LABEL.SELECT_CURRENCY'})}
                                        </option>

                                        {currencytype?.map((currencyType) => (
                                          <option
                                            key={currencyType.currency_id}
                                            value={currencyType.currency_id}
                                          >
                                            {currencyType.currency_name}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name='currency_id'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.currency_name}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.SERIAL_NUMBER'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='serial_number'
                                        className='form-control form-control-solid'
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.SERIAL_NUMBER',
                                        })}
                                      />
                                      <ErrorMessage
                                        name='serial_number'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.serial_number}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                  {intl.formatMessage({id: 'LABEL.PURCHASE_NUMBER'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='pr_no'
                                        className='form-control form-control-solid'
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.PURCHASE_NUMBER',
                                        })}
                                      />
                                      <ErrorMessage
                                        name='pr_no'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.pr_no}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-5 d-flex align-items-center'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.PURCHASE_DATE'})}
                                </label>
                                <div className='col-lg-8 text-start'>
                                  {isEditMode ? (
                                    <>
                                      <Field name='pr_date'>
                                        {({form, field}) => {
                                          const {setFieldValue} = form
                                          const {value} = field

                                          const handleChange = (date) => {
                                            setFieldValue('pr_date', date)
                                          }

                                          const initialValue = value ? new Date(value) : null

                                          return (
                                            <div
                                              className={`react-datepicker-fullwidth-wrapper test ${
                                                mode === 'dark' ? 'dark-mode' : 'light-mode'
                                              }`}
                                            >
                                              <DatePicker
                                                className='form-control form-control-solid'
                                                selected={initialValue}
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                dateFormat='dd/MM/yyyy'
                                                onChange={(date) => {
                                                  handleChange(date)
                                                }}
                                                placeholderText={intl.formatMessage({
                                                  id: 'LABEL.SELECT_DATE',
                                                })}
                                                showMonthDropdown
                                                useShortMonthInDropdown
                                              />
                                            </div>
                                          )
                                        }}
                                      </Field>
                                      <ErrorMessage
                                        name='pr_date'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.pr_date}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.PURCHASE_AMOUNT'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='number'
                                        name='po_amount'
                                        className='form-control form-control-solid'
                                        value={values.po_amount || ''}
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.SELECT_AMOUNT',
                                        })}
                                        onChange={(e) => setFieldValue('po_amount', e.target.value)}
                                      />
                                      <ErrorMessage
                                        name='po_amount'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.po_amount}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.UNIT_RATE'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='number'
                                        name='unit_rate'
                                        className='form-control form-control-solid'
                                        value={values.unit_rate || ''}
                                        placeholder={intl.formatMessage({id: 'LABEL.UNIT_RATE'})}
                                        onChange={(e) => setFieldValue('unit_rate', e.target.value)}
                                      />
                                      <ErrorMessage
                                        name='unit_rate'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.unit_rate}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.PURCHASE_ORDER'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='po_no'
                                        className='form-control form-control-solid'
                                        value={values.po_no || ''}
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.PURCHASE_ORDER',
                                        })}
                                        onChange={(e) => setFieldValue('po_no', e.target.value)}
                                      />
                                      <ErrorMessage
                                        name='po_no'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.po_no}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-5 d-flex align-items-center'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.ORDER_DATE'})}
                                </label>
                                <div className='col-lg-8 text-start'>
                                  {isEditMode ? (
                                    <>
                                      <Field name='po_date'>
                                        {({form, field}) => {
                                          const {setFieldValue} = form
                                          const {value} = field

                                          const handleChange = (date) => {
                                            setFieldValue('po_date', date)
                                          }
                                          const initialValue = value ? new Date(value) : null

                                          return (
                                            <div
                                              className={`react-datepicker-fullwidth-wrapper test ${
                                                mode === 'dark' ? 'dark-mode' : 'light-mode'
                                              }`}
                                            >
                                              <DatePicker
                                                className='form-control form-control-solid'
                                                selected={initialValue}
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                dateFormat='dd/MM/yyyy'
                                                onChange={(date) => {
                                                  handleChange(date)
                                                }}
                                                placeholderText={intl.formatMessage({
                                                  id: 'LABEL.SELECT_DATE',
                                                })}
                                                showMonthDropdown
                                                useShortMonthInDropdown
                                              />
                                            </div>
                                          )
                                        }}
                                      </Field>
                                      <ErrorMessage
                                        name='po_date'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.po_date}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.SUPPLIER'})}
                                </label>

                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='supplier'
                                        className='form-control form-control-solid'
                                        value={values.supplier || ''}
                                        placeholder={intl.formatMessage({id: 'LABEL.SUPPLIER'})}
                                        onChange={(e) => setFieldValue('supplier', e.target.value)}
                                      />
                                      <ErrorMessage
                                        name='supplier'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.supplier}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.WORKING_STATUS'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='working_status'
                                        className='form-control form-control-solid'
                                        value={values.working_status || ''}
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.WORKING_STATUS',
                                        })}
                                        onChange={(e) =>
                                          setFieldValue('working_status', e.target.value)
                                        }
                                      />
                                      <ErrorMessage
                                        name='working_status'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.working_status}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.SALVAGE_VALUE'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='number'
                                        name='salvage_value'
                                        className='form-control form-control-solid'
                                        value={values.salvage_value || ''}
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.SALVAGE_VALUE',
                                        })}
                                        onChange={(e) =>
                                          setFieldValue('salvage_value', e.target.value)
                                        }
                                      />
                                      <ErrorMessage
                                        name='salvage_value'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.salvage_value}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.DEPRECIATION_PERCENTAGE'})}
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='number'
                                        name='depreciation_percentage'
                                        className='form-control form-control-solid'
                                        value={values.depreciation_percentage || ''}
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.DEPRECIATION_PERCENTAGE',
                                        })}
                                        onChange={(e) =>
                                          setFieldValue('depreciation_percentage', e.target.value)
                                        }
                                      />
                                      <ErrorMessage
                                        name='depreciation_percentage'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.depreciation_percentage}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.ASSET_LIFE_SPAN'})}(in yrs)
                                </label>
                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='number'
                                        name='asset_lifespan'
                                        className='form-control form-control-solid'
                                        value={values.asset_lifespan || ''}
                                        placeholder={intl.formatMessage({
                                          id: 'LABEL.ASSET_LIFE_SPAN',
                                        })}
                                        onChange={(e) =>
                                          setFieldValue('asset_lifespan', e.target.value)
                                        }
                                      />
                                      <ErrorMessage
                                        name='asset_lifespan'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.asset_lifespan}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.ASSET_TAG_ID'})}
                                </label>

                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <Field
                                        type='text'
                                        name='asset_tag'
                                        className='form-control form-control-solid'
                                        value={values.asset_tag || ''}
                                        placeholder={intl.formatMessage({id: 'LABEL.ASSET_TAG_ID'})}
                                        onChange={(e) => setFieldValue('asset_tag', e.target.value)}
                                      />
                                      <ErrorMessage
                                        name='asset_tag'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.asset_tag}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-5 d-flex align-items-center'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.INSTALLATION_DATE'})}
                                </label>

                                <div className='col-lg-8 text-start'>
                                  {isEditMode ? (
                                    <>
                                      <Field name='first_installation_date'>
                                        {({form, field}) => {
                                          const {setFieldValue} = form
                                          const {value} = field

                                          const handleChange = (date) => {
                                            setFieldValue('first_installation_date', date)
                                          }
                                          const initialValue = value ? new Date(value) : null

                                          return (
                                            <div
                                              className={`react-datepicker-fullwidth-wrapper test ${
                                                mode === 'dark' ? 'dark-mode' : 'light-mode'
                                              }`}
                                            >
                                              <DatePicker
                                                className='form-control form-control-solid'
                                                selected={initialValue}
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                dateFormat='dd/MM/yyyy'
                                                onChange={(date) => {
                                                  handleChange(date)
                                                }}
                                                placeholderText={intl.formatMessage({
                                                  id: 'LABEL.SELECT_DATE',
                                                })}
                                                showMonthDropdown
                                                useShortMonthInDropdown
                                              />
                                            </div>
                                          )
                                        }}
                                      </Field>
                                      <ErrorMessage
                                        name='first_installation_date'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.first_installation_date}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-5 d-flex align-items-center'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.WARRANTY_START_DATE'})}
                                </label>
                                <div className='col-lg-8 text-start'>
                                  {isEditMode ? (
                                    <>
                                      <Field name='warranty_start_date'>
                                        {({form, field}) => {
                                          const {setFieldValue} = form
                                          const {value} = field

                                          const handleChange = (date) => {
                                            setFieldValue('warranty_start_date', date)
                                          }
                                          const initialValue = value ? new Date(value) : null

                                          return (
                                            <div
                                              className={`react-datepicker-fullwidth-wrapper test ${
                                                mode === 'dark' ? 'dark-mode' : 'light-mode'
                                              }`}
                                            >
                                              <DatePicker
                                                className='form-control form-control-solid'
                                                selected={initialValue}
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                dateFormat='dd/MM/yyyy'
                                                onChange={(date) => {
                                                  handleChange(date)
                                                }}
                                                placeholderText={intl.formatMessage({
                                                  id: 'LABEL.START_DATE',
                                                })}
                                                showMonthDropdown
                                                useShortMonthInDropdown
                                              />
                                            </div>
                                          )
                                        }}
                                      </Field>
                                      <ErrorMessage
                                        name='warranty_start_date'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.warranty_start_date}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-5 d-flex align-items-center'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.WARRANTY_END_DATE'})}
                                </label>
                                <div className='col-lg-8 text-start'>
                                  {isEditMode ? (
                                    <>
                                      <Field name='warranty_expiry_date'>
                                        {({form, field}) => {
                                          const {setFieldValue} = form
                                          const {value} = field

                                          const handleChange = (date) => {
                                            setFieldValue('warranty_expiry_date', date)
                                          }
                                          const initialValue = value ? new Date(value) : null

                                          return (
                                            <div
                                              className={`react-datepicker-fullwidth-wrapper test ${
                                                mode === 'dark' ? 'dark-mode' : 'light-mode'
                                              }`}
                                            >
                                              <DatePicker
                                                className='form-control form-control-solid'
                                                selected={initialValue}
                                                isClearable
                                                showYearDropdown
                                                scrollableYearDropdown
                                                dateFormat='dd/MM/yyyy'
                                                onChange={(date) => {
                                                  handleChange(date)
                                                }}
                                                placeholderText={intl.formatMessage({
                                                  id: 'LABEL.END_DATE',
                                                })}
                                                showMonthDropdown
                                                useShortMonthInDropdown
                                              />
                                            </div>
                                          )
                                        }}
                                      </Field>
                                      <ErrorMessage
                                        name='warranty_expiry_date'
                                        component='div'
                                        className='text-danger'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.warranty_expiry_date}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className='row mb-4'>
                                <label className='col-lg-4 fw-bold text-muted text-start'>
                                  {intl.formatMessage({id: 'LABEL.IN_STOCK'})}
                                </label>

                                <div className='col-lg-8'>
                                  {isEditMode ? (
                                    <>
                                      <div className='form-check form-check-solid form-switch'>
                                        <Field
                                          className='form-check-input w-40px h-20px'
                                          type='checkbox'
                                          id='toggle'
                                          name='in_stock'
                                          checked={values.in_stock}
                                          onChange={(e) =>
                                            setFieldValue('in_stock', e.target.checked)
                                          }
                                        />

                                        <span
                                          className={`me-4 fw-bold ${
                                            values.in_stock ? 'text-success' : 'text-danger'
                                          }`}
                                        >
                                          {values.in_stock ? 'Yes' : 'No'}
                                        </span>
                                      </div>
                                      <ErrorMessage
                                        component='div'
                                        className='text-danger'
                                        name='in_stock'
                                      />
                                    </>
                                  ) : (
                                    <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                                      {assetData?.in_stock === 1 ? 'Yes' : 'No'}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer text-end' id='side_activities_footer'>
                    {isEditMode ? (
                      <>
                        {!isEditMode ? (
                          <button
                            className='btn btn-sm btn-secondary m-2'
                            type='button'
                            id='side_activities_close'
                            onClick={handleSaveClick}
                          >
                            {intl.formatMessage({id: 'BTN.CLOSE'})}
                          </button>
                        ) : (
                          <div className='btn btn-sm btn-secondary m-2' onClick={handleSaveClick}>
                            {intl.formatMessage({id: 'LABEL.CANCEL'})}
                          </div>
                        )}
                        <button
                          className='btn  btn-sm btn-primary'
                          type='submit'
                          disabled={!isEditMode}
                        >
                          {intl.formatMessage({id: 'BTN.SAVE'})}
                        </button>
                      </>
                    ) : (
                      <button
                        className='btn btn-sm btn-secondary m-2 mt-0'
                        type='button'
                        id='side_activities_close'
                        onClick={handleSaveClick}
                      >
                        {intl.formatMessage({id: 'BTN.CLOSE'})}
                      </button>
                    )}
                  </div>
                </Form>
              </>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {EditAsset}
