import {FC, useState, useEffect} from 'react'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'

import React from 'react'

import useApiCall from '../../custom_hooks/useApiCall'
import InterviewHR from './InterviewHR'
import {useIntl} from 'react-intl'

const InterviewsDrawer = (props: any) => {
  const {data, fetchData, isLoading}: any | null = useApiCall(
    `/recruitment/interviews/${props.props.id}`
  )

  let interviewData = data && data[0]
  const intl = useIntl()
  const [feedback, setFeedback] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [candidate_id, setCandidateID] = useState<string>('')
  const [candidate_fname, setCandidateFname] = useState<string>('')

  const [candidate_lname, setCandidateLname] = useState<string>('')
  const [candidate_email, setcandidateEmail] = useState<string>('')
  const [candidate_contact_no, setcandidateContactNo] = useState<string>('')
  const [interviewer_name, setInterviewerName] = useState<string>('')
  const [interview_mode, setInterviewerMode] = useState<string>('')
  const [interview_type, setInterviewerType] = useState<string>('')
  const [interview_location, setInterviewerLocation] = useState<string>('')

  useEffect(() => {
    if (interviewData) {
      setFeedback(interviewData?.feedback)
      setStatus(interviewData?.status)
      setCandidateID(interviewData?.candidate_id)
      setCandidateFname(interviewData?.candidate_fname)

      setCandidateLname(interviewData?.candidate_lname)
      setcandidateEmail(interviewData?.candidate_email)
      setcandidateContactNo(interviewData?.candidate_contact_no)
      setInterviewerName(interviewData?.interviewer_name)
      setInterviewerMode(interviewData?.interview_mode)
      setInterviewerType(interviewData?.interview_type)
      setInterviewerLocation(interviewData?.interview_location)
    }
  }, [interviewData])

  useEffect(() => {
    fetchData()
  }, [props.props.id])

  const hrDetails = {
    feedback: feedback || '',
    status: status || '',

    fetchData: fetchData,
  }

  return (
    <div className='card shadow-none rounded-0'>
      <div>
        <div className='card-header' id='side_activities_header'>
          <h3 className='card-title fw-bolder text-dark'>
            {' '}
            {intl.formatMessage({id: 'MENU.RECRUITMENT.INTERVIEWS'})}
          </h3>
          <div className='card-toolbar '>
            <React.Fragment>
              <div className='btn btn-icon btn-sm btn-active-danger' id='side_activities_close'>
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </React.Fragment>
          </div>
        </div>

        <div className='card-body position-relative' id='side_activities_body'>
          <div>
            <div
              id='side_activities_scroll'
              className='position-relative scroll-y me-n5 pe-5 w-400px'
              data-kt-scroll='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#side_activities_body'
              data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
              data-kt-scroll-offset='5px'
            >
              {isLoading ? (
                <div className='card h-350px'>
                  <div className='m-auto d-flex flex-column align-items-center'>
                    <div className='spinner-border spinner-primary mr-15'></div>
                  </div>
                </div>
              ) : (
                <>
                  {/* <div className='d-flex  mb-5'>
                    <div className='d-flex'>
                      <img
                        src={toAbsoluteUrl(`/media/avatars/300-${candidate_id}.jpg`)}
                        alt=''
                        className='h-80px w-80px me-2'
                      />
                      <div className=''>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-5'>
                          {candidate_fname}
                        </a>
                        <br />

                        <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                          {candidate_email}
                        </a>
                        <br />
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                          {candidate_contact_no}
                        </a>
                        <br />
                      </div>
                    </div>
                  </div> */}
                  <div className=' mb-7'>
                    <div className='row mb-4'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        {intl.formatMessage({id: 'LABEL.CANDIDATE_NAME'})}
                      </label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                          {candidate_fname}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-4'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        {intl.formatMessage({id: 'LABEL.INTERVIEW_TYPE'})}
                      </label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                          {interview_type}
                        </span>
                      </div>
                    </div>

                    <div className='row mb-4'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        {intl.formatMessage({id: 'LABEL.INTERVIEW_MODE'})}
                      </label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                          {interview_mode}
                        </span>
                      </div>
                    </div>

                    <div className='row mb-4'>
                      <label className='col-lg-4 fw-bold text-muted'>
                        {intl.formatMessage({id: 'LABEL.LOCATION'})}
                      </label>
                      <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark text-hover-primary default-cursor'>
                          {interview_location}
                        </span>
                      </div>
                    </div>

                    <h5 className='mt-4 text-dark'>{intl.formatMessage({id: 'LABEL.WORKFLOW'})}</h5>
                    <hr className='text-dark mt-2' />
                    <div className='card-body position-relative p-0' id='kt_activities_body'>
                      <div className='timeline'>
                        <InterviewHR hrDetails={hrDetails} hrID={props.props.id} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InterviewsDrawer
