import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import {GetAllInputs} from '../PerformanceAPI'
import secureLocalStorage from 'react-secure-storage'
import es from 'date-fns/locale/es'
import ar from 'date-fns/locale/ar'
import fr from 'date-fns/locale/fr'
import {useLocation} from 'react-router-dom'
import usePageTitle from '../../custom_hooks/usePageTitle'
import DateFormatter from '../../DateFormatter'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../../_metronic/partials'
import DatePicker from 'react-datepicker'
import useApiCall from '../../custom_hooks/useApiCall'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import React from 'react'
import {registerLocale} from 'react-datepicker'

const DataTable = ({columns, data, loading, fetchData}) => {
  const [inputVal, setInputVal] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const [currentStatus, setCurrentStatus] = useState('Pending')
  const {mode} = useThemeMode()
  const [locale, setLocale] = React.useState('en')
  const lang = useLang()
  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.PERFORMANCE_MANAGEMENT.MY_INPUTS'}))

  const filteredData = useMemo(() => {
    return data
      ? data.filter((each) => {
          const kpiMatches =
            each.kpi_name && each.kpi_name.toLowerCase().includes(inputVal.toLowerCase())

          const kpiStatus =
            each.performance_input_status !== 'Approved' &&
            each.performance_input_status !== 'Rejected'
              ? 'Pending'
              : each.performance_input_status
          const currentStatusMatches =
            kpiStatus && kpiStatus.toLowerCase() === currentStatus.toLowerCase()

          return kpiMatches && currentStatusMatches
        })
      : []
  }, [inputVal, data, currentStatus])
  useEffect(() => {
    if (lang === 'ar') {
      setLocale('ar')
    } else {
      setLocale(lang)
    }
  })

  const filteredDataByDate = useMemo(() => {
    if (!selectedDate) {
      return filteredData
      // No date selected, return the current filtered data
    }

    // Adjust the selected date to account for the time zone offset
    const selectedDateStr = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10) // Convert selected date to 'yyyy-MM-dd' format

    return filteredData.filter((each) => {
      return each.created_timestamp.slice(0, 10) === selectedDateStr
    })
  }, [selectedDate, filteredData])

  const tableInstance = useTable({columns, data: filteredDataByDate}, usePagination)
  registerLocale('es', es)
  registerLocale('ar', ar)
  registerLocale('fr', fr)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageIndex, pageSize},
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = tableInstance

  const pageOptions = [5, 10, 20, 30, 40, 50]

  // Render the component
  return (
    <Context.Consumer>
      {(value) => {
        const {id, updateId} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='d-flex'>
                  <div className='d-flex flex-wrap flex-stack mb-6'>
                    <h3 className='fw-bolder my-2'>
                      {intl.formatMessage({id: 'MENU.PERFORMANCE_MANAGEMENT.MY_INPUTS'})}
                    </h3>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header border-0 pt-5'>
                    <div className='d-flex flex-wrap flex-stack'>
                      <div className='fw-bolder'>
                        <ul className='nav'>
                          <li className='nav-item'>
                            <a
                              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                              data-bs-toggle='tab'
                              onClick={() => setCurrentStatus('Pending')}
                            >
                              {intl.formatMessage({id: 'LABEL.PENDING'})}
                            </a>
                          </li>
                          <li className='nav-item'>
                            <a
                              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                              data-bs-toggle='tab'
                              onClick={() => setCurrentStatus('Approved')}
                            >
                              {intl.formatMessage({id: 'LABEL.APPROVED'})}
                            </a>
                          </li>

                          <li className='nav-item'>
                            <a
                              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                              data-bs-toggle='tab'
                              onClick={() => setCurrentStatus('Rejected')}
                            >
                              {intl.formatMessage({id: 'LABEL.REJECTED'})}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='card-toolbar'>
                      <div className='d-flex align-items-center position-relative me-4'>
                        <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                        <input
                          type='search'
                          id='kt_filter_search'
                          className='form-control form-control-white form-control-sm w-150px ps-9'
                          placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                          value={inputVal}
                          onChange={(e) => setInputVal(e.target.value)}
                        />
                      </div>

                      {/* <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <KTIcon iconName='calendar' className='fs-2 m-0' />
                      </button> */}

                      <div
                        className={`react-datepicker-fullwidth-wrapper test me-4 ${
                          mode === 'dark' ? 'dark-mode' : 'light-mode'
                        }`}
                      >
                        <DatePicker
                          className='form-control form-control-sm'
                          selected={selectedDate}
                          isClearable
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormat='dd/MM/yyyy'
                          onChange={(date) => setSelectedDate(date)}
                          placeholderText={intl.formatMessage({id: 'LABEL.SELECT_DATE'})}
                          showMonthDropdown
                          useShortMonthInDropdown
                          locale={locale}
                        />
                      </div>

                      <button
                        className='btn btn-sm btn-light-primary mx-2'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateId({
                            type: 'add_epi',
                            fetchData,
                          })
                        }}
                      >
                        <KTIcon iconName='plus' className='fs-3' />
                        {intl.formatMessage({id: 'LABEL.ADD_EPI'})}
                      </button>
                    </div>
                  </div>
                  <div className='tab-content p-2 pt-0'>
                    <div className='card-body tab-pane fade show active table-responsive'>
                      <table
                        className='table table-row-dashed table-row-gray-300 align-middle'
                        {...getTableProps()}
                      >
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.KPI_NAME'})}
                            </th>
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.TASK_NAME'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.START_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.END_DATE'})}
                            </th>

                            <th className='min-w-140px  text-muted text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                            </th>

                            {/* <th className='min-w-100px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.STATUS'})}
                            </th> */}
                          </tr>
                        </thead>

                        <tbody {...getTableBodyProps()}>
                          {page && page.length > 0 ? (
                            page.map((row) => {
                              prepareRow(row)
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => (
                                    <td className='text-center' {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  ))}
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td>
                                <p></p>
                              </td>
                              <td className='card'>
                                <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                  {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {filteredData.length > 10 && (
                        <Pagination>
                          <Pagination.First
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                          />
                          <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />

                          {[...Array(pageCount)].map((_, i) => (
                            <Pagination.Item
                              key={i}
                              active={i === pageIndex}
                              onClick={() => gotoPage(i)}
                            >
                              {i + 1}
                            </Pagination.Item>
                          ))}

                          <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
                          <Pagination.Last
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                          />

                          <div className='d-flex align-items-center mx-5'>
                            <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                            <select
                              className='form-select form-select-solid fw-bold w-75px me-2'
                              value={pageSize}
                              onChange={(e) => setPageSize(Number(e.target.value))}
                            >
                              {pageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Pagination>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1>{intl.formatMessage({id: 'LABEL.NO_INPUTS_FOUND'})}</h1>
                  <button
                    className='btn btn-sm btn-light-primary mt-3'
                    id='side_activities_toggle'
                    onClick={() => {
                      updateId({
                        type: 'add_epi',
                        fetchData,
                      })
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    {intl.formatMessage({id: 'LABEL.ADD_EPI'})}
                  </button>
                </div>
              </div>
            )}
            <SideDrawer value={id} />
          </>
        )
      }}
    </Context.Consumer>
  )
}

const MyInputsTable = () => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }

  const {performanceData, isperformanceLoading: isLoading, fetchData} = GetAllInputs()
  // const location = useLocation()

  // useEffect(() => {
  //   const targetElement = document.getElementById('side_activities')
  //   if (targetElement && location.pathname === '/performance-management/my-inputs') {
  //     const body = document.querySelector('body')
  //     const observer = new MutationObserver(() => {
  //       if (!body.hasAttribute('data-kt-drawer-side-activities')) {
  //         fetchData()
  //       }
  //     })
  //     observer.observe(targetElement, {attributes: true})
  //     return () => {
  //       observer.disconnect()
  //     }
  //   }
  // }, [location.pathname, fetchData])

  const columns = useMemo(
    () => [
      {
        Header: 'Kpi Name',
        accessor: 'Kpi Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.kpi_name}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Activity',
        accessor: 'Activity',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.activity}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Start Date',
        accessor: 'Start Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.emp_start_date)}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'End Date',
        accessor: 'End Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.emp_end_date)}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Raised Date',
        accessor: 'Raised Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {DateFormatter(row.original.created_timestamp.slice(0, 10))}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={performanceData !== null ? performanceData : []}
      loading={isLoading}
      fetchData={fetchData}
    />
  )
}

export default MyInputsTable
