import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import secureLocalStorage from 'react-secure-storage'
import {useLocation} from 'react-router-dom'
import usePageTitle from '../../custom_hooks/usePageTitle'
import {useAuth} from '../../auth'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import useApiCall from '../../custom_hooks/useApiCall'
import {defaultProfileImage} from '../../core'
import {useThemeMode} from '../../../../_metronic/partials'

const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)

  const {mode} = useThemeMode()

  const intl = useIntl()
  usePageTitle('Team Reviews')

  const filteredData = useMemo(() => {
    return data?.filter((each) => each.kpi_name.toLowerCase().includes(inputVal.toLowerCase()))
  }, [data, inputVal])

  const filteredDataByDate = useMemo(() => {
    if (!selectedDate) {
      return filteredData
      // No date selected, return the current filtered data
    }

    // Adjust the selected date to account for the time zone offset
    const selectedDateStr = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10) // Convert selected date to 'yyyy-MM-dd' format

    return filteredData.filter((each) => {
      return each.created_timestamp.slice(0, 10) === selectedDateStr
    })
  }, [selectedDate, filteredData])
  // Create separate datasets for active and inactive records

  // Table instances for active and inactive datasets
  const activeData = useMemo(() => {
    return filteredDataByDate?.filter((request) => request.performance_input_status === 'Pending')
  }, [filteredDataByDate])

  const inactiveData = useMemo(() => {
    return filteredDataByDate?.filter((request) => request.performance_input_status === 'Approved')
  }, [filteredDataByDate])

  const RejectedData = useMemo(() => {
    return filteredDataByDate?.filter((request) => request.performance_input_status === 'Rejected')
  }, [filteredDataByDate])

  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)
  const RejectedDataInstance = useTable({columns, data: RejectedData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  const RejectedPage = RejectedDataInstance.page
  const RejectedPageCount = RejectedDataInstance.pageCount
  const RejectedPageIndex = RejectedDataInstance.state.pageIndex
  const RejectedPageSize = RejectedDataInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }

  const gotoRejectedPage = (pageIndex) => {
    RejectedDataInstance.gotoPage(pageIndex)
  }

  const setRejectedPage = (pageSize) => {
    RejectedDataInstance.setPageSize(pageSize)
  }

  const pageOptions = [5, 10, 20, 30, 40, 50]

  const handleDatesChange = () => {
    setSelectedDate(null)
  }

  // Render the component
  return (
    <Context.Consumer>
      {(value) => {
        const {id, updateId} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='card'>
                  <div className='card-header border-0 pt-5'>
                    <div className='d-flex flex-wrap flex-stack'>
                      <div className='fw-bolder'>
                        <ul className='nav'>
                          <li className='nav-item'>
                            <a
                              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                              data-bs-toggle='tab'
                              href='#kt_table_widget_4_tab_1'
                            >
                              Pending
                            </a>
                          </li>
                          <li className='nav-item'>
                            <a
                              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                              data-bs-toggle='tab'
                              href='#kt_table_widget_4_tab_2'
                            >
                              Approved
                            </a>
                          </li>

                          <li className='nav-item'>
                            <a
                              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                              data-bs-toggle='tab'
                              href='#kt_table_widget_4_tab_3'
                            >
                              Rejected
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='card-toolbar'>
                      <div className='d-flex align-items-center position-relative me-4'>
                        <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                        <input
                          type='text'
                          id='kt_filter_search'
                          className='form-control form-control-white form-control-sm w-150px ps-9'
                          placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                          value={inputVal}
                          onChange={(e) => setInputVal(e.target.value)}
                        />
                      </div>

                      {/* <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                        <KTIcon iconName='calendar' className='fs-2 m-0' />
                      </button> */}

                      <div
                        className={`react-datepicker-fullwidth-wrapper test me-4 ${
                          mode === 'dark' ? 'dark-mode' : 'light-mode'
                        }`}
                      >
                        <DatePicker
                          className='form-control form-control-solid'
                          selected={selectedDate}
                          isClearable
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormat='dd/MM/yyyy'
                          onChange={(date) => setSelectedDate(date)}
                          placeholderText={intl.formatMessage({id: 'LABEL.SELECT_DATE'})}
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                      </div>

                      {/* <button
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        onClick={handleDatesChange}
                      >
                        <KTIcon iconName='setting-2' className='fs-2 m-0' />
                      </button> */}
                    </div>
                  </div>
                  <div className='tab-content p-2 pt-0'>
                    <div
                      className='card-body tab-pane fade show active table-responsive'
                      id='kt_table_widget_4_tab_1'
                    >
                      <table
                        className='table table-row-dashed align-middle table-row-gray-300'
                        {...activeTableInstance.getTableProps()}
                      >
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                            </th>
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.ROLE'})}
                            </th>
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.TASK_NAME'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.START_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.END_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.EMP.START_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.EMP.END_DATE'})}
                            </th>

                            <th className='min-w-140px  text-muted text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                            </th>

                            {/* <th className='min-w-100px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.STATUS'})}
                            </th> */}
                            <th className='min-w-100px text-center default-cursor'>
                              {intl.formatMessage({id: 'MENU.ACTIONS'})}
                            </th>
                          </tr>
                        </thead>

                        <tbody {...activeTableInstance.getTableBodyProps()}>
                          {activePage.length > 0 ? (
                            activePage.map((row) => {
                              activeTableInstance.prepareRow(row)
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => (
                                    <td className=' ' {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  ))}
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td>
                                <p></p>
                              </td>
                              <td className='card'>
                                <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                  {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {data.length > 10 && (
                        <Pagination>
                          <Pagination.First
                            onClick={() => gotoActivePage(0)}
                            disabled={!activeTableInstance.canPreviousPage}
                          />
                          <Pagination.Prev
                            onClick={activeTableInstance.previousPage}
                            disabled={!activeTableInstance.canPreviousPage}
                          />
                          {[...Array(activePageCount)].map((_, i) => (
                            <Pagination.Item
                              key={i}
                              active={i === activePageIndex}
                              onClick={() => gotoActivePage(i)}
                            >
                              {i + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={activeTableInstance.nextPage}
                            disabled={!activeTableInstance.canNextPage}
                          />
                          <Pagination.Last
                            onClick={() => gotoActivePage(activePageCount - 1)}
                            disabled={!activeTableInstance.canNextPage}
                          />
                          <div className='d-flex align-items-center mx-5'>
                            <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                            <select
                              className='form-select form-select-solid fw-bold w-75px me-2'
                              value={activePageSize}
                              onChange={(e) => setActivePageSize(Number(e.target.value))}
                            >
                              {pageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Pagination>
                      )}
                    </div>

                    <div
                      className='card-body tab-pane fade  table-responsive'
                      id='kt_table_widget_4_tab_2'
                    >
                      <table
                        className='table table-row-dashed align-middle table-row-gray-300'
                        {...inactiveTableInstance.getTableProps()}
                      >
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                            </th>
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.ROLE'})}
                            </th>
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.TASK_NAME'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.START_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.END_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.EMP.START_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.EMP.END_DATE'})}
                            </th>

                            <th className='min-w-140px  text-muted text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                            </th>

                            {/* <th className='min-w-100px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.STATUS'})}
                            </th> */}
                            <th className='min-w-100px text-center default-cursor'>
                              {intl.formatMessage({id: 'MENU.ACTIONS'})}
                            </th>
                          </tr>
                        </thead>
                        <tbody {...inactiveTableInstance.getTableBodyProps()}>
                          {inactivePage.length > 0 ? (
                            inactivePage.map((row) => {
                              inactiveTableInstance.prepareRow(row)
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => (
                                    <td className=' ' {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  ))}
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td>
                                <p></p>
                              </td>
                              <td className='card'>
                                <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                  {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {data.length > 10 && (
                        <Pagination>
                          <Pagination.First
                            onClick={() => gotoInactivePage(0)}
                            disabled={!inactiveTableInstance.canPreviousPage}
                          />
                          <Pagination.Prev
                            onClick={inactiveTableInstance.previousPage}
                            disabled={!inactiveTableInstance.canPreviousPage}
                          />
                          {[...Array(inactivePageCount)].map((_, i) => (
                            <Pagination.Item
                              key={i}
                              active={i === inactivePageIndex}
                              onClick={() => gotoInactivePage(i)}
                            >
                              {i + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={inactiveTableInstance.nextPage}
                            disabled={!inactiveTableInstance.canNextPage}
                          />
                          <Pagination.Last
                            onClick={() => gotoInactivePage(inactivePageCount - 1)}
                            disabled={!inactiveTableInstance.canNextPage}
                          />
                          <div className='d-flex align-items-center mx-5'>
                            <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                            <select
                              className='form-select form-select-solid fw-bold w-75px me-2'
                              value={inactivePageSize}
                              onChange={(e) => setInactivePageSize(Number(e.target.value))}
                            >
                              {pageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Pagination>
                      )}
                    </div>
                    <div
                      className='card-body tab-pane fade show  table-responsive'
                      id='kt_table_widget_4_tab_3'
                    >
                      <table
                        className='table table-row-dashed align-middle table-row-gray-300'
                        {...RejectedDataInstance.getTableProps()}
                      >
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.CANDIDATE'})}
                            </th>
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.ROLE'})}
                            </th>
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.TASK_NAME'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.START_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.END_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.EMP.START_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.EMP.END_DATE'})}
                            </th>

                            <th className='min-w-140px  text-muted text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                            </th>
                            {/* 
                            <th className='min-w-100px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.STATUS'})}
                            </th> */}
                            <th className='min-w-100px text-center default-cursor'>
                              {intl.formatMessage({id: 'MENU.ACTIONS'})}
                            </th>
                          </tr>
                        </thead>

                        <tbody {...RejectedDataInstance.getTableBodyProps()}>
                          {RejectedPage.length > 0 ? (
                            RejectedPage.map((row) => {
                              RejectedDataInstance.prepareRow(row)
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => (
                                    <td className=' ' {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  ))}
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td>
                                <p></p>
                              </td>
                              <td className='card'>
                                <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                  {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {data.length > 10 && (
                        <Pagination>
                          <Pagination.First
                            onClick={() => gotoRejectedPage(0)}
                            disabled={!RejectedDataInstance.canPreviousPage}
                          />
                          <Pagination.Prev
                            onClick={RejectedDataInstance.previousPage}
                            disabled={!RejectedDataInstance.canPreviousPage}
                          />
                          {[...Array(RejectedPageCount)].map((_, i) => (
                            <Pagination.Item
                              key={i}
                              active={i === RejectedPageIndex}
                              onClick={() => gotoRejectedPage(i)}
                            >
                              {i + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={RejectedDataInstance.nextPage}
                            disabled={!RejectedDataInstance.canNextPage}
                          />
                          <Pagination.Last
                            onClick={() => gotoRejectedPage(RejectedPageCount - 1)}
                            disabled={!RejectedDataInstance.canNextPage}
                          />
                          <div className='d-flex align-items-center mx-5'>
                            <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                            <select
                              className='form-select form-select-solid fw-bold w-75px me-2'
                              value={RejectedPageSize}
                              onChange={(e) => setRejectedPage(Number(e.target.value))}
                            >
                              {pageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Pagination>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1>{intl.formatMessage({id: 'LABEL.NO_INPUTS_FOUND'})}</h1>
                </div>
              </div>
            )}
            <SideDrawer value={id} />
          </>
        )
      }}
    </Context.Consumer>
  )
}

const TeamReviewsTable = () => {
  let emp_id
  let user_id
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({emp_id, user_id} = userInfo)
  }
  const {currentUser} = useAuth()
  const {
    data: filteredDataByDate,
    isLoading,
    fetchData,
  } = useApiCall(emp_id ? `/performance/TeamEpi/${emp_id}` : null)

  const columns = useMemo(
    () => [
      {
        Header: 'Employee Name',
        accessor: 'employee_name',
        Cell: ({row}) => {
          return (
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-45px me-5'>
                <img
                  src={
                    row.original.employee_profile_pic
                      ? row.original.employee_profile_pic
                      : defaultProfileImage
                  }
                  alt='img'
                />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <p className='text-dark fw-bold text-hover-primary default-cursor fs-6 m-0'>
                  {row.original.first_name} {row.original.middle_name} {row.original.last_name}
                </p>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-start'>
                  {row.original.employee_code}
                </span>
              </div>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Role Name',
        accessor: 'Role Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.job_role_name}
              </p>
            </div>
          )
        },
      },
      {
        Header: 'Kpi Name',
        accessor: 'Kpi Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.kpi_name}
              </p>
            </div>
          )
        },
      },

      {
        Header: ' Kpi Start Date',
        accessor: ' Kpi Start Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.start_date}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: ' Kpi End Date',
        accessor: ' Kpi End Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.end_date}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Start Date',
        accessor: 'Start Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.emp_start_date}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'End Date',
        accessor: 'End Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.emp_end_date}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Raised Date',
        accessor: 'Raised Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.created_timestamp.slice(0, 10)}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      // {
      //   Header: 'Status',
      //   accessor: 'status',
      //   Cell: ({row}) => {
      //     const statusColor =
      //       row.original.performance_input_status === 'Reviewer Approved' ||
      //       row.original.performance_input_status === 'HR Accepted'
      //         ? 'success'
      //         : row.original.performance_input_status === 'Pending'
      //         ? 'muted'
      //         : 'danger'

      //     return (
      //       <p
      //         className={`text-${
      //           statusColor === 'info' ? 'info' : statusColor
      //         } fw-bold text-hover-primary d-block fs-6 default-cursor`}
      //       >
      //         {row.original.performance_input_status}
      //       </p>
      //     )
      //   },
      // },

      {
        Header: 'Actions',
        accessor: 'Actions',
        Cell: ({row}) => {
          return (
            <Context.Consumer>
              {(value) => {
                const {updateId} = value
                const hrReviewedStatus = row.original.performance_input_status

                return (
                  <div className='d-flex justify-content-center'>
                    {hrReviewedStatus === 'Pending' ? (
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateId({
                            ...row.original,
                            type: 'team_reviews',
                            fetchData,
                          })
                        }}
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </div>
                    ) : (
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        id='side_activities_toggle'
                        onClick={() => {
                          updateId({
                            id: row.original.kpi_id,
                            type: 'team_reviews',
                          })
                        }}
                      >
                        <KTIcon iconName='eye' className='fs-3' />
                      </div>
                    )}
                  </div>
                )
              }}
            </Context.Consumer>
          )
        },
      },
    ],
    []
  )

  return (
    <DataTable
      columns={columns}
      data={filteredDataByDate !== null ? filteredDataByDate : []}
      loading={isLoading}
    />
  )
}

export default TeamReviewsTable
