import DatePicker from 'react-datepicker'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import Swal from 'sweetalert2'
import secureLocalStorage from 'react-secure-storage'
import {useParams} from 'react-router-dom'
import usePostApi from '../../custom_hooks/usePostApi'
import {useThemeMode} from '../../../../_metronic/partials'
import useApiCall from '../../custom_hooks/useApiCall'
import {baseUrl} from '../../core'
import {DateBasicFormat} from '../../DateHelpers'
import {KTIcon} from '../../../../_metronic/helpers'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'

const EditLeaveType = (props: any) => {
  const lang = useLang()
  const {fetchData: refreshData, id: leaveTypeId} = props.props
  const {data: leaveTypeInformation, isLoading}: any | null = useApiCall(
    leaveTypeId ? `/leave/types/${leaveTypeId}` : null
  )
  const leaveTypeData = leaveTypeInformation && leaveTypeInformation[0]
  const intl = useIntl()
  const {execute} = usePostApi()
  const {mode} = useThemeMode()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {organization_id, emp_id} = userInfo || {}
  const brandLogos: any | null = secureLocalStorage.getItem('brand_logos')
  const {brand_logo_url} = brandLogos || {}
  const brandName = localStorage.getItem('displayName') || 'Kloudworx'
  const {data}: any | null = useApiCall(`/organization/1`)
  const locationName = data && data[0]
  const validationSchema = Yup.object().shape({
    leave_type_name: Yup.string().required('Leave Type is Required'),
    leave_type_category: Yup.string().required('Leave Category is Required'),
    start_date: Yup.string().required(intl.formatMessage({id: 'ERR.MSG.START_DATE_IS_REQUIRED'})),
    end_date: Yup.string().required(intl.formatMessage({id: 'ERR.MSG.END_DATE_IS_REQUIRED'})),
    status: Yup.string().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })
  const handleSubmit = async (values: any, {resetForm}: FormikHelpers<any>) => {
    const url = `${baseUrl}/leave/types/${leaveTypeId}`
    try {
      const requestData = {
        organization_id: organization_id,
        employee_id: emp_id,
        leave_type_name: values.leave_type_name,
        leave_type_category: values.leave_type_category,
        carry_forward_allowed: values.carry_forward_allowed ? values.carry_forward_allowed : 'no',
        carry_forward_max_allowed_days:
          values.carry_forward_allowed === 'yes'
            ? values.carry_forward_max_allowed_days
              ? values.carry_forward_max_allowed_days
              : 0
            : 0,
        effective_start_date: DateBasicFormat(values.start_date),
        effective_end_date: DateBasicFormat(values.end_date),
        status: values.status ? 1 : 0,
      }
      await execute(url, 'PATCH', requestData, id)
      resetForm()
      refreshData()
      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.LEAVE_TYPE_EDITED'}),
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('side_activities_close')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <div className='card shadow-none rounded-0'>
        <Formik
          initialValues={{
            leave_type_name: leaveTypeData?.leave_type_name || '',
            leave_type_category: leaveTypeData?.leave_type_category || '',
            start_date: leaveTypeData?.effective_start_date
              ? new Date(leaveTypeData?.effective_start_date)
              : null || '',
            end_date: leaveTypeData?.effective_end_date
              ? new Date(leaveTypeData?.effective_end_date)
              : null || '',
            carry_forward_allowed: leaveTypeData?.carry_forward_allowed || '',
            carry_forward_max_allowed_days: leaveTypeData?.carry_forward_max_allowed_days || '',
            status: leaveTypeData?.status === 1 || '',
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({values, handleChange, resetForm}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL.EDIT_LEAVE_TYPE'})}
                  </h3>
                  <div className='card-toolbar'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                      id='side_activities_close'
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative me-n5 pe-5 w-400px'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      {isLoading ? (
                        <div className='card card-xl-stretch'>
                          <div className='m-auto d-flex flex-column align-items-center'>
                            <div className='spinner-border spinner-primary mr-15'></div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className='d-flex align-items-start'>
                            <img
                              src={brand_logo_url}
                              alt='img'
                              className='activity-img rounded w-65px h-65px me-3'
                            />
                            <div className='mt-0 pt-0'>
                              <p className='text-dark fw-bold text-hover-primary m-0'>
                                {brandName}
                              </p>
                              <p className='text-dark fw-bold text-hover-primary text-muted m-0 mt-1'>
                                <KTIcon iconName='geolocation' className='fs-7 me-1' />
                                {locationName?.country_name}
                              </p>
                              <p className='text-dark fw-bold text-hover-primary text-muted m-0'>
                                <KTIcon iconName='bank' className='fs-7 me-1' />
                                {locationName?.address_line1}
                              </p>
                            </div>
                          </div>
                          <hr className='text-dark mt-4 mb-4' />
                          <div className='timeline'>
                            <div className='timeline-item'>
                              <div className='timeline-line w-40px'></div>
                              <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                <div className='symbol-label bg-light'>
                                  <KTIcon
                                    iconName='message-programming'
                                    className='fs-2 text-gray-500'
                                  />
                                </div>
                              </div>
                              <div className='timeline-content mb-4 mt-n1'>
                                <div className='overflow-auto pe-3'>
                                  <div className='fw-bold mb-2 required'>
                                    {intl.formatMessage({id: 'LABEL.NAME'})}
                                  </div>
                                  <div className='flex-fill'>
                                    <Field
                                      type='text'
                                      className='form-control form-control-solid'
                                      id='floatingInput'
                                      name='leave_type_name'
                                      value={values.leave_type_name}
                                      onChange={handleChange}
                                    />
                                    <ErrorMessage
                                      component='div'
                                      className='text-danger'
                                      name='leave_type_name'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='timeline-item'>
                              <div className='timeline-line w-40px'></div>
                              <div className='timeline-icon symbol symbol-circle symbol-40px'>
                                <div className='symbol-label bg-light'>
                                  <KTIcon iconName='code' className='fs-2 text-gray-500' />
                                </div>
                              </div>
                              <div className='timeline-content mb-8 mt-n2'>
                                <div className='overflow-auto pe-3'>
                                  <div className='fw-bold mb-2 required'>
                                    {intl.formatMessage({id: 'LABEL.CATEGORY'})}
                                  </div>
                                  <div className='flex-fill'>
                                    <Field
                                      as='select'
                                      id='leave_type_category'
                                      name='leave_type_category'
                                      className='form-select form-select-solid'
                                      value={values.leave_type_category}
                                      onChange={handleChange}
                                    >
                                      <option value=''>
                                        {intl.formatMessage({id: 'LABEL.SELECT_CATEGORY_TYPE'})}
                                      </option>
                                      <option value='paid'>Paid</option>
                                      <option value='unpaid'>UnPaid</option>
                                    </Field>
                                    <ErrorMessage
                                      component='div'
                                      className='text-danger'
                                      name='leave_type_category'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className='text-dark mt-4 mb-4' />
                          <div className='d-flex mb-4'>
                            <div className='flex-fill'>
                              <div className='fw-bold mb-2 required'>
                                {intl.formatMessage({id: 'LABEL.START_DATE'})}
                              </div>
                              <Field name='start_date'>
                                {({form, field}: any) => {
                                  const {setFieldValue} = form
                                  const {value} = field
                                  const handleChange = (date: any) => {
                                    setFieldValue('start_date', date)
                                  }
                                  return (
                                    <div
                                      className={`react-datepicker-fullwidth-wrapper test ${
                                        mode === 'dark' ? 'dark-mode' : 'light-mode'
                                      }`}
                                    >
                                      <DatePicker
                                        className='form-control form-control-solid'
                                        selected={value}
                                        isClearable={value !== null && value !== ''}
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dateFormat='dd/MM/yyyy'
                                        onChange={(date) => {
                                          handleChange(date)
                                        }}
                                        placeholderText={intl.formatMessage({
                                          id: 'LABEL.SELECT_START_DATE',
                                        })}
                                        locale={lang || 'en'}
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                      />
                                    </div>
                                  )
                                }}
                              </Field>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='start_date'
                              />
                            </div>
                            <div className=' ms-2'>
                              <div className='fw-bold mb-2 required'>
                                {intl.formatMessage({id: 'LABEL.END_DATE'})}
                              </div>
                              <div className='flex-fill'>
                                <Field name='end_date'>
                                  {({form, field}: any) => {
                                    const {setFieldValue} = form
                                    const {value} = field
                                    const handleChange = (date: any) => {
                                      setFieldValue('end_date', date)
                                    }
                                    return (
                                      <div
                                        className={`react-datepicker-fullwidth-wrapper test ${
                                          mode === 'dark' ? 'dark-mode' : 'light-mode'
                                        }`}
                                      >
                                        <DatePicker
                                          className='form-control form-control-solid'
                                          selected={value}
                                          isClearable={value !== null && value !== ''}
                                          showYearDropdown
                                          scrollableYearDropdown
                                          dateFormat='dd/MM/yyyy'
                                          onChange={(date) => {
                                            handleChange(date)
                                          }}
                                          placeholderText={intl.formatMessage({
                                            id: 'LABEL.SELECT_END_DATE',
                                          })}
                                          locale={lang || 'en'}
                                          showMonthDropdown
                                          useShortMonthInDropdown
                                          minDate={new Date(values.start_date)}
                                        />
                                      </div>
                                    )
                                  }}
                                </Field>
                                <ErrorMessage
                                  component='div'
                                  className='text-danger'
                                  name='end_date'
                                />
                              </div>
                            </div>
                          </div>
                          <div className='mb-4'>
                            <div className=' fw-bold mb-2'>
                              {intl.formatMessage({id: 'LABEL.CARRY_FORWARD'})}
                            </div>
                            <div className='flex-fill'>
                              <Field
                                as='select'
                                id='headName'
                                name='carry_forward_allowed'
                                className='form-select form-select-solid'
                                value={values.carry_forward_allowed}
                                onChange={handleChange}
                              >
                                <option value='no'>No</option>
                                <option value='yes'>Yes</option>
                              </Field>
                              <ErrorMessage
                                component='div'
                                className='text-danger'
                                name='carry_forward_allowed'
                              />
                            </div>
                          </div>
                          {values.carry_forward_allowed === 'yes' && (
                            <div className='mb-4'>
                              <div className=' fw-bold mb-2'>
                                {intl.formatMessage({id: 'LABEL.CARRY_FORWARD_ALLOWED_DAYS'})}
                              </div>
                              <div className='flex-fill'>
                                <Field
                                  type='number'
                                  id='carry_forward_max_allowed_days'
                                  name='carry_forward_max_allowed_days'
                                  className='form-select form-select-solid'
                                  value={values.carry_forward_max_allowed_days}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          )}
                          <div className='form-floating flex-fill'>
                            <div className='form-check form-check-solid form-switch mb-0'>
                              <Field
                                className='form-check-input w-40px h-20px'
                                type='checkbox'
                                name='status'
                                id='toggle'
                              />
                              <span
                                className={`me-4 fw-bold ${
                                  values.status ? 'text-success' : 'text-danger'
                                }`}
                              >
                                {values.status ? 'Active' : 'Inactive'}
                              </span>
                              <ErrorMessage component='div' className='text-danger' name='status' />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className='card-footer text-end' id='side_activities_footer'>
                  <div
                    className='btn btn-sm btn-secondary m-2'
                    onClick={() => handleCancel(resetForm)}
                    id='side_activities_close'
                  >
                    {intl.formatMessage({id: 'LABEL.CANCEL'})}
                  </div>
                  <button className='btn btn-light-primary btn-sm' type='submit'>
                    <KTIcon iconName='check' className='fs-7' />
                    {intl.formatMessage({id: 'BTN.SAVE'})}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {EditLeaveType}
