import {KTIcon} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FormikHelpers} from 'formik'
import Swal from 'sweetalert2'
import {useEffect, useState} from 'react'
import {baseUrl} from '../../core'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../../custom_hooks/useApiCall'
import usePostApi from '../../custom_hooks/usePostApi'
import {useParams} from 'react-router-dom'
const AddLocation = (props: any) => {
  const intl = useIntl()
  const {execute} = usePostApi()
  const {id}: any | null = useParams()
  const userInfo: any | null = secureLocalStorage.getItem('user_info')
  const {organization_id} = userInfo || {}
  const brandLogos: any | null = secureLocalStorage.getItem('brand_logos')
  const {brand_logo_url} = brandLogos || {}
  const brandName = localStorage.getItem('displayName') || 'Kloudworx'
  const {data}: any | null = useApiCall(`/organization/1`)
  const locationName = data && data[0]
  const validationSchema = Yup.object().shape({
    location_name: Yup.string().required(intl.formatMessage({id: 'LABEL.LOCATION_NAME_REQUIRED'})),
    location_code: Yup.string().required(intl.formatMessage({id: 'LABEL.LOCATION_CODE_REQUIRED'})),
    location_tax_id: Yup.string().required(
      intl.formatMessage({id: 'LABEL.LOCATION_TAXID_REQUIRED'})
    ),
    address_line1: Yup.string().required(intl.formatMessage({id: 'LABEL.ADDRESS_LINE1_REQUIRED'})),
    address_line2: Yup.string().required(intl.formatMessage({id: 'LABEL.ADDRESS_LINE2_REQUIRED'})),
    country_name: Yup.string().required(intl.formatMessage({id: 'LABEL.COUNTRY_NAME_REQUIRED'})),
    postal_code: Yup.string().required(intl.formatMessage({id: 'LABEL.POSTAL_CODE_REQUIRED'})),
    status: Yup.boolean().required(intl.formatMessage({id: 'LABEL.STATUS_REQUIRED'})),
  })
  const [countryId, setCountryId] = useState(null)
  const [stateId, setStateId] = useState(null)
  const {data: countriesData}: any | null = useApiCall(`/country`)
  const {data: states, fetchData: fetchStates}: any | null = useApiCall(
    countryId ? `/state/${countryId}` : null
  )
  const {data: citiesData, fetchData: fetchCities}: any | null = useApiCall(
    stateId ? `/city/${stateId}` : null
  )
  let cities: any | null = citiesData ? citiesData : null
  useEffect(() => {
    fetchStates()
    cities = []
  }, [countryId])
  useEffect(() => {
    fetchCities()
  }, [stateId])
  const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    const {resetForm} = formikHelpers
    const url = `${baseUrl}/organization/locations`
    try {
      const requestData = {
        organization_id: organization_id,
        location_name: values.location_name,
        location_code: values.location_code,
        location_tax_id: values.location_tax_id,
        address_line1: values.address_line1,
        address_line2: values.address_line2,
        state_id: parseInt(values.state_name) || 0,
        city_id: parseInt(values.city_name) || 0,
        country_id: parseInt(values.country_name),
        postal_code: values.postal_code,
        status: values.status ? 1 : 0,
      }
      await execute(url, 'POST', requestData, id)
      props.props.fetchData()
      resetForm()
      Swal.fire({
        title: intl.formatMessage({id: 'LABEL.ADDED_SUCCESSFULLY'}),
        text: intl.formatMessage({id: 'LABEL.CHANGES_SAVED'}),
        icon: 'success',
        showConfirmButton: true,
        customClass: {
          confirmButton: 'btn btn-light-primary btn-sm',
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const sideDrawer = document.getElementById('side_activities_close')
          if (sideDrawer) {
            sideDrawer.click()
          }
        }
      })
    } catch (error) {
      console.error(error)
      Swal.fire({
        icon: 'error',
        title: intl.formatMessage({id: 'LABEL.OOPS'}),
        text: intl.formatMessage({id: 'LABEL.SOMETHING_WRONG'}),
      })
    }
  }
  const handleCancel = (resetForm: any) => {
    resetForm()
  }
  return (
    <div>
      <div className='card shadow-none rounded-0'>
        <Formik
          initialValues={{
            location_name: '',
            location_code: '',
            location_tax_id: '',
            address_line1: '',
            address_line2: '',
            state_name: '',
            city_name: '',
            country_name: '',
            postal_code: '',
            status: true,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({resetForm, values, handleChange}) => {
            return (
              <Form>
                <div className='card-header' id='side_activities_header'>
                  <h3 className='card-title fw-bolder text-dark'>
                    {intl.formatMessage({id: 'LABEL.ADD_LOCATION'})}
                  </h3>
                  <div className='card-toolbar'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                      id='side_activities_close'
                    >
                      <KTIcon iconName='cross' className='fs-1' />
                    </button>
                  </div>
                </div>
                <div className='card-body position-relative' id='side_activities_body'>
                  <div>
                    <div
                      id='side_activities_scroll'
                      className='position-relative scroll-y me-n5 pe-5'
                      data-kt-scroll='true'
                      data-kt-scroll-height='auto'
                      data-kt-scroll-wrappers='#side_activities_body'
                      data-kt-scroll-dependencies='#side_activities_header, #side_activities_footer'
                      data-kt-scroll-offset='5px'
                    >
                      <div className='d-flex align-items-start'>
                        <img
                          src={brand_logo_url}
                          alt='img'
                          className='activity-img rounded w-65px h-65px me-3'
                        />
                        <div className='mt-0 pt-0'>
                          <p className='text-dark fw-bold text-hover-primary fs-5 m-0'>
                            {brandName}
                          </p>
                          <p className='text-dark fw-bold text-hover-primary text-muted m-0 mt-1'>
                            <KTIcon iconName='geolocation' className='fs-7 me-1' />
                            {locationName?.country_name}
                          </p>
                          <p className='text-dark fw-bold text-hover-primary text-muted m-0'>
                            <KTIcon iconName='bank' className='fs-7 me-1' />
                            {locationName?.address_line1}
                          </p>
                        </div>
                      </div>
                      <hr className='text-dark mt-4 mb-4' />
                      <div className='timeline'>
                        <div className='timeline-item'>
                          <div className='timeline-line w-40px'></div>
                          <div className='timeline-icon symbol symbol-circle symbol-40px'>
                            <div className='symbol-label bg-light'>
                              <KTIcon iconName='geolocation-home' className='fs-2 text-gray-500' />
                            </div>
                          </div>
                          <div className='timeline-content mb-6 mt-n1'>
                            <div className='overflow-auto pe-3'>
                              <div className='fw-bold mb-2 required'>
                                {intl.formatMessage({id: 'LABEL.LOCATION_NAME'})}
                              </div>
                              <div className='flex-fill'>
                                <Field
                                  type='text'
                                  className='form-control form-control-solid'
                                  id='floatingInput'
                                  name='location_name'
                                />
                                <ErrorMessage
                                  component='div'
                                  className='text-danger'
                                  name='location_name'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='timeline-item'>
                          <div className='timeline-line w-40px'></div>
                          <div className='timeline-icon symbol symbol-circle symbol-40px'>
                            <div className='symbol-label bg-light'>
                              <KTIcon iconName='two-credit-cart' className='fs-2 text-gray-500' />
                            </div>
                          </div>
                          <div className='timeline-content mb-6 mt-n2'>
                            <div className='overflow-auto pe-3'>
                              <div className='fw-bold mb-2 required'>
                                {intl.formatMessage({id: 'LABEL.LOCATION_TAX_ID'})}
                              </div>
                              <div className='flex-fill'>
                                <Field
                                  type='text'
                                  className='form-control form-control-solid'
                                  id='floatingInput'
                                  name='location_tax_id'
                                />
                                <ErrorMessage
                                  component='div'
                                  className='text-danger'
                                  name='location_tax_id'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='timeline-item'>
                          <div className='timeline-line w-40px'></div>
                          <div className='timeline-icon symbol symbol-circle symbol-40px'>
                            <div className='symbol-label bg-light'>
                              <KTIcon iconName='barcode' className='fs-2 text-gray-500' />
                            </div>
                          </div>
                          <div className='timeline-content mb-4 mt-n3'>
                            <div className='overflow-auto pe-3'>
                              <div className='fw-bold mb-2 required'>
                                {intl.formatMessage({id: 'LABEL.LOCATION_CODE'})}
                              </div>
                              <div className='flex-fill'>
                                <Field
                                  type='text'
                                  className='form-control form-control-solid'
                                  id='floatingInput'
                                  name='location_code'
                                />
                                <ErrorMessage
                                  component='div'
                                  className='text-danger'
                                  name='location_code'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className='text-dark mt-4 mb-4' />
                      <div className='d-flex mb-4'>
                        <div className=''>
                          <div className='fw-bold mb-2 required'>
                            {intl.formatMessage({id: 'LABEL.ADDRESS_LINE-1'})}
                          </div>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='address_line1'
                          />
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='address_line1'
                          />
                        </div>
                        <div className='ms-2'>
                          <div className='fw-bold mb-2 required'>
                            {intl.formatMessage({id: 'LABEL.ADDRESS_LINE-2'})}
                          </div>
                          <Field
                            type='text'
                            className='form-control form-control-solid'
                            id='floatingInput'
                            name='address_line2'
                          />
                          <ErrorMessage
                            component='div'
                            className='text-danger'
                            name='address_line2'
                          />
                        </div>
                      </div>
                      <div className='my-2'>
                        <div className='fw-bold mb-2 required'>
                          {intl.formatMessage({id: 'LABEL.COUNTRY'})}
                        </div>
                        <Field
                          as='select'
                          id='floatingInput'
                          className='form-select form-select-solid'
                          name='country_name'
                          onChange={(e: any) => {
                            setCountryId(e.target.value)
                            handleChange(e)
                          }}
                        >
                          <option value=''>
                            {intl.formatMessage({id: 'LABEL.SELECT_COUNTRY'})}
                          </option>
                          {countriesData?.map((option: any) => (
                            <option key={option.country_id} value={option.country_id}>
                              {option.country_name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage component='div' className='text-danger' name='country_name' />
                      </div>
                      {states?.length > 0 && (
                        <div className='my-2'>
                          <div className='fw-bold mb-2'>
                            {intl.formatMessage({id: 'LABEL.STATE'})}
                          </div>
                          <Field
                            as='select'
                            id='floatingInput'
                            className='form-select form-select-solid'
                            name='state_name'
                            onChange={(e: any) => {
                              setStateId(e.target.value)
                              handleChange(e)
                            }}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_STATE'})}
                            </option>
                            {states?.map((option: any) => (
                              <option key={option.state_id} value={option.state_id}>
                                {option.state_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage component='div' className='text-danger' name='state_name' />
                        </div>
                      )}
                      {cities?.length > 0 && (
                        <div className='my-2'>
                          <div className='fw-bold mb-2'>
                            {intl.formatMessage({id: 'LABEL.CITY'})}
                          </div>
                          <Field
                            as='select'
                            id='floatingInput'
                            className='form-select form-select-solid'
                            name='city_name'
                            onChange={handleChange}
                          >
                            <option value=''>
                              {intl.formatMessage({id: 'LABEL.SELECT_CITY'})}
                            </option>
                            {cities?.map((option: any) => (
                              <option key={option.city_id} value={option.city_id}>
                                {option.city_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage component='div' className='text-danger' name='city_name' />
                        </div>
                      )}
                      <div className='my-2'>
                        <div className='fw-bold mb-2 required'>
                          {intl.formatMessage({id: 'LABEL.POSTAL_CODE'})}
                        </div>
                        <Field
                          type='text'
                          className='form-control form-control-solid'
                          id='floatingInput'
                          name='postal_code'
                        />
                        <ErrorMessage component='div' className='text-danger' name='postal_code' />
                      </div>
                      <div className='form-floating flex-fill'>
                        <div className='form-check form-check-solid form-switch mb-0'>
                          <Field
                            className='form-check-input w-40px h-20px'
                            type='checkbox'
                            name='status'
                            id='toggle'
                          />
                          <span
                            className={`me-4 fw-bold ${
                              values.status ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {values.status ? 'Active' : 'Inactive'}
                          </span>
                          <ErrorMessage component='div' className='text-danger' name='status' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-footer text-end' id='side_activities_footer'>
                  <div
                    className='btn btn-sm btn-secondary m-2'
                    onClick={() => handleCancel(resetForm)}
                    id='side_activities_close'
                  >
                    {intl.formatMessage({id: 'LABEL.CANCEL'})}
                  </div>
                  <button className='btn btn-sm btn-primary' type='submit'>
                    {intl.formatMessage({id: 'BTN.SAVE'})}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {AddLocation}
