import {useState} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {useLocation} from 'react-router-dom'
import IssuanceTable from '../../asset_management/issuance/IssuanceTable'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import ITAdminNav from './ITAdminNav'
import TeamAssets from '../../asset_management/issuance/TeamAssets'
import ITAssetRequestsTable from './ITAssetRequestsTable'
import {useIntl} from 'react-intl'
function ITAdminRoutes() {
  const intl = useIntl()
  const {pathname} = useLocation()
  const [SideDrawerId, updateId] = useState('0')
  const ManagerExpenseTitle = [
    {
      title: intl.formatMessage({id: 'LABEL.ASSET_ISSUANCE'}),
      path: '/it-admin/assets',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const checkNavigation = () => {
    switch (pathname) {
      case '/it-admin/assets':
        return (
          <PageTitle breadcrumbs={ManagerExpenseTitle}>
            {intl.formatMessage({id: 'LABEL.ISSUANCE'})}
          </PageTitle>
        )
    }
  }

  const switchComponent = () => {
    switch (pathname) {
      case '/it-admin/assets':
        return <ITAssetRequestsTable />
    }
  }

  const UpdatingId = (id) => {
    updateId(id)
  }

  return (
    <>
      <Context.Provider value={{id: SideDrawerId, updateId: UpdatingId}}>
        {checkNavigation()}
        <ITAdminNav />
        {/* <HRWFExpMain /> */}
        {switchComponent()}
      </Context.Provider>
    </>
  )
}

export default ITAdminRoutes
