import {useState} from 'react'

import {PageTitle} from '../../../../_metronic/layout/core'
import {useLocation} from 'react-router-dom'
import TDWFTravelRequests from './TDWFTravelRequests'
import TravelDeskNav from './TravelDeskNav'
import TravelDeskContext from './TravelDeskContext'
import TDWFTravelRequestsTable from './TDWFTravelRequestsTable'
import {useIntl} from 'react-intl'
function TravelDeskRoutes() {
  const intl = useIntl()
  const {pathname} = useLocation()
  const [expInfo, setExpInfo] = useState(null)
  const updateExpInfoData = (expInfo) => {
    setExpInfo(expInfo)
  }
  const ManagerExpenseTitle = [
    {
      title: intl.formatMessage({id: 'MENU.TRAVEL_REQUESTS'}),
      path: '/traveldesk-workflow/travelrequests',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const checkNavigation = () => {
    switch (pathname) {
      case '/traveldesk-workflow/travelrequests':
        return (
          <PageTitle breadcrumbs={ManagerExpenseTitle}>
            {intl.formatMessage({id: 'MENU.TRAVEL_REQUESTS'})}
          </PageTitle>
        )
    }
  }

  const switchComponent = () => {
    switch (pathname) {
      case '/traveldesk-workflow/travelrequests':
        return <TDWFTravelRequestsTable />
    }
  }
  return (
    <TravelDeskContext.Provider value={{ExpInfo: expInfo, updateExpInfo: updateExpInfoData}}>
      {checkNavigation()}
      <TravelDeskNav />
      {/* <HRWFExpMain /> */}
      {switchComponent()}
    </TravelDeskContext.Provider>
  )
}

export default TravelDeskRoutes
