import {useEffect, useMemo, useState} from 'react'
import {useTable, usePagination} from 'react-table'
import {Pagination} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import DatePicker from 'react-datepicker'
import '../../DatepickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css'
import {useThemeMode} from '../../../../_metronic/partials'
import {useIntl} from 'react-intl'
import Context from '../../../../_metronic/partials/layout/activity-drawer/context'
import {SideDrawer} from '../../../../_metronic/partials/layout/activity-drawer/Side-drawer'
import {useLocation} from 'react-router-dom'
import usePageTitle from '../../custom_hooks/usePageTitle'
import secureLocalStorage from 'react-secure-storage'
import useApiCall from '../../custom_hooks/useApiCall'
const DataTable = ({columns, data, loading}) => {
  const [inputVal, setInputVal] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const [progressVal, setProgressVal] = useState('')
  const {mode} = useThemeMode()
  const {data: jobroles} = useApiCall('/hrConfig/jobRole')

  const intl = useIntl()
  usePageTitle(intl.formatMessage({id: 'MENU.PERFORMANCE_MANAGEMENT.MY_INPUTS'}))

  let is_admin
  let is_itadmin
  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({is_admin, is_itadmin} = userInfo)
  }
  const filteredData = useMemo(() => {
    let filteredData = data
    if (inputVal) {
      filteredData = filteredData.filter((role) =>
        role.kpi_name.toLowerCase().includes(inputVal.toLowerCase())
      )
    }

    if (progressVal) {
      filteredData = filteredData.filter(
        (leave) => leave.job_role_name.toLowerCase() === progressVal.toLowerCase()
      )
    }

    if (!selectedDate) {
      return filteredData
      // No date selected, return the current filtered data
    }

    // Adjust the selected date to account for the time zone offset
    const selectedDateStr = new Date(
      selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 10) // Convert selected date to 'yyyy-MM-dd' format

    return filteredData.filter((each) => {
      return each.created_timestamp.slice(0, 10) === selectedDateStr
    })
  }, [data, inputVal, progressVal, selectedDate])

  // Create separate datasets for active and inactive records

  // Table instances for active and inactive datasets
  const activeData = useMemo(() => {
    return filteredData?.filter((request) => request.kpi_status === 'Pending')
  }, [filteredData])

  const inactiveData = useMemo(() => {
    return filteredData?.filter((request) => request.kpi_status === 'Completed')
  }, [filteredData])

  const activeTableInstance = useTable({columns, data: activeData}, usePagination)
  const inactiveTableInstance = useTable({columns, data: inactiveData}, usePagination)

  // Extract required values from table instances
  const activePage = activeTableInstance.page
  const activePageCount = activeTableInstance.pageCount
  const activePageIndex = activeTableInstance.state.pageIndex
  const activePageSize = activeTableInstance.state.pageSize

  const inactivePage = inactiveTableInstance.page
  const inactivePageCount = inactiveTableInstance.pageCount
  const inactivePageIndex = inactiveTableInstance.state.pageIndex
  const inactivePageSize = inactiveTableInstance.state.pageSize

  // Handle pagination for active dataset
  const gotoActivePage = (pageIndex) => {
    activeTableInstance.gotoPage(pageIndex)
  }

  const setActivePageSize = (pageSize) => {
    activeTableInstance.setPageSize(pageSize)
  }

  // Handle pagination for inactive dataset
  const gotoInactivePage = (pageIndex) => {
    inactiveTableInstance.gotoPage(pageIndex)
  }

  const setInactivePageSize = (pageSize) => {
    inactiveTableInstance.setPageSize(pageSize)
  }

  const pageOptions = [5, 10, 20, 30, 40, 50]

  const handleDatesChange = () => {
    setSelectedDate(null)
  }

  const handleFilterByStatus = (e) => {
    setProgressVal(e.target.value)
  }

  // Render the component
  return (
    <Context.Consumer>
      {(value) => {
        const {id, updateId} = value
        return (
          <>
            {loading ? (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <div className='spinner-border spinner-primary mr-15'></div>
                </div>
              </div>
            ) : data.length > 0 ? (
              <>
                <div className='d-flex'>
                  <div className='d-flex flex-wrap flex-stack mb-6'>
                    <h3 className='fw-bolder my-2'>
                      {' '}
                      {intl.formatMessage({id: 'MENU.PERFORMANCE_MANAGEMENT.MY_KPI'})}
                    </h3>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header border-0 pt-5'>
                    <div className='d-flex flex-wrap flex-stack'>
                      <div className='fw-bolder'>
                        <ul className='nav'>
                          <li className='nav-item'>
                            <a
                              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1 ms-0'
                              data-bs-toggle='tab'
                              href='#kt_table_widget_4_tab_1'
                            >
                              {intl.formatMessage({id: 'LABEL.PENDING'})}
                            </a>
                          </li>
                          <li className='nav-item'>
                            <a
                              className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                              data-bs-toggle='tab'
                              href='#kt_table_widget_4_tab_2'
                            >
                              {intl.formatMessage({id: 'LABEL.COMPLETED'})}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='card-toolbar'>
                      <div className='d-flex align-items-center position-relative me-4'>
                        <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3' />
                        <input
                          type='text'
                          id='kt_filter_search'
                          className='form-control form-control-white form-control-sm w-150px ps-9'
                          placeholder={intl.formatMessage({id: 'LABEL.SEARCH'})}
                          value={inputVal}
                          onChange={(e) => setInputVal(e.target.value)}
                        />
                      </div>
                      {/* <div className='d-flex align-items-center me-4'> */}
                      {/* <span className='fs-7 fw-bold text-gray-700 pe-4 text-nowrap d-none d-md-block'>
                          {intl.formatMessage({id: 'LABEL.FILTER BY'})}
                        </span>
                        <select
                          className='form-select form-select-solid'
                          data-control='select2'
                          data-placeholder='Latest'
                          data-hide-search='true'
                          onChange={handleFilterByStatus}
                          value={progressVal}
                        >
                          <option value=''>
                            {intl.formatMessage({id: 'LABEL.SELECT_JOB_ROLE'})}
                          </option>
                          {jobroles?.map((roleType) => (
                            <option key={roleType.job_role_id} value={roleType.role_name}>
                              {roleType.role_name}
                            </option>
                          ))}{' '}
                        </select>
                      </div> */}

                      {/* <div
                        className={`react-datepicker-fullwidth-wrapper test me-4 ${
                          mode === 'dark' ? 'dark-mode' : 'light-mode'
                        }`}
                      >
                        <DatePicker
                          className='form-control form-control-solid'
                          selected={selectedDate}
                          isClearable
                          showYearDropdown
                          scrollableYearDropdown
                          dateFormat='dd/MM/yyyy'
                          onChange={(date) => setSelectedDate(date)}
                          placeholderText={intl.formatMessage({id: 'LABEL.SELECT_DATE'})}
                          showMonthDropdown
                          useShortMonthInDropdown
                        />
                      </div> */}
                      {/* {is_admin === 1 && (
                        <button
                          className='btn btn-sm btn-light-primary mx-2'
                          id='side_activities_toggle'
                          onClick={() => {
                            updateId({
                              type: 'add_kpi',
                            })
                          }}
                        >
                          <KTIcon iconName='plus' className='fs-3' />
                          Add KPI
                        </button>
                      )} */}
                    </div>
                  </div>
                  <div className='tab-content p-2 pt-0'>
                    <div
                      className='card-body tab-pane fade show active table-responsive'
                      id='kt_table_widget_4_tab_1'
                    >
                      <table
                        className='table table-row-dashed align-middle table-row-gray-300'
                        {...activeTableInstance.getTableProps()}
                      >
                        <thead>
                          <tr className='fw-bold text-muted'>
                            {/* <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.ROLE_NAME'})}
                            </th> */}
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.KPI_NAME'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.START_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.END_DATE'})}
                            </th>

                            {/* <th className='min-w-140px  text-muted text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                            </th> */}

                            <th className='min-w-100px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.STATUS'})}
                            </th>
                          </tr>
                        </thead>

                        <tbody {...activeTableInstance.getTableBodyProps()}>
                          {activePage.length > 0 ? (
                            activePage.map((row) => {
                              activeTableInstance.prepareRow(row)
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => (
                                    <td className=' ' {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  ))}
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td>
                                <p></p>
                              </td>
                              <td className='card'>
                                <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                  {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {data.length > 10 && (
                        <Pagination>
                          <Pagination.First
                            onClick={() => gotoActivePage(0)}
                            disabled={!activeTableInstance.canPreviousPage}
                          />
                          <Pagination.Prev
                            onClick={activeTableInstance.previousPage}
                            disabled={!activeTableInstance.canPreviousPage}
                          />
                          {[...Array(activePageCount)].map((_, i) => (
                            <Pagination.Item
                              key={i}
                              active={i === activePageIndex}
                              onClick={() => gotoActivePage(i)}
                            >
                              {i + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={activeTableInstance.nextPage}
                            disabled={!activeTableInstance.canNextPage}
                          />
                          <Pagination.Last
                            onClick={() => gotoActivePage(activePageCount - 1)}
                            disabled={!activeTableInstance.canNextPage}
                          />
                          <div className='d-flex align-items-center mx-5'>
                            <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                            <select
                              className='form-select form-select-solid fw-bold w-75px me-2'
                              value={activePageSize}
                              onChange={(e) => setActivePageSize(Number(e.target.value))}
                            >
                              {pageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Pagination>
                      )}
                    </div>

                    <div
                      className='card-body tab-pane fade  table-responsive'
                      id='kt_table_widget_4_tab_2'
                    >
                      <table
                        className='table table-row-dashed align-middle table-row-gray-300'
                        {...inactiveTableInstance.getTableProps()}
                      >
                        <thead>
                          <tr className='fw-bold text-muted'>
                            {/* <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.ROLE_NAME'})}
                            </th> */}
                            <th className='min-w-140px default-cursor'>
                              {intl.formatMessage({id: 'LABEL.KPI_NAME'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.START_DATE'})}
                            </th>

                            <th className='min-w-120px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.END_DATE'})}
                            </th>

                            {/* <th className='min-w-140px  text-muted text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.RAISED_DATE'})}
                            </th> */}

                            <th className='min-w-100px text-start default-cursor'>
                              {intl.formatMessage({id: 'LABEL.STATUS'})}
                            </th>
                          </tr>
                        </thead>

                        <tbody {...inactiveTableInstance.getTableBodyProps()}>
                          {inactivePage.length > 0 ? (
                            inactivePage.map((row) => {
                              inactiveTableInstance.prepareRow(row)
                              return (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell) => (
                                    <td className=' ' {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  ))}
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td className='card'>
                                <p className='text-dark fw-bold text-hover-primary default-cursor d-block fs-6 m-0 my-5'>
                                  {intl.formatMessage({id: 'NO_RESULTS_FOUND_MSG'})}
                                </p>
                              </td>
                              <td>
                                <p></p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                      {data.length > 10 && (
                        <Pagination>
                          <Pagination.First
                            onClick={() => gotoInactivePage(0)}
                            disabled={!inactiveTableInstance.canPreviousPage}
                          />
                          <Pagination.Prev
                            onClick={inactiveTableInstance.previousPage}
                            disabled={!inactiveTableInstance.canPreviousPage}
                          />
                          {[...Array(inactivePageCount)].map((_, i) => (
                            <Pagination.Item
                              key={i}
                              active={i === inactivePageIndex}
                              onClick={() => gotoInactivePage(i)}
                            >
                              {i + 1}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={inactiveTableInstance.nextPage}
                            disabled={!inactiveTableInstance.canNextPage}
                          />
                          <Pagination.Last
                            onClick={() => gotoInactivePage(inactivePageCount - 1)}
                            disabled={!inactiveTableInstance.canNextPage}
                          />
                          <div className='d-flex align-items-center mx-5'>
                            <span className='me-2'>{intl.formatMessage({id: 'LABEL.SHOW'})}</span>
                            <select
                              className='form-select form-select-solid fw-bold w-75px me-2'
                              value={inactivePageSize}
                              onChange={(e) => setInactivePageSize(Number(e.target.value))}
                            >
                              {pageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Pagination>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='card h-250px'>
                <div className='m-auto d-flex flex-column align-items-center'>
                  <h1>{intl.formatMessage({id: 'LABEL.NO_KPI_FOUND'})}</h1>

                  {/* <button
                    className='btn btn-sm btn-light-primary mt-3'
                    id='side_activities_toggle'
                    onClick={() => {
                      updateId({
                        type: 'add_kpi',
                      })
                    }}
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    Add KPI
                  </button> */}
                </div>
              </div>
            )}
            <SideDrawer value={id} />
          </>
        )
      }}
    </Context.Consumer>
  )
}

const MyKPITable = () => {
  let job_role_id

  const userInfo = secureLocalStorage.getItem('user_info')
  if (userInfo) {
    ;({job_role_id} = userInfo)
  }

  const {
    data: filteredData,
    isLoading,
    fetchData,
  } = useApiCall(job_role_id ? `/masterData/kpi/${job_role_id}` : null)

  const columns = useMemo(
    () => [
      // {
      //   Header: 'Role Name',
      //   accessor: 'Role Name',
      //   Cell: ({row}) => {
      //     return (
      //       <div className='text-start'>
      //         <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
      //           {row.original.job_role_name}
      //         </p>
      //       </div>
      //     )
      //   },
      // },
      {
        Header: 'Kpi Name',
        accessor: 'Kpi Name',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.kpi_name}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'Start Date',
        accessor: 'Start Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.start_date}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      {
        Header: 'End Date',
        accessor: 'End Date',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.end_date}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },

      // {
      //   Header: 'Created Date',
      //   accessor: 'Created Date',
      //   Cell: ({row}) => {
      //     return (
      //       <div className='text-start'>
      //         <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
      //           {row.original.created_timestamp.slice(0, 10)}
      //         </p>
      //       </div>
      //     )
      //   },
      //   disableSortBy: true,
      // },

      {
        Header: 'Status',
        accessor: 'Status',
        Cell: ({row}) => {
          return (
            <div className='text-start'>
              <p className='text-dark fw-bold text-hover-primary d-block fs-6 default-cursor'>
                {row.original.kpi_status}
              </p>
            </div>
          )
        },
        disableSortBy: true,
      },
    ],
    []
  )

  return <DataTable columns={columns} data={filteredData ? filteredData : []} loading={isLoading} />
}

export default MyKPITable
